import { BookingSteps, PaymentSteps } from '@constants';
import { BookingError } from '@constants/bookingError';

import {
  mapLocaleToBookingErrorUri,
  mapLocaleToBookingUri,
  mapLocaleToCabinUri,
  mapLocaleToCheckoutUri,
  mapLocaleToConfirmationUri,
  mapLocaleToCruisesUri,
  mapLocaleToPaymentUri,
  mapLocaleToPersonaliaUri,
  mapLocaleToSummaryUri
} from '../mappers/uriMappers';

const getBookingStepRoute = (
  step: BookingSteps,
  locale: TLocale,
  cabinIndex?: number
) => {
  switch (step) {
    case BookingSteps.Cabin:
      return `${mapLocaleToCabinUri(locale)}/${cabinIndex || 0}`;
    case BookingSteps.CabinLocation:
      return `${mapLocaleToCabinUri(locale)}/location`;
    case BookingSteps.Extras:
      return 'extras';
    case BookingSteps.Personalia:
      return `${mapLocaleToPersonaliaUri(locale)}/${cabinIndex || 0}`;
    case BookingSteps.ExtrasFlightsOptions:
      return `${mapLocaleToSummaryUri(locale)}`;
    case BookingSteps.Checkout:
      return `${mapLocaleToCheckoutUri(locale)}`;

    case BookingSteps.Departure:
    default:
      return '';
  }
};

export const buildBookingStepUrlWithLocale =
  (locale: TLocale) =>
  (
    step: BookingSteps,
    quoteId: string,
    voyageId: string,
    voyageSlug: string,
    cabinIndex?: number,
    packageCode?: string,
    departureDate?: string
  ) =>
    `/${locale}/${mapLocaleToCruisesUri(locale)}/${encodeURIComponent(
      voyageSlug
    )}/${mapLocaleToBookingUri(locale)}/${getBookingStepRoute(
      step,
      locale,
      cabinIndex
    )}?voyageId=${voyageId}&quoteId=${quoteId}${
      packageCode ? `&package=${encodeURIComponent(packageCode)}` : ''
    }${departureDate ? `&date=${encodeURIComponent(departureDate)}` : ''}`;

const getPaymenStepRoute = (step: PaymentSteps, locale: TLocale) => {
  switch (step) {
    case PaymentSteps.PaymentResponse:
      return 'paymentResponse';
    case PaymentSteps.EmbededPaymentResponse:
      return 'embeddedPaymentResponse';
    case PaymentSteps.Confirmation:
      return `${mapLocaleToConfirmationUri(locale)}`;
    case PaymentSteps.Payment:
      return `${mapLocaleToPaymentUri(locale)}`;
    default:
      return '';
  }
};

export const buildPaymentStepUrlWithLocale =
  (locale: TLocale) =>
  (
    step: PaymentSteps,
    voyageSlug: string,
    absolute = false,
    bookingId?: string,
    customerId?: string,
    paymentUrl?: string
  ) => {
    const base = `${
      absolute && window ? window.location.origin : ''
    }/${locale}/${mapLocaleToCruisesUri(locale)}/${encodeURIComponent(
      voyageSlug
    )}/${mapLocaleToBookingUri(locale)}/${getPaymenStepRoute(step, locale)}`;

    if (bookingId && customerId) {
      return `${base}?bookingId=${bookingId}&customerId=${customerId}`;
    }

    if (paymentUrl) {
      return `${base}?paymentUrl=${paymentUrl}`;
    }

    return base;
  };

export const buildBookingErrorRedirectUrl = ({
  locale,
  cruise,
  quoteId,
  errorType
}: {
  locale: TLocale;
  cruise: string;
  quoteId: string;
  errorType: BookingError;
}) =>
  `/${locale}/${mapLocaleToCruisesUri(
    locale
  )}/${cruise}/${mapLocaleToBookingUri(locale)}/${mapLocaleToBookingErrorUri(
    locale
  )}/?type=${errorType}&quoteId=${quoteId}`;

export const buildBookingErrorRedirect = (props: {
  locale: TLocale;
  cruise: string;
  quoteId: string;
  errorType: BookingError;
}) => ({
  redirect: {
    permanent: false,
    destination: buildBookingErrorRedirectUrl(props)
  }
});
