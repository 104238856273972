export const stickyBarReducerTypes = {
  HIDE: 'HIDE',
  SHOW: 'SHOW'
};

export function stickyBarReducer(action: { type: string }) {
  switch (action.type) {
    case stickyBarReducerTypes.SHOW: {
      return true;
    }
    case stickyBarReducerTypes.HIDE: {
      return false;
    }
    default: {
      return false;
    }
  }
}
