import { useEffect, useState } from 'react';

export const setValueAndNotify = (key: string, newValue: string) => {
  window.localStorage.setItem(key, newValue);
  const event = new StorageEvent('storage', { key, newValue });
  window.dispatchEvent(event);
};

export const removeAndNotify = (key: string) => {
  window.localStorage.removeItem(key);
  const event = new StorageEvent('storage', { key, newValue: null });
  window.dispatchEvent(event);
};

/**
 * Hook that syncs local storage to state, even across tabs.
 */
export const useWatchedStorage = <T>(key: string, setValue?: T) => {
  const [data, setData] = useState<T | null>(setValue ?? null);

  useEffect(() => {
    if (setValue) {
      setValueAndNotify(key, JSON.stringify(setValue));
    } else {
      const json = window.localStorage.getItem(key);
      if (json) {
        setData(JSON.parse(json) as T);
      } else {
        setData(null);
      }
    }

    // Storage event lets us listen to localStorage changes in other
    // tabs.
    const storageEventHandler = (e: StorageEvent) => {
      if (e.key === key) {
        const json = e.newValue;
        if (json) {
          setData(JSON.parse(json) as T);
        } else {
          setData(null);
        }
      }
    };
    window.addEventListener('storage', storageEventHandler);

    return () => {
      window.removeEventListener('storage', storageEventHandler);
    };
  }, []);

  return {
    data,
    // We provide oldValue when you want to set data because
    // state could lag behind localStorage by one tick.
    set: (fn: (oldValue: T | null) => T) => {
      const json = window.localStorage.getItem(key);
      let oldValue: T | null = null;
      if (json) {
        oldValue = JSON.parse(json) as T;
      }

      const value = fn(oldValue);

      setData(value);
      setValueAndNotify(key, JSON.stringify(value));
    },
    remove: () => {
      setData(null);
      removeAndNotify(key);
    }
  };
};
