import { RefObject, useCallback, useEffect, useState } from 'react';

/**
 * Hook that takes a ref and returns the width and height
 * of the element.
 */
const useElementSize = (ref: RefObject<HTMLElement>) => {
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);

  const updateSize = useCallback(() => {
    if (ref.current) {
      const rect = ref.current.getBoundingClientRect();
      setWidth(rect.right - rect.left);
      setHeight(rect.bottom - rect.top);
    }
  }, []);

  useEffect(() => {
    updateSize();

    let debounceTimer: undefined | ReturnType<typeof setTimeout>;

    const debouncedUpdate = () => {
      if (debounceTimer) {
        clearTimeout(debounceTimer);
        debounceTimer = setTimeout(() => {
          updateSize();
          debounceTimer = undefined;
        }, 100);
      } else {
        updateSize();
        debounceTimer = setTimeout(() => {
          debounceTimer = undefined;
        }, 100);
      }
    };

    window.addEventListener('resize', debouncedUpdate);
    const intervalId = setInterval(debouncedUpdate, 500);

    return () => {
      window.removeEventListener('resize', debouncedUpdate);
      clearInterval(intervalId);
    };
  }, []);

  return {
    width,
    height
  };
};

export default useElementSize;
