import { MouseEventHandler } from 'react';
import { differenceInMinutes } from 'date-fns';
import clsx from 'clsx';

import { TQuoteMetaPreviewInformation } from '@src/types/quoteMetaInformation';
import { useLocale, useTranslate } from '@hooks';
import { createDateAsUTC, dateToString } from '@utils';
import { ContentfulImage, IconButton } from '@atoms';
import { ButtonModes } from '@constants';
import { CloseLine } from '@icons/System';
import { appbar, common } from '@microcopies';
import useNow from '@hooks/useNow';

import { CenteredSpinner } from '.';

const ExpirationState = {
  Expired: 'expired',
  Expiring: 'expiring',
  InProgress: 'inprogress',
  Old: 'old'
};

type TExpirationState = typeof ExpirationState[keyof typeof ExpirationState];

const BookingInProgressPreview = ({
  holdExpires,
  preview,
  isLoading,
  onClick,
  onClose
}: {
  holdExpires: number;
  preview: TQuoteMetaPreviewInformation;
  isLoading: boolean;
  onClick: () => void;
  onClose: () => void;
}) => {
  const translateCommon = useTranslate(common, (x) => x.common);
  const translateAppbar = useTranslate(appbar, (x) => x.appbar);
  const locale = useLocale();

  const now = useNow(5000);

  const expireInMinutes = differenceInMinutes(holdExpires, now);
  let expirationState: TExpirationState = ExpirationState.InProgress;
  if (holdExpires === 0) {
    expirationState = ExpirationState.Old;
  } else if (expireInMinutes < 0) {
    expirationState = ExpirationState.Expired;
  } else if (expireInMinutes < 10) {
    expirationState = ExpirationState.Expiring;
  }

  let expirationMessage: string;
  let expirationTitle: string;
  switch (expirationState) {
    case ExpirationState.Expiring:
      expirationTitle = translateAppbar(
        (x) => x.bookingsInProgress.statusExpiringTitle
      );
      expirationMessage = translateAppbar(
        (x) => x.bookingsInProgress.statusExpiringMessage
      );
      break;
    case ExpirationState.Expired:
      expirationTitle = translateAppbar(
        (x) => x.bookingsInProgress.statusExpiredTitle
      );
      expirationMessage = translateAppbar(
        (x) => x.bookingsInProgress.statusExpiredMessage
      );
      break;
    case ExpirationState.Old:
      expirationTitle = translateAppbar(
        (x) => x.bookingsInProgress.unfinishedTitle
      );
      expirationMessage = translateAppbar(
        (x) => x.bookingsInProgress.unfinishedMessage
      );
      break;
    case ExpirationState.InProgress:
    default:
      expirationTitle = translateAppbar((x) => x.bookingInProgress);
      expirationMessage = translateAppbar(
        (x) => x.bookingsInProgress.statusInProgressMessage
      );
      break;
  }

  const onCloseHandler: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.stopPropagation();
    onClose();
  };

  return (
    <button
      onClick={() => !isLoading && onClick()}
      className="relative w-full p-2 pb-3 text-left bg-white drop-shadow-card-shadow rounded-5xl hover:cursor-pointer hover:drop-shadow-card-hover animate-fadeInPop"
    >
      <div className="flex gap-4">
        {preview.imageSrc && (
          <ContentfulImage
            src={preview.imageSrc}
            className="w-24 rounded-5xl"
            layout="fixed"
            quality={100}
            fit="thumb"
            objectFit="cover"
            width={100}
            height={100}
          />
        )}
        <div className="flex-1">
          <div className="flex items-start justify-between gap-2">
            <span
              className={clsx('px-2 py-1.5 ui-text rounded-xl font-normal', {
                'bg-flare-yellow': expirationState === ExpirationState.Expiring,
                'bg-red-100': expirationState === ExpirationState.Expired,
                'bg-light-green-secondary':
                  expirationState === ExpirationState.InProgress ||
                  expirationState === ExpirationState.Old
              })}
            >
              {expirationTitle}
            </span>
            <IconButton
              aria-label="Hide voyage preview"
              size="small"
              mode={ButtonModes.flatBlack}
              icon={CloseLine}
              onClick={onCloseHandler}
            />
          </div>
          <h5 className="my-2 overflow-hidden text-ellipsis line-clamp-2">
            {preview.voyageName}
          </h5>
          <p className="body-text-2">
            <span>
              {dateToString(createDateAsUTC(preview.departureDate), locale)}
              {' - '}
              {preview.duration} {translateCommon((x) => x.days)}
            </span>
          </p>
        </div>
      </div>
      <p className="mt-3 caption text-light-black">{expirationMessage}</p>
      {isLoading && <CenteredSpinner absolute />}
    </button>
  );
};

export default BookingInProgressPreview;
