import { TQuoteMetaInformationWithTimestamp } from '@src/types/quoteMetaInformation';

export const isPackage = (data: any): data is PG.Response.Quote.TPackage =>
  typeof (data as PG.Response.Quote.TPackage).packageId === 'string';

export const isQuote = (data?: any): data is PG.Response.Quote.TRootObject =>
  typeof (data as PG.Response.Quote.TRootObject)?.quoteId === 'string';

export const isCabin = (data: any): data is PG.Response.Shared.TCabin =>
  typeof (data as PG.Response.Shared.TCabin).cabinId === 'string';

export const isPassenger = (data: any): data is PG.Response.Quote.TPassenger =>
  typeof (data as PG.Response.Quote.TPassenger).passengerId === 'string' &&
  typeof (data as PG.Response.Quote.TPassenger).guestType === 'string';

export const isDate = (maybeDate: Date | undefined): maybeDate is Date =>
  maybeDate instanceof Date;

export const isCabinRequest = (
  data: any
): data is PG.Request.Cabin.TRootObject =>
  data !== undefined &&
  data !== null &&
  (data as PG.Request.Cabin.TRootObject).quoteId !== undefined &&
  (data as PG.Request.Cabin.TRootObject).voyageId !== undefined &&
  (data as PG.Request.Cabin.TRootObject).cabins !== undefined;

export function isQuoteMetaInformation(
  value: any
): value is TQuoteMetaInformationWithTimestamp {
  if (typeof value !== 'object' || value === null) {
    return false;
  }

  const valueObject: { [key: string]: unknown } = value;

  return Boolean(
    valueObject.quoteId &&
      valueObject.voyageId &&
      valueObject.voyageSlug &&
      valueObject.savedAt &&
      valueObject.step !== undefined
  );
}

export function isQuoteMetaInformationArray(
  value: any
): value is TQuoteMetaInformationWithTimestamp[] {
  if (!Array.isArray(value)) {
    return false;
  }

  return !value.find((x) => !isQuoteMetaInformation(x));
}
