import { gql } from '@apollo/client';

import { GlobalInfoBanner } from '@content/models';

import { getContentApiClient } from '../apolloClient';
import { RequestConfig } from '../types';

const query = gql`
  query getGlobalInfoBanner($locale: String!) {
    b2cExpeditionGlobalInfoBanner(locale: $locale) {
      show
      style
      banner {
        id
        title
        url
      }
    }
  }
`;

interface GlobalInfoBannerData {
  show: boolean;
  style: string | null;
  banner: {
    id: string;
    title: string;
    url: string;
  } | null;
}
interface Response {
  b2cExpeditionGlobalInfoBanner: GlobalInfoBannerData;
}

const mapToViewModel = (data: GlobalInfoBannerData): GlobalInfoBanner =>
  data?.banner
    ? {
        show: data.show ?? false,
        id: data.banner?.id ?? '',
        title: data.banner?.title ?? null,
        slug: data.banner?.url ?? null,
        style: data.style ?? null
      }
    : null;

export const getGlobalInfoBanner = async ({
  locale,
  preview
}: RequestConfig): Promise<GlobalInfoBanner> => {
  const res = await getContentApiClient(preview).query<Response>({
    query,
    variables: { locale }
  });

  return mapToViewModel(res.data.b2cExpeditionGlobalInfoBanner);
};
