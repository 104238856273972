import { useEffect, useState } from 'react';

import {
  getBannerFromLocalStorage,
  removeBannerFromLocalStorage,
  setBannerInLocalStorage
} from '@src/utils/storage';

const EXPIRES_AFTER = 3600000; // 1 hour in milliseconds

export const useBannerInformation = () => {
  const [storedData, setStoredData] = useState<{ savedAt: number } | undefined>(
    () => {
      if (typeof window !== 'undefined') {
        return getBannerFromLocalStorage();
      }
      return undefined;
    }
  );

  const handleCloseBanner = () => {
    const savedAt = Date.now();
    setBannerInLocalStorage({ savedAt });
    setStoredData({ savedAt });
  };

  useEffect(() => {
    if (storedData) {
      if (storedData?.savedAt + EXPIRES_AFTER > Date.now()) {
        // Less than 1 hour since meta information was saved
        const savedAt = Date.now();
        setBannerInLocalStorage({ savedAt });
      } else {
        // More than 1 hour since meta information was saved
        // I think this is reasonable for updated and critical banners
        removeBannerFromLocalStorage();
      }
    }
  }, []);

  return { storedData, handleCloseBanner };
};
