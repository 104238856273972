import { delay, isFeatureEnabled } from '@utils';
import {
  ClientError,
  NetworkError,
  ParseError,
  ServerError
} from '@api/errors';

export type FetchOptions = {
  method?: 'GET' | 'POST' | 'PUT' | 'DELETE';
  body?: BodyInit; // TODO: Should put optional params into options object
  userId?: string;
  ignoreReturnValue?: boolean;
};

async function handleErrors(res: Response) {
  if (res.status >= 400 && res.status <= 499) {
    return res.text().then((text) => {
      throw new ClientError({
        statusCode: res.status,
        message: text
      });
    });
  }
  if (res.status >= 500 && res.status <= 599) {
    return res.text().then((text) => {
      throw new ServerError({
        statusCode: res.status,
        message: text
      });
    });
  }
  if (res.status < 200 || res.status > 299) {
    throw new Error(
      `Unexpected status response: ${res.statusText} (${res.status})`
    );
  }
  return Promise.resolve();
}

export const postWithReturnStatus =
  <T>(
    body: any,
    headers: Record<string, string> = {
      'content-type': 'application/json;charset=UTF-8'
    }
  ) =>
  async (url: string): Promise<{ data?: T; statusCode: number }> => {
    const resp = await fetch(url, {
      method: 'POST',
      headers,
      body: JSON.stringify(body)
    });
    if (resp.status >= 500) {
      throw new Error(`${resp.status} ${resp.statusText}`);
    }
    const data = await resp.text();
    try {
      return { data: JSON.parse(data), statusCode: resp.status };
    } catch (_) {
      return { statusCode: resp.status };
    }
  };

export const post =
  <T>(
    body: any,
    headers: Record<string, string> = {
      'content-type': 'application/json;charset=UTF-8'
    }
  ) =>
  (url: string) =>
    fetch(url, {
      method: 'POST',
      headers,
      body: typeof body === 'string' ? body : JSON.stringify(body)
    })
      .then((resp) => {
        if (resp.status.toString()[0] !== '2') {
          throw new Error(`${resp.status} ${resp.statusText}`);
        }
        return resp;
      })
      .then((resp) => resp.json() as Promise<T>);

export const put =
  <T>(
    body: any,
    headers: Record<string, string> = {
      'content-type': 'application/json;charset=UTF-8'
    }
  ) =>
  (url: string) =>
    fetch(url, {
      method: 'PUT',
      headers,
      body: typeof body === 'string' ? body : JSON.stringify(body)
    })
      .then((resp) => {
        if (resp.status.toString()[0] !== '2') {
          throw new Error(`${resp.status} ${resp.statusText}`);
        }
        return resp;
      })
      .then((resp) => resp.json() as Promise<T>);

export const get =
  <T>(signal?: AbortSignal) =>
  (url: string) =>
    fetch(`${url}`, { signal })
      .then((resp) => {
        if (resp.status.toString()[0] !== '2') {
          throw new Error(`${resp.status} ${resp.statusText} for ${url}`);
        }
        return resp;
      })
      .then((resp) => resp.json() as Promise<T>);

export const del = <T>(url: string) =>
  fetch(url, {
    method: 'DELETE'
  })
    .then((resp) => {
      if (resp.status.toString()[0] !== '2') {
        throw new Error(`${resp.status} ${resp.statusText}`);
      }
      return resp;
    })
    .then((resp) => resp.json() as Promise<T>);

export const fetchWithErrorHandling = async <T>(
  url: string,
  headers: HeadersInit = {
    'content-type': 'application/json;charset=UTF-8'
  },
  options?: FetchOptions
): Promise<T> => {
  let res: Response;
  try {
    res = await fetch(url, {
      headers,
      body: options?.body,
      method: options?.method ?? 'GET'
    });
  } catch (error) {
    throw new NetworkError((error as Error).message);
  }

  await handleErrors(res);

  if (options?.ignoreReturnValue) return Promise.resolve({} as T);

  try {
    return (await res.json()) as T;
  } catch (error) {
    throw new ParseError();
  }
};

export const pgFetch = async <T>(
  path: string,
  options?: FetchOptions
): Promise<T> => {
  let headers: HeadersInit = {
    'content-type': 'application/json;charset=UTF-8'
  };
  if (options?.userId && isFeatureEnabled('nellieIDAPIHeader')) {
    headers = { ...headers, 'x-nellie-id': options.userId };
  }
  const resp = await fetchWithErrorHandling(
    `${process.env.BOOKING_DOMAIN_URL || ''}/Api${path}`,
    headers,
    options
  );
  return resp as T;
};

export const pgFetchWithRetry = async <T>({
  endpoint,
  attemptNumber = 0,
  maxAttempts = 15,
  delayBetweenAttempts = 1500
}: {
  endpoint: string;
  attemptNumber?: number;
  maxAttempts?: number;
  delayBetweenAttempts?: number;
}): Promise<TResponseWithErrorData<T>> => {
  let errorData = null as TErrorData | null;

  try {
    const res = await pgFetch<T>(endpoint);

    return {
      data: res,
      expired: false,
      error: false,
      errorData,
      statusCode: 200 // pgFetch throws for non-200
    };
  } catch (e) {
    // 502 is likely due to hitting a locked Seaware procedure.
    // Try a little later.
    const isRedisLockError =
      (e instanceof ServerError && e.message.includes('Redis lock')) ||
      (e as { statusCode: number })?.statusCode === 502;
    if (isRedisLockError && attemptNumber < maxAttempts) {
      console.error(
        `Received 502 from PG. Attempt #${
          attemptNumber + 1
        }. Retrying in ${delayBetweenAttempts}ms...`
      );
      return delay(
        () =>
          pgFetchWithRetry({
            endpoint,
            maxAttempts,
            delayBetweenAttempts,
            attemptNumber: attemptNumber + 1
          }),
        delayBetweenAttempts * (attemptNumber + 1)
      );
    }

    if (attemptNumber < maxAttempts) {
      console.error(`Non-502 error from PG while fetching from ${endpoint}.`);
    } else {
      console.error(`Max retries exceeded while fetching from ${endpoint}`);
    }
    console.error(e);

    errorData = {
      pgError: e,
      attemptNumber,
      statusCode: (e as { statusCode: number })?.statusCode
    };
  }

  return {
    expired: false,
    error: true,
    errorData,
    statusCode: errorData?.statusCode ?? 0
  };
};
