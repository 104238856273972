export const SectionNames = {
  Cruises: 'Cruises',
  Destinations: 'Destinations',
  Offers: 'Offers',
  Ships: 'Ships',
  WhyHX: 'Why HX?',
  CountrySelect: 'CountrySelect',
  ContactUs: 'Contact Us',
  Account: 'Account'
};
