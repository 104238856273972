import { forwardRef } from 'react';
import clsx from 'clsx';

import Icon from './Icon';

type TIconButtonProps = {
  buttonColor?:
    | 'black'
    | 'gray'
    | 'red'
    | 'transparent'
    | 'transparentDark'
    | 'white'
    | 'hxGray'
    | 'hxIndigo';
  height?: number;
  dataTestId?: string;
  icon: TIconType;
  iconClassName?: string;
  width?: number;
  isRounded?: boolean;
} & Omit<IButtonProps, 'aria-label' | 'buttonColor' | 'href' | 'rel'> &
  Required<{ 'aria-label': string }>;

const IconButton = forwardRef<HTMLButtonElement, TIconButtonProps>(
  (
    {
      buttonColor = 'gray',
      className,
      icon,
      iconClassName,
      isDisabled,
      size = 'large',
      type = 'button',
      dataTestId,
      isRounded,
      ...rest
    }: TIconButtonProps,
    ref
  ) => {
    const fillByButtonColors = {
      black: 'text-white group-hover:text-black',
      gray: 'text-black group-hover:text-white hover:text-white',
      white: 'text-black group-hover:text-white hover:text-white',
      red: 'text-white',
      transparent: 'text-black group-hover:text-white hover:text-white',
      transparentDark: 'text-white group-hover:text-black hover:text-black',
      hxGray: 'text-black group-hover:text-black hover:text-white',
      hxIndigo: 'text-white group-hover:text-white hover:text-white'
    };
    const classes = clsx(
      'group iconBtn flex items-center justify-center max-h-10',
      {
        'bg-white hover:bg-black': buttonColor === 'white',
        'bg-warm-gray-3 hover:bg-black': buttonColor === 'gray',
        'bg-hx-light-gray hover:bg-warm-gray-3': buttonColor === 'hxGray',
        'bg-primary-hx-indigo hover:bg-warm-gray-3': buttonColor === 'hxIndigo',
        'bg-transparent hover:bg-black active:bg-warm-gray-3':
          buttonColor === 'transparent',
        'bg-transparent hover:bg-white': buttonColor === 'transparentDark',
        'bg-black hover:bg-white': buttonColor === 'black',
        'bg-primary-hrg-red': buttonColor === 'red',
        'bg-white': buttonColor === 'white',
        '!bg-white !text-warm-gray-4': isDisabled,
        'w-10 h-10': size === 'large',
        'w-8 h-8': size === 'small',
        'rounded-full': isRounded,
        [className || '']: className
      }
    );

    return (
      <button
        ref={ref}
        className={classes}
        disabled={isDisabled}
        type={type}
        data-testid={dataTestId ?? 'button'}
        {...rest}
      >
        <Icon
          className={iconClassName}
          size={rest.iconSize}
          fill={
            isDisabled ? 'text-warm-gray-4' : fillByButtonColors[buttonColor]
          }
          icon={icon}
        />
      </button>
    );
  }
);

IconButton.displayName = 'IconButton';

export default IconButton;
