import { getSharedEnvironmentVariables } from '@utils/environmentVariables';

export const getValidLocales = () => {
  const validLocales = getSharedEnvironmentVariables().VALID_LOCALES;

  return validLocales ? (validLocales?.split(',') as TLocale[]) : [];
};

export const getSitemapLocales = () => {
  const sitemapLocales = getSharedEnvironmentVariables().SITEMAP_LOCALES;

  return sitemapLocales ? (sitemapLocales?.split(',') as TLocale[]) : [];
};

export const getLiveLocales = (): TLocale[] => {
  const liveLocales: TLocale[] = getValidLocales();
  return liveLocales;
};

export const DEFAULT_LOCALE_URL = `${
  getSharedEnvironmentVariables().WEBSITE_URL || 'https://www.travelhx.com/'
}/en/`;
