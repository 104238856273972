export const environmentVariablesWindowKey = '__ENVIRONMENT_VARIABLES';

export type TSharedEnviromentVariables = {
  TRACKJS_TOKEN?: string;
  TRACKJS_APP?: string;
  TRACKJS_ENABLE?: string;
  VALID_LOCALES?: string;
  PRELIMINARY_LOCALES?: string;
  SITEMAP_LOCALES?: string;
  WEBSITE_URL?: string;
  MAPBOX_ACCESS_TOKEN?: string;
  SMARTVEL_COVID_WIDGET_KEY?: string;
};

export const getSharedEnvironmentVariables = (): TSharedEnviromentVariables => {
  if (typeof window !== 'undefined') {
    return {
      TRACKJS_APP: window[environmentVariablesWindowKey]?.TRACKJS_APP,
      TRACKJS_ENABLE: window[environmentVariablesWindowKey]?.TRACKJS_ENABLE,
      TRACKJS_TOKEN: window[environmentVariablesWindowKey]?.TRACKJS_TOKEN,
      VALID_LOCALES: window[environmentVariablesWindowKey]?.VALID_LOCALES,
      SITEMAP_LOCALES: window[environmentVariablesWindowKey]?.SITEMAP_LOCALES,
      PRELIMINARY_LOCALES:
        window[environmentVariablesWindowKey]?.PRELIMINARY_LOCALES,
      WEBSITE_URL: window[environmentVariablesWindowKey]?.WEBSITE_URL,
      MAPBOX_ACCESS_TOKEN:
        window[environmentVariablesWindowKey]?.MAPBOX_ACCESS_TOKEN,
      SMARTVEL_COVID_WIDGET_KEY:
        window[environmentVariablesWindowKey]?.SMARTVEL_COVID_WIDGET_KEY
    };
  }
  return {
    TRACKJS_APP: process.env.TRACKJS_APP,
    TRACKJS_ENABLE: process.env.TRACKJS_ENABLE,
    TRACKJS_TOKEN: process.env.TRACKJS_TOKEN,
    VALID_LOCALES: process.env.VALID_LOCALES,
    PRELIMINARY_LOCALES: process.env.PRELIMINARY_LOCALES,
    SITEMAP_LOCALES: process.env.SITEMAP_LOCALES,
    WEBSITE_URL: process.env.WEBSITE_URL,
    MAPBOX_ACCESS_TOKEN: process.env.MAPBOX_ACCESS_TOKEN,
    SMARTVEL_COVID_WIDGET_KEY: process.env.SMARTVEL_COVID_WIDGET_KEY
  };
};
