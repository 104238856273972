import { useState, useEffect, useRef } from 'react';
import Flag from 'react-flagkit';
import clsx from 'clsx';
import { Icon } from '@hurtigruten/design-system-components';

import { combineRefs } from '@src/utils/combineRefs';
import { useLocaleHelpers, TCountryCode } from '@hooks/useLocaleHelpers';
import { DEFAULT_LOCALE_URL } from '@constants/locales';
import {
  addLocaleToLocalStorage,
  hxLocale
} from '@src/utils/locale/localeStorage';
import { useLocale, useTranslate, useMediaQuery } from '@hooks';
import { footer } from '@microcopies';
import { breakpoints } from '@src/utils';

import NavButton from './NavButton';

const dropDownClass = (open: boolean) =>
  clsx(
    'max-h-0 overflow-hidden w-[250px] absolute bg-white transition-all duration-100 ease-in-out-smooth z-100 top-[100%] ',
    {
      'max-h-[700px]': open
    }
  );

const hoverClass = () =>
  clsx(
    'inline bg-gradient-to-r from-black to-black bg-leftOffBottom bg-no-repeat bg-[length:0%_1px] w-fit duration-200 hover:bg-[length:100%_1px] transition-bgsize'
  );

const animateClass = (activeTab: boolean) =>
  clsx(
    ` block mb-6 text-sm opacity-0 translate-x-5 duration-[250ms] transition ease-in-out-smooth relative `,
    {
      'opacity-100 !translate-x-0 z-10': activeTab
    }
  );

export const IconHeader = ({ showTitle }: { showTitle: boolean }) => {
  const locale = useLocale();
  const { getSelectorCountryFromLocale } = useLocaleHelpers();
  const selectedLocale = getSelectorCountryFromLocale(locale);

  const flagLocale =
    locale === 'en' ? 'global' : locale.split('-')[1]?.toUpperCase();

  return (
    <div className="flex gap-3">
      {flagLocale === 'global' ? (
        <Icon graphic="globe" />
      ) : (
        <Flag country={flagLocale} />
      )}{' '}
      <p className={showTitle ? 'text-base ' : 'sr-only'}>
        {showTitle && selectedLocale?.name}
      </p>
    </div>
  );
};

export const CountriesList = ({
  activeTab,
  onClick
}: {
  activeTab: boolean;
  onClick: (arg: boolean) => void;
}) => {
  const locale = useLocale();
  const { selectorCountriesToLocaleMap: websites } = useLocaleHelpers();
  const translate = useTranslate(footer, (x) => x.footer);
  const isDesktop = useMediaQuery(breakpoints.tabletLandscape);

  const flagLocale =
    locale === 'en' ? 'EN' : locale.split('-')[1]?.toUpperCase();

  const optionsWebsites = websites.map((website) => ({
    description: website.name,
    value: website.countryCode
  }));

  const { getCountryFromCountryCode } = useLocaleHelpers();

  const addToStorage = (newLocale: TLocale) => {
    if (typeof window !== 'undefined') {
      addLocaleToLocalStorage(window, newLocale);
    }
  };

  const onSelect = (countryCode: TCountryCode | 'en') => {
    onClick(false);
    if (countryCode === 'en') {
      window.location.assign(`/en/`);
    } else {
      const website = getCountryFromCountryCode(countryCode);
      if (website) {
        addToStorage(website.locale as TLocale);
        const newLocale = website.locale as string;
        window.location.assign(`/${newLocale}/`);
        document.cookie = `${hxLocale}=${newLocale};path=/;maxAge=34560000`;
      } else {
        window.location.assign(DEFAULT_LOCALE_URL);
      }
    }
  };

  return (
    <>
      {isDesktop && (
        <p className={animateClass(activeTab)}>
          {translate((x) => x.changeCountry)}
        </p>
      )}

      {optionsWebsites.map((option, i) => (
        <div
          key={i}
          className={`flex gap-3  ${animateClass(activeTab)}`}
          style={{
            transitionDelay:
              option.value === 'EN' ? `0ms` : `${(i + 2) * 5}0ms`,
            order: option.value === 'EN' ? '0' : '1' // move global first
          }}
        >
          {option.value === 'EN' ? (
            <Icon graphic="globe" />
          ) : (
            <Flag country={option.value} size={20} className="rounded-sm" />
          )}
          <button
            className={`flex w-full justify-between gap-2 text-left`}
            onClick={() => onSelect(option.value)}
            tabIndex={activeTab ? 0 : -1}
          >
            {/* This div seems redundant but it's needed for the underline 
            animation to work correctly */}
            <div className="w-[calc(100%-24px)]">
              <span className={hoverClass()}>{option.description}</span>
            </div>
            {option.value === flagLocale && <Icon graphic="check-light" />}
          </button>
        </div>
      ))}
    </>
  );
};

const CountrySelectorDesktop = ({
  activeTab,
  menuAction,
  setCountrySelectorActive,
  tabIndex,
  parentRef
}: {
  activeTab: boolean;
  menuAction: boolean;
  setCountrySelectorActive: () => void;
  tabIndex: number;
  parentRef: (element: HTMLButtonElement) => void;
}) => {
  const [open, setOpen] = useState(false);
  const [dropdownOffset, setDropdownOffset] = useState<number>();
  const containerRef = useRef<HTMLButtonElement>(null);

  const clickAction = () => {
    setCountrySelectorActive();
    if (open) {
      setOpen(false);
    } else {
      const container = containerRef.current;
      if (container) {
        setDropdownOffset(container.offsetLeft);
      }
      setOpen(true);
    }
  };

  useEffect(() => {
    if (!activeTab) {
      setOpen(false);
    }
  }, [activeTab]);

  const dropdownPosition = dropdownOffset
    ? { left: dropdownOffset - 24 }
    : { right: 149 };

  return (
    <>
      <NavButton
        active={activeTab}
        open={menuAction}
        onClick={() => clickAction()}
        data-testid="country-selector-button"
        tabIndex={tabIndex}
        ref={combineRefs(parentRef, containerRef)}
      >
        <div className="flex -mr-1 mt-[2px]">
          <IconHeader showTitle={false} />
          <div
            className={clsx(
              'px-1 py-2 transition ease-in-out-smooth duration-300',
              {
                '-rotate-180': open
              }
            )}
          >
            <Icon graphic="arrowhead-down" />
          </div>
        </div>
      </NavButton>
      <div
        data-testid="MegaNavCountrySelector"
        className={dropDownClass(open)}
        style={dropdownPosition}
      >
        <div className="flex flex-col px-6 pt-6">
          <CountriesList activeTab={activeTab} onClick={() => setOpen(false)} />
        </div>
      </div>
    </>
  );
};

export default CountrySelectorDesktop;
