export class InvalidLocaleError extends Error {
  constructor(val: string) {
    super(`Invalid locale: ${val}`);
  }
}

export const mapLocaleToCurrency = (locale: TLocale): TCurrency => {
  switch (locale) {
    case 'en-gb':
      return 'GBP';
    case 'en-au':
      return 'AUD';
    case 'en-us':
      return 'USD';
    case 'de-de':
    case 'fr-fr':
    case 'en':
      return 'EUR';
    case 'de-ch':
      return 'CHF';
    case 'da-dk':
      return 'DKK';
    case 'nb-no':
      return 'NOK';
    case 'sv-se':
      return 'SEK';
    default:
      ((_: never) => _)(locale);
      throw new InvalidLocaleError(locale);
  }
};

export const mapLocaleToMarket = (locale: TLocale): TMarket => {
  switch (locale) {
    case 'en-gb':
    case 'en-au':
    case 'en':
    case 'de-ch':
      return 'UK';
    case 'en-us':
      return 'US';
    case 'de-de':
      return 'DE';
    case 'fr-fr':
      return 'FR';
    case 'da-dk':
    case 'nb-no':
    case 'sv-se':
      return 'NO';
    default:
      ((_: never) => _)(locale);
      throw new InvalidLocaleError(locale);
  }
};

export const mapContentfulLanguageToMarketTag = (
  locale: TContentfulLanguage
): TMarketTag => {
  switch (locale) {
    case 'en-AU':
      return 'marketAu';
    case 'en':
    case 'en-GB':
      return 'marketUk';
    case 'en-US':
      return 'marketUs';
    case 'de-DE':
      return 'marketDe';
    case 'gsw-CH':
      return 'marketCh';
    case 'fr-FR':
      return 'marketFr';
    case 'sv-SE':
      return 'marketSe';
    case 'nb-NO':
      return 'marketNo';
    case 'da-DK':
      return 'marketDk';
    default:
      ((_: never) => _)(locale);
      throw new InvalidLocaleError(locale);
  }
};

export const mapLocaleToMarketTag = (locale: TLocale): TMarketTag => {
  switch (locale) {
    case 'en':
      return 'marketEn';
    case 'en-gb':
      return 'marketUk';
    case 'en-au':
      return 'marketAu';
    case 'en-us':
      return 'marketUs';
    case 'de-de':
      return 'marketDe';
    case 'de-ch':
      return 'marketCh';
    case 'fr-fr':
      return 'marketFr';
    case 'sv-se':
      return 'marketSe';
    case 'da-dk':
      return 'marketDk';
    case 'nb-no':
      return 'marketNo';
    default:
      ((_: never) => _)(locale);
      throw new InvalidLocaleError(locale);
  }
};

export const mapContentfulLanguageToLocale = (
  contentfulLanguage: TLanguageLocaleCode
): TLocale => {
  switch (contentfulLanguage) {
    case 'en-GB':
      return 'en-gb';
    case 'en-AU':
      return 'en-au';
    case 'en-US':
      return 'en-us';
    case 'de-DE':
      return 'de-de';
    case 'gsw-CH':
      return 'de-ch';
    case 'da-DK':
      return 'da-dk';
    case 'nb-NO':
      return 'nb-no';
    case 'sv-SE':
      return 'sv-se';
    case 'fr-FR':
      return 'fr-fr';
    case 'en':
      return 'en';
    default:
      ((_: never) => _)(contentfulLanguage);
  }
  return 'en-au';
};

export const mapLocaleToContenfulFormat = (
  locale: TLocale
): TLanguageLocaleCode => {
  switch (locale) {
    case 'en-gb':
      return 'en-GB';
    case 'en-au':
      return 'en-AU';
    case 'en-us':
      return 'en-US';
    case 'de-de':
      return 'de-DE';
    case 'de-ch':
      return 'gsw-CH';
    case 'fr-fr':
      return 'fr-FR';
    case 'da-dk':
      return 'da-DK';
    case 'nb-no':
      return 'nb-NO';
    case 'sv-se':
      return 'sv-SE';
    case 'en':
      return 'en';
    default:
      ((_: never) => _)(locale);
      throw new InvalidLocaleError(locale);
  }
};

export const mapLocaleToTwoLetterCountryCode = (
  locale: TLocale
): TCountryCode => {
  switch (locale) {
    case 'en-gb':
    case 'en':
      return 'GB';
    case 'en-au':
      return 'AU';
    case 'en-us':
      return 'US';
    case 'de-de':
      return 'DE';
    case 'de-ch':
      return 'CH';
    case 'fr-fr':
      return 'FR';
    case 'da-dk':
      return 'DK';
    case 'nb-no':
      return 'NO';
    case 'sv-se':
      return 'SE';
    default:
      ((_: never) => _)(locale);
      throw new InvalidLocaleError(locale);
  }
};

export const mapLocaleToTwoLetterTrackingCountryCode = (
  locale: TLocale
): TTrackingCountryCode => {
  switch (locale) {
    case 'en-gb':
      return 'uk';
    case 'en-au':
      return 'au';
    case 'en-us':
      return 'us';
    case 'de-de':
      return 'de';
    case 'de-ch':
      return 'ch';
    case 'fr-fr':
      return 'fr';
    case 'nb-no':
      return 'no';
    case 'sv-se':
      return 'se';
    case 'da-dk':
      return 'dk';
    case 'en':
      return 'en';
    default:
      ((_: never) => _)(locale);
      throw new InvalidLocaleError(locale);
  }
};

export const mapLocaleToTwoLetterLanguageCode = (
  locale: TLocale
): TLanguageCode => {
  switch (locale) {
    case 'en-gb':
    case 'en-au':
    case 'en-us':
    case 'de-de':
    case 'de-ch':
    case 'da-dk':
    case 'sv-se':
    case 'nb-no':
    case 'en':
    case 'fr-fr':
      return 'en';
    default:
      ((_: never) => _)(locale);
      throw new InvalidLocaleError(locale);
  }
};

export const mapLocaleToISOLanguageCode = (
  locale: TLocale
): TAzureLanguageCode => {
  switch (locale) {
    case 'en-gb':
    case 'en-au':
    case 'en-us':
    case 'en':
      return 'en';
    case 'fr-fr':
      return 'fr';
    case 'da-dk':
      return 'da';
    case 'nb-no':
      return 'nb';
    case 'sv-se':
      return 'sv';
    case 'de-de':
    case 'de-ch':
      return 'de';
    default:
      ((_: never) => _)(locale);
      throw new InvalidLocaleError(locale);
  }
};

/* Currently only two successive fallbacks are supported, see mapSlugsToUrls */
export const mapLocaleToFallbackLanguage = (
  locale: TLocale | 'en'
): TFallbackLocale => {
  switch (locale) {
    case 'en':
    case 'en-gb':
    case 'en-au':
    case 'en-us':
    case 'de-de':
    case 'fr-fr':
    case 'da-dk':
    case 'sv-se':
    case 'nb-no':
      return 'en';
    case 'de-ch':
      return 'de-de';
    default:
      ((_: never) => _)(locale);
      throw new InvalidLocaleError(locale);
  }
};
