import { gql } from '@apollo/client';

import { MegaNav } from '@content/gql/graphql';

export type MegaNavData = MegaNav;

export const getMegaNav = gql`
  query GetMegaNav($locale: String!) {
    megaNav(locale: $locale) {
      sections {
        internalName
        navControl
        sectionName
        links {
          url
          label
        }
        featuredLinksHeading
        featuredLinks {
          label
          url
        }
        spotlight {
          id
          image {
            url
            alt
          }
          subtitle
          title
          url {
            url
            label
          }
          isNewsletter
        }
        featuredImage {
          url
          alt
        }
      }
    }
  }
`;
