import { createContext, useContext, ReactNode, useState } from 'react';

import { FooterInfo } from '@content/models/footerInfo';
import { MegaNavData } from '@content/queries/contentApi/getMegaNav';
import { HeaderRibbon } from '@content/gql/graphql';

type siteDataType = {
  footer?: FooterInfo;
  megaNav?: MegaNavData;
  headerRibbon?: HeaderRibbon;
};

type siteDataContextType = {
  data: siteDataType;
  setSiteData: (newData: siteDataType) => void;
};

const siteDataDefaultValues: siteDataContextType = {
  data: {
    footer: {
      aboutLinks: [],
      contactInfo: null,
      goToLinks: [],
      supportLinks: [],
      destinationLinks: [],
      coastalLinks: [],
      legalLinks: [],
      socialMediaLinks: []
    }
  },
  setSiteData: () => {}
};

const SiteDataContext = createContext<siteDataContextType>(
  siteDataDefaultValues
);

export function useSiteData() {
  return useContext(SiteDataContext);
}

type Props = {
  children: ReactNode;
  defaultData?: siteDataType;
};

export function SiteDataProvider({ children, defaultData }: Props) {
  const [data, setData] = useState<siteDataType>(
    defaultData || siteDataDefaultValues.data
  );

  const setSiteData = (newData: siteDataType) => {
    setData(newData);
  };

  const value = {
    data,
    setSiteData
  };

  return (
    <SiteDataContext.Provider value={value}>
      {children}
    </SiteDataContext.Provider>
  );
}
