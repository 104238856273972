export class ArgumentError extends Error {
  public argumentName: string;

  constructor({
    message,
    argumentName
  }: {
    message?: string;
    argumentName: string;
  }) {
    super(message);
    this.argumentName = argumentName;
  }
}

export class NetworkError extends Error {}

export class ClientError extends Error {
  public statusCode: number;

  constructor({
    statusCode,
    message
  }: {
    statusCode: number;
    message: string;
  }) {
    super(message);
    this.statusCode = statusCode;
  }
}
export class ServerError extends Error {
  public statusCode: number;

  constructor({
    statusCode,
    message
  }: {
    statusCode: number;
    message: string;
  }) {
    super(message);
    this.statusCode = statusCode;
  }
}

export class NotFoundError extends Error {}

export class ParseError extends Error {}
