import { useEffect, useMemo, useState } from 'react';

import { TQuoteMetaInformationWithTimestamp } from '@src/types/quoteMetaInformation';
import { BookingSteps } from '@constants';
import {
  getEndDate,
  getStartDate,
  getVoyageDetails
} from '@src/utils/mappers/quote';
import { getDateDifferenceInDays } from '@src/utils';
import { TVoyageBookingFlowInformation } from '@content/requests/voyage/getVoyageBookingFlowInformationBySlug';
import lastUpdatedToHoldExpires from '@utils/booking/lastUpdatedToHoldExpires';

import { useWatchedStorage } from './useWatchedStorage';

const QUOTE_META_STORAGE_KEY = 'NELLIE_BOOKING_IN_PROGRESS';
const QUOTE_PREVIEW_CLOSED_STORAGE_KEY =
  'NELLIE_BOOKING_IN_PROGRESS_PREVIEW_CLOSED';

type TQuotePersistArguments = {
  voyage: TVoyageBookingFlowInformation;
  quote: PG.Response.Quote.TRootObject;
  voyageId: string;
  step: BookingSteps;
  cabinIndex?: number;
};

const useList = () => {
  const res = useWatchedStorage<TQuoteMetaInformationWithTimestamp[]>(
    QUOTE_META_STORAGE_KEY
  );
  return res;
};

const isNotExpiredByMoreThanThreeMinutes = (
  x: TQuoteMetaInformationWithTimestamp
) => {
  // Remove if expired for 3 minutes
  const now = new Date().getTime();
  if (now > x.holdExpires + 180000) {
    return false;
  }
  return true;
};

export const usePersistQuoteMetaInformation = ({
  quote,
  voyage,
  voyageId,
  step,
  cabinIndex
}: TQuotePersistArguments) => {
  const { set } = useList();

  useEffect(() => {
    const voyageDetails = getVoyageDetails(quote);

    const startDate = getStartDate(quote);
    const endDate = getEndDate(quote);

    if (voyageDetails && quote.price) {
      const dataToPersist: TQuoteMetaInformationWithTimestamp = {
        quoteId: quote.quoteId,
        voyageId,
        voyageSlug: voyage.slug,
        step,
        cabinIndex,
        preview: {
          currency: quote.currency,
          departureDate: startDate ? startDate.toISOString() : null,
          duration:
            endDate && startDate
              ? getDateDifferenceInDays(startDate, endDate)
              : null,
          imageSrc: voyage.highlightedImage?.url ?? null,
          price: quote.price,
          strikeThroughPrice: quote.priceDetail.strikeThroughPrice,
          voyageName: voyage.name
        },
        savedAt: Date.now(),
        holdExpires: lastUpdatedToHoldExpires(quote.lastUpdatedInSeaware)
      };

      set((oldValue) => {
        const list =
          oldValue
            ?.filter((x) => x.quoteId !== dataToPersist.quoteId)
            .filter(isNotExpiredByMoreThanThreeMinutes) ?? [];
        list.push(dataToPersist);
        return list;
      });
    }
  }, [quote, voyage, step, cabinIndex, voyageId]);
};

export const useIsMetadataPreviewOpen = (quoteIds: string[]) => {
  const [openIds, setOpenIds] = useState<string[]>([]);
  const { data, set } = useWatchedStorage<string[]>(
    QUOTE_PREVIEW_CLOSED_STORAGE_KEY
  );

  useEffect(() => {
    setOpenIds(quoteIds.filter((id) => !data?.includes(id)));
  }, [quoteIds, data]);

  return {
    openIds,
    closePreview: (quoteId: string) => {
      set((oldValue) => {
        const closed = new Set(oldValue);
        closed.add(quoteId);
        return Array.from(closed);
      });
      setOpenIds(openIds.filter((id) => id !== quoteId));
    }
  };
};

export const useQuoteMetaInformationList = () => {
  const { data, set } = useList();
  const allQuoteIds = useMemo(() => data?.map((x) => x.quoteId) ?? [], [data]);
  const { openIds, closePreview } = useIsMetadataPreviewOpen(allQuoteIds);

  return {
    list: data
      ?.filter((x) => openIds.includes(x.quoteId))
      .filter(isNotExpiredByMoreThanThreeMinutes),
    set,
    closePreview
  };
};

export const useQuoteMetaInformation = (quoteId: string) => {
  const { data } = useList();

  return data?.find((x) => x.quoteId === quoteId);
};

export const useCleanMetaInformation = (quoteId: string) => {
  const { set } = useList();

  useEffect(() => {
    set((oldValue) => {
      const arr = oldValue ?? [];
      return arr.filter((x) => x.quoteId !== quoteId);
    });
  }, []);
};
