/* eslint-disable max-len */
// Auto generated file. Do not edit.

export const country: Record<string, TCountry> = {
  'en-AU': {
    country: {
      AD: 'Andorra',
      AE: 'United Arab Emirates',
      AF: 'Afghanistan',
      AG: 'Antigua and Barbuda',
      AI: 'Anguilla',
      AL: 'Albania',
      AM: 'Armenia',
      AO: 'Angola',
      AQ: 'Antarctica',
      AR: 'Argentina',
      AS: 'American Samoa',
      AT: 'Austria',
      AU: 'Australia',
      AW: 'Aruba',
      AX: 'Åland Islands',
      AZ: 'Azerbaijan',
      BA: 'Bosnia and Herzegovina',
      BB: 'Barbados',
      BD: 'Bangladesh',
      BE: 'Belgium',
      BF: 'Burkina Faso',
      BG: 'Bulgaria',
      BH: 'Bahrain',
      BI: 'Burundi',
      BJ: 'Benin',
      BL: 'Saint Barthélemy',
      BM: 'Bermuda',
      BN: 'Brunei',
      BO: 'Bolivia',
      BQ: 'Bonaire',
      BR: 'Brazil',
      BS: 'The Bahamas',
      BT: 'Bhutan',
      BV: 'Bouvet Island',
      BW: 'Botswana',
      BY: 'Belarus',
      BZ: 'Belize',
      CA: 'Canada',
      CC: 'Cocos (Keeling) Islands',
      CD: 'Democratic Republic of the Congo',
      CF: 'Central African Republic',
      CG: 'Republic of the Congo',
      CH: 'Switzerland',
      CI: 'Ivory Coast',
      CK: 'Cook Islands',
      CL: 'Chile',
      CM: 'Cameroon',
      CN: 'China',
      CO: 'Colombia',
      CR: 'Costa Rica',
      CU: 'Cuba',
      CV: 'Cape Verde',
      CW: 'Curaçao',
      CX: 'Christmas Island',
      CY: 'Cyprus',
      CZ: 'Czech Republic',
      DE: 'Germany',
      DJ: 'Djibouti',
      DK: 'Denmark',
      DM: 'Dominica',
      DO: 'Dominican Republic',
      DZ: 'Algeria',
      EE: 'Estonia',
      EC: 'Ecuador',
      EG: 'Egypt',
      EH: 'Western Sahara',
      EN: 'Global',
      ER: 'Eritrea',
      ES: 'Spain',
      ET: 'Ethiopia',
      FI: 'Finland',
      FJ: 'Fiji',
      FK: 'Falkland Islands',
      FM: 'Federated States of Micronesia',
      FO: 'Faroe Islands',
      FR: 'France',
      GA: 'Gabon',
      GB: 'United Kingdom',
      GD: 'Grenada',
      GE: 'Georgia',
      GF: 'French Guiana',
      GG: 'Guernsey',
      GI: 'Gibraltar',
      GH: 'Ghana',
      GL: 'Greenland',
      GM: 'The Gambia',
      GN: 'Guinea',
      GP: 'Guadeloupe',
      GQ: 'Equatorial Guinea',
      GR: 'Greece',
      GS: 'South Georgia',
      GT: 'Guatemala',
      GU: 'Guam',
      GW: 'Guinea-Bissau',
      GY: 'Guyana',
      HK: 'Hong Kong',
      HM: 'Heard Island and McDonald Islands',
      HN: 'Honduras',
      HR: 'Croatia',
      HT: 'Haiti',
      HU: 'Hungary',
      ID: 'Indonesia',
      IE: 'Republic of Ireland',
      IL: 'Israel',
      IM: 'Isle of Man',
      IN: 'India',
      IO: 'British Indian Ocean Territory',
      IQ: 'Iraq',
      IR: 'Iran',
      IS: 'Iceland',
      IT: 'Italy',
      JE: 'Jersey',
      JM: 'Jamaica',
      JO: 'Jordan',
      JP: 'Japan',
      KE: 'Kenya',
      KG: 'Kyrgyzstan',
      KH: 'Cambodia',
      KI: 'Kiribati',
      KM: 'Comoros',
      KN: 'Saint Kitts and Nevis',
      KP: 'North Korea',
      KR: 'South Korea',
      KW: 'Kuwait',
      KY: 'Cayman Islands',
      KZ: 'Kazakhstan',
      LA: 'Laos',
      LB: 'Lebanon',
      LC: 'Saint Lucia',
      LI: 'Liechtenstein',
      LK: 'Sri Lanka',
      LR: 'Liberia',
      LS: 'Lesotho',
      LT: 'Lithuania',
      LU: 'Luxembourg',
      LV: 'Latvia',
      MA: 'Morocco',
      LY: 'Libya',
      MC: 'Monaco',
      MD: 'Moldova',
      ME: 'Montenegro',
      MG: 'Madagascar',
      MF: 'Saint Martin',
      MH: 'Marshall Islands',
      MK: 'Republic of Macedonia',
      ML: 'Mali',
      MM: 'Myanmar',
      MN: 'Mongolia',
      MO: 'Macau',
      MP: 'Northern Mariana Islands',
      MQ: 'Martinique',
      MR: 'Mauritania',
      MS: 'Montserrat',
      MT: 'Malta',
      MU: 'Mauritius',
      MV: 'Maldives',
      MW: 'Malawi',
      MX: 'Mexico',
      MY: 'Malaysia',
      MZ: 'Mozambique',
      NA: 'Namibia',
      NC: 'New Caledonia',
      NE: 'Niger',
      NF: 'Norfolk Island',
      NG: 'Nigeria',
      NI: 'Nicaragua',
      NL: 'Netherlands',
      NO: 'Norway',
      NP: 'Nepal',
      NR: 'Nauru',
      NU: 'Niue',
      NZ: 'New Zealand',
      OM: 'Oman',
      PA: 'Panama',
      PE: 'Peru',
      PF: 'French Polynesia',
      PG: 'Papua New Guinea',
      PH: 'Philippines',
      PK: 'Pakistan',
      PL: 'Poland',
      PM: 'Saint Pierre and Miquelon',
      PN: 'Pitcairn Islands',
      PR: 'Puerto Rico',
      PS: 'Palestine',
      PT: 'Portugal',
      PW: 'Palau',
      PY: 'Paraguay',
      QA: 'Qatar',
      RE: 'Réunion',
      RO: 'Romania',
      RS: 'Serbia',
      RU: 'Russia',
      RW: 'Rwanda',
      SA: 'Saudi Arabia',
      SB: 'Solomon Islands',
      SC: 'Seychelles',
      SD: 'Sudan',
      SE: 'Sweden',
      SG: 'Singapore',
      SH: 'Saint Helena',
      SI: 'Slovenia',
      SJ: 'Svalbard and Jan Mayen',
      SK: 'Slovakia',
      SL: 'Sierra Leone',
      SM: 'San Marino',
      SN: 'Senegal',
      SO: 'Somalia',
      SR: 'Suriname',
      SS: 'South Sudan',
      ST: 'São Tomé and Príncipe',
      SV: 'El Salvador',
      SX: 'Sint Maarten',
      SY: 'Syria',
      SZ: 'Swaziland',
      TC: 'Turks and Caicos Islands',
      TD: 'Chad',
      TF: 'French Southern and Antarctic Lands',
      TG: 'Togo',
      TH: 'Thailand',
      TJ: 'Tajikistan',
      TK: 'Tokelau',
      TL: 'East Timor',
      TM: 'Turkmenistan',
      TN: 'Tunisia',
      TO: 'Tonga',
      TR: 'Turkey',
      TT: 'Trinidad and Tobago',
      TV: 'Tuvalu',
      TW: 'Taiwan',
      TZ: 'Tanzania',
      UA: 'Ukraine',
      UG: 'Uganda',
      UM: 'United States Minor Outlying Islands',
      US: 'United States',
      UY: 'Uruguay',
      UZ: 'Uzbekistan',
      VA: 'Holy See',
      VC: 'Saint Vincent and the Grenadines',
      VE: 'Venezuela',
      VG: 'Virgin Islands (British)',
      VI: 'Virgin Islands (U.S.)',
      VN: 'Vietnam',
      VU: 'Vanuatu',
      WF: 'Wallis and Futuna',
      WS: 'Samoa',
      XK: 'Republic of Kosovo',
      YE: 'Yemen',
      YT: 'Mayotte',
      ZA: 'South Africa',
      ZM: 'Zambia',
      ZW: 'Zimbabwe'
    }
  },
  'en-GB': {
    country: {
      AD: 'Andorra',
      AE: 'United Arab Emirates',
      AF: 'Afghanistan',
      AG: 'Antigua and Barbuda',
      AI: 'Anguilla',
      AL: 'Albania',
      AM: 'Armenia',
      AO: 'Angola',
      AQ: 'Antarctica',
      AR: 'Argentina',
      AS: 'American Samoa',
      AT: 'Austria',
      AU: 'Australia',
      AW: 'Aruba',
      AX: 'Åland Islands',
      AZ: 'Azerbaijan',
      BA: 'Bosnia and Herzegovina',
      BB: 'Barbados',
      BD: 'Bangladesh',
      BE: 'Belgium',
      BF: 'Burkina Faso',
      BG: 'Bulgaria',
      BH: 'Bahrain',
      BI: 'Burundi',
      BJ: 'Benin',
      BL: 'Saint Barthélemy',
      BM: 'Bermuda',
      BN: 'Brunei',
      BO: 'Bolivia',
      BQ: 'Bonaire',
      BR: 'Brazil',
      BS: 'The Bahamas',
      BT: 'Bhutan',
      BV: 'Bouvet Island',
      BW: 'Botswana',
      BY: 'Belarus',
      BZ: 'Belize',
      CA: 'Canada',
      CC: 'Cocos (Keeling) Islands',
      CD: 'Democratic Republic of the Congo',
      CF: 'Central African Republic',
      CG: 'Republic of the Congo',
      CH: 'Switzerland',
      CI: 'Ivory Coast',
      CK: 'Cook Islands',
      CL: 'Chile',
      CM: 'Cameroon',
      CN: 'China',
      CO: 'Colombia',
      CR: 'Costa Rica',
      CU: 'Cuba',
      CV: 'Cape Verde',
      CW: 'Curaçao',
      CX: 'Christmas Island',
      CY: 'Cyprus',
      CZ: 'Czech Republic',
      DE: 'Germany',
      DJ: 'Djibouti',
      DK: 'Denmark',
      DM: 'Dominica',
      DO: 'Dominican Republic',
      DZ: 'Algeria',
      EE: 'Estonia',
      EC: 'Ecuador',
      EG: 'Egypt',
      EH: 'Western Sahara',
      EN: 'Global',
      ER: 'Eritrea',
      ES: 'Spain',
      ET: 'Ethiopia',
      FI: 'Finland',
      FJ: 'Fiji',
      FK: 'Falkland Islands',
      FM: 'Federated States of Micronesia',
      FO: 'Faroe Islands',
      FR: 'France',
      GA: 'Gabon',
      GB: 'United Kingdom',
      GD: 'Grenada',
      GE: 'Georgia',
      GF: 'French Guiana',
      GG: 'Guernsey',
      GI: 'Gibraltar',
      GH: 'Ghana',
      GL: 'Greenland',
      GM: 'The Gambia',
      GN: 'Guinea',
      GP: 'Guadeloupe',
      GQ: 'Equatorial Guinea',
      GR: 'Greece',
      GS: 'South Georgia',
      GT: 'Guatemala',
      GU: 'Guam',
      GW: 'Guinea-Bissau',
      GY: 'Guyana',
      HK: 'Hong Kong',
      HM: 'Heard Island and McDonald Islands',
      HN: 'Honduras',
      HR: 'Croatia',
      HT: 'Haiti',
      HU: 'Hungary',
      ID: 'Indonesia',
      IE: 'Republic of Ireland',
      IL: 'Israel',
      IM: 'Isle of Man',
      IN: 'India',
      IO: 'British Indian Ocean Territory',
      IQ: 'Iraq',
      IR: 'Iran',
      IS: 'Iceland',
      IT: 'Italy',
      JE: 'Jersey',
      JM: 'Jamaica',
      JO: 'Jordan',
      JP: 'Japan',
      KE: 'Kenya',
      KG: 'Kyrgyzstan',
      KH: 'Cambodia',
      KI: 'Kiribati',
      KM: 'Comoros',
      KN: 'Saint Kitts and Nevis',
      KP: 'North Korea',
      KR: 'South Korea',
      KW: 'Kuwait',
      KY: 'Cayman Islands',
      KZ: 'Kazakhstan',
      LA: 'Laos',
      LB: 'Lebanon',
      LC: 'Saint Lucia',
      LI: 'Liechtenstein',
      LK: 'Sri Lanka',
      LR: 'Liberia',
      LS: 'Lesotho',
      LT: 'Lithuania',
      LU: 'Luxembourg',
      LV: 'Latvia',
      MA: 'Morocco',
      LY: 'Libya',
      MC: 'Monaco',
      MD: 'Moldova',
      ME: 'Montenegro',
      MG: 'Madagascar',
      MF: 'Saint Martin',
      MH: 'Marshall Islands',
      MK: 'Republic of Macedonia',
      ML: 'Mali',
      MM: 'Myanmar',
      MN: 'Mongolia',
      MO: 'Macau',
      MP: 'Northern Mariana Islands',
      MQ: 'Martinique',
      MR: 'Mauritania',
      MS: 'Montserrat',
      MT: 'Malta',
      MU: 'Mauritius',
      MV: 'Maldives',
      MW: 'Malawi',
      MX: 'Mexico',
      MY: 'Malaysia',
      MZ: 'Mozambique',
      NA: 'Namibia',
      NC: 'New Caledonia',
      NE: 'Niger',
      NF: 'Norfolk Island',
      NG: 'Nigeria',
      NI: 'Nicaragua',
      NL: 'Netherlands',
      NO: 'Norway',
      NP: 'Nepal',
      NR: 'Nauru',
      NU: 'Niue',
      NZ: 'New Zealand',
      OM: 'Oman',
      PA: 'Panama',
      PE: 'Peru',
      PF: 'French Polynesia',
      PG: 'Papua New Guinea',
      PH: 'Philippines',
      PK: 'Pakistan',
      PL: 'Poland',
      PM: 'Saint Pierre and Miquelon',
      PN: 'Pitcairn Islands',
      PR: 'Puerto Rico',
      PS: 'Palestine',
      PT: 'Portugal',
      PW: 'Palau',
      PY: 'Paraguay',
      QA: 'Qatar',
      RE: 'Réunion',
      RO: 'Romania',
      RS: 'Serbia',
      RU: 'Russia',
      RW: 'Rwanda',
      SA: 'Saudi Arabia',
      SB: 'Solomon Islands',
      SC: 'Seychelles',
      SD: 'Sudan',
      SE: 'Sweden',
      SG: 'Singapore',
      SH: 'Saint Helena',
      SI: 'Slovenia',
      SJ: 'Svalbard and Jan Mayen',
      SK: 'Slovakia',
      SL: 'Sierra Leone',
      SM: 'San Marino',
      SN: 'Senegal',
      SO: 'Somalia',
      SR: 'Suriname',
      SS: 'South Sudan',
      ST: 'São Tomé and Príncipe',
      SV: 'El Salvador',
      SX: 'Sint Maarten',
      SY: 'Syria',
      SZ: 'Swaziland',
      TC: 'Turks and Caicos Islands',
      TD: 'Chad',
      TF: 'French Southern and Antarctic Lands',
      TG: 'Togo',
      TH: 'Thailand',
      TJ: 'Tajikistan',
      TK: 'Tokelau',
      TL: 'East Timor',
      TM: 'Turkmenistan',
      TN: 'Tunisia',
      TO: 'Tonga',
      TR: 'Turkey',
      TT: 'Trinidad and Tobago',
      TV: 'Tuvalu',
      TW: 'Taiwan',
      TZ: 'Tanzania',
      UA: 'Ukraine',
      UG: 'Uganda',
      UM: 'United States Minor Outlying Islands',
      US: 'United States',
      UY: 'Uruguay',
      UZ: 'Uzbekistan',
      VA: 'Holy See',
      VC: 'Saint Vincent and the Grenadines',
      VE: 'Venezuela',
      VG: 'Virgin Islands (British)',
      VI: 'Virgin Islands (U.S.)',
      VN: 'Vietnam',
      VU: 'Vanuatu',
      WF: 'Wallis and Futuna',
      WS: 'Samoa',
      XK: 'Republic of Kosovo',
      YE: 'Yemen',
      YT: 'Mayotte',
      ZA: 'South Africa',
      ZM: 'Zambia',
      ZW: 'Zimbabwe'
    }
  },
  'en-US': {
    country: {
      AD: 'Andorra',
      AE: 'United Arab Emirates',
      AF: 'Afghanistan',
      AG: 'Antigua and Barbuda',
      AI: 'Anguilla',
      AL: 'Albania',
      AM: 'Armenia',
      AO: 'Angola',
      AQ: 'Antarctica',
      AR: 'Argentina',
      AS: 'American Samoa',
      AT: 'Austria',
      AU: 'Australia',
      AW: 'Aruba',
      AX: 'Åland Islands',
      AZ: 'Azerbaijan',
      BA: 'Bosnia and Herzegovina',
      BB: 'Barbados',
      BD: 'Bangladesh',
      BE: 'Belgium',
      BF: 'Burkina Faso',
      BG: 'Bulgaria',
      BH: 'Bahrain',
      BI: 'Burundi',
      BJ: 'Benin',
      BL: 'Saint Barthélemy',
      BM: 'Bermuda',
      BN: 'Brunei',
      BO: 'Bolivia',
      BQ: 'Bonaire',
      BR: 'Brazil',
      BS: 'The Bahamas',
      BT: 'Bhutan',
      BV: 'Bouvet Island',
      BW: 'Botswana',
      BY: 'Belarus',
      BZ: 'Belize',
      CA: 'Canada',
      CC: 'Cocos (Keeling) Islands',
      CD: 'Democratic Republic of the Congo',
      CF: 'Central African Republic',
      CG: 'Republic of the Congo',
      CH: 'Switzerland',
      CI: 'Ivory Coast',
      CK: 'Cook Islands',
      CL: 'Chile',
      CM: 'Cameroon',
      CN: 'China',
      CO: 'Colombia',
      CR: 'Costa Rica',
      CU: 'Cuba',
      CV: 'Cape Verde',
      CW: 'Curaçao',
      CX: 'Christmas Island',
      CY: 'Cyprus',
      CZ: 'Czech Republic',
      DE: 'Germany',
      DJ: 'Djibouti',
      DK: 'Denmark',
      DM: 'Dominica',
      DO: 'Dominican Republic',
      DZ: 'Algeria',
      EE: 'Estonia',
      EC: 'Ecuador',
      EG: 'Egypt',
      EH: 'Western Sahara',
      EN: 'Global',
      ER: 'Eritrea',
      ES: 'Spain',
      ET: 'Ethiopia',
      FI: 'Finland',
      FJ: 'Fiji',
      FK: 'Falkland Islands',
      FM: 'Federated States of Micronesia',
      FO: 'Faroe Islands',
      FR: 'France',
      GA: 'Gabon',
      GB: 'United Kingdom',
      GD: 'Grenada',
      GE: 'Georgia',
      GF: 'French Guiana',
      GG: 'Guernsey',
      GI: 'Gibraltar',
      GH: 'Ghana',
      GL: 'Greenland',
      GM: 'The Gambia',
      GN: 'Guinea',
      GP: 'Guadeloupe',
      GQ: 'Equatorial Guinea',
      GR: 'Greece',
      GS: 'South Georgia',
      GT: 'Guatemala',
      GU: 'Guam',
      GW: 'Guinea-Bissau',
      GY: 'Guyana',
      HK: 'Hong Kong',
      HM: 'Heard Island and McDonald Islands',
      HN: 'Honduras',
      HR: 'Croatia',
      HT: 'Haiti',
      HU: 'Hungary',
      ID: 'Indonesia',
      IE: 'Republic of Ireland',
      IL: 'Israel',
      IM: 'Isle of Man',
      IN: 'India',
      IO: 'British Indian Ocean Territory',
      IQ: 'Iraq',
      IR: 'Iran',
      IS: 'Iceland',
      IT: 'Italy',
      JE: 'Jersey',
      JM: 'Jamaica',
      JO: 'Jordan',
      JP: 'Japan',
      KE: 'Kenya',
      KG: 'Kyrgyzstan',
      KH: 'Cambodia',
      KI: 'Kiribati',
      KM: 'Comoros',
      KN: 'Saint Kitts and Nevis',
      KP: 'North Korea',
      KR: 'South Korea',
      KW: 'Kuwait',
      KY: 'Cayman Islands',
      KZ: 'Kazakhstan',
      LA: 'Laos',
      LB: 'Lebanon',
      LC: 'Saint Lucia',
      LI: 'Liechtenstein',
      LK: 'Sri Lanka',
      LR: 'Liberia',
      LS: 'Lesotho',
      LT: 'Lithuania',
      LU: 'Luxembourg',
      LV: 'Latvia',
      MA: 'Morocco',
      LY: 'Libya',
      MC: 'Monaco',
      MD: 'Moldova',
      ME: 'Montenegro',
      MG: 'Madagascar',
      MF: 'Saint Martin',
      MH: 'Marshall Islands',
      MK: 'Republic of Macedonia',
      ML: 'Mali',
      MM: 'Myanmar',
      MN: 'Mongolia',
      MO: 'Macau',
      MP: 'Northern Mariana Islands',
      MQ: 'Martinique',
      MR: 'Mauritania',
      MS: 'Montserrat',
      MT: 'Malta',
      MU: 'Mauritius',
      MV: 'Maldives',
      MW: 'Malawi',
      MX: 'Mexico',
      MY: 'Malaysia',
      MZ: 'Mozambique',
      NA: 'Namibia',
      NC: 'New Caledonia',
      NE: 'Niger',
      NF: 'Norfolk Island',
      NG: 'Nigeria',
      NI: 'Nicaragua',
      NL: 'Netherlands',
      NO: 'Norway',
      NP: 'Nepal',
      NR: 'Nauru',
      NU: 'Niue',
      NZ: 'New Zealand',
      OM: 'Oman',
      PA: 'Panama',
      PE: 'Peru',
      PF: 'French Polynesia',
      PG: 'Papua New Guinea',
      PH: 'Philippines',
      PK: 'Pakistan',
      PL: 'Poland',
      PM: 'Saint Pierre and Miquelon',
      PN: 'Pitcairn Islands',
      PR: 'Puerto Rico',
      PS: 'Palestine',
      PT: 'Portugal',
      PW: 'Palau',
      PY: 'Paraguay',
      QA: 'Qatar',
      RE: 'Réunion',
      RO: 'Romania',
      RS: 'Serbia',
      RU: 'Russia',
      RW: 'Rwanda',
      SA: 'Saudi Arabia',
      SB: 'Solomon Islands',
      SC: 'Seychelles',
      SD: 'Sudan',
      SE: 'Sweden',
      SG: 'Singapore',
      SH: 'Saint Helena',
      SI: 'Slovenia',
      SJ: 'Svalbard and Jan Mayen',
      SK: 'Slovakia',
      SL: 'Sierra Leone',
      SM: 'San Marino',
      SN: 'Senegal',
      SO: 'Somalia',
      SR: 'Suriname',
      SS: 'South Sudan',
      ST: 'São Tomé and Príncipe',
      SV: 'El Salvador',
      SX: 'Sint Maarten',
      SY: 'Syria',
      SZ: 'Swaziland',
      TC: 'Turks and Caicos Islands',
      TD: 'Chad',
      TF: 'French Southern and Antarctic Lands',
      TG: 'Togo',
      TH: 'Thailand',
      TJ: 'Tajikistan',
      TK: 'Tokelau',
      TL: 'East Timor',
      TM: 'Turkmenistan',
      TN: 'Tunisia',
      TO: 'Tonga',
      TR: 'Turkey',
      TT: 'Trinidad and Tobago',
      TV: 'Tuvalu',
      TW: 'Taiwan',
      TZ: 'Tanzania',
      UA: 'Ukraine',
      UG: 'Uganda',
      UM: 'United States Minor Outlying Islands',
      US: 'United States',
      UY: 'Uruguay',
      UZ: 'Uzbekistan',
      VA: 'Holy See',
      VC: 'Saint Vincent and the Grenadines',
      VE: 'Venezuela',
      VG: 'Virgin Islands (British)',
      VI: 'Virgin Islands (U.S.)',
      VN: 'Vietnam',
      VU: 'Vanuatu',
      WF: 'Wallis and Futuna',
      WS: 'Samoa',
      XK: 'Republic of Kosovo',
      YE: 'Yemen',
      YT: 'Mayotte',
      ZA: 'South Africa',
      ZM: 'Zambia',
      ZW: 'Zimbabwe'
    }
  },
  'de-DE': {
    country: {
      AD: 'Andorra',
      AE: 'Vereinigte Arabische Emirate',
      AF: 'Afghanistan',
      AG: 'Antigua und Barbuda',
      AI: 'Anguilla',
      AL: 'Albanien',
      AM: 'Armenien',
      AO: 'Angola',
      AQ: 'Antarktis',
      AR: 'Argentinien',
      AS: 'Amerikanisch-Samoa',
      AT: 'Österreich',
      AU: 'Australien',
      AW: 'Aruba',
      AX: 'Åland-Inseln',
      AZ: 'Aserbaidschan',
      BA: 'Bosnien und Herzegowina',
      BB: 'Barbados',
      BD: 'Bangladesch',
      BE: 'Belgien',
      BF: 'Burkina Faso',
      BG: 'Bulgarien',
      BH: 'Bahrain',
      BI: 'Burundi',
      BJ: 'Benin',
      BL: 'St. Barthélemy',
      BM: 'Bermuda',
      BN: 'Brunei',
      BO: 'Bolivien',
      BQ: 'Bonaire',
      BR: 'Brasilien',
      BS: 'Die Bahamas',
      BT: 'Bhutan',
      BV: 'Bouvetinsel',
      BW: 'Botswana',
      BY: 'Weißrussland',
      BZ: 'Belize',
      CA: 'Kanada',
      CC: 'Kokosinseln (Keeling)',
      CD: 'Demokratische Republik Kongo',
      CF: 'Zentralafrikanische Republik',
      CG: 'Republik Kongo',
      CH: 'Schweiz',
      CI: 'Elfenbeinküste',
      CK: 'Cook-Inseln',
      CL: 'Chile',
      CM: 'Kamerun',
      CN: 'China',
      CO: 'Kolumbien',
      CR: 'Costa Rica',
      CU: 'Kuba',
      CV: 'Kap Verde',
      CW: 'Curaçao',
      CX: 'Weihnachtsinsel',
      CY: 'Zypern',
      CZ: 'Tschechische Republik',
      DE: 'Deutschland',
      DJ: 'Dschibuti',
      DK: 'Dänemark',
      DM: 'Dominica',
      DO: 'Dominikanische Republik',
      DZ: 'Algerien',
      EE: 'Estland',
      EC: 'Ecuador',
      EG: 'Ägypten',
      EH: 'Westsahara',
      EN: 'Global',
      ER: 'Eritrea',
      ES: 'Spanien',
      ET: 'Äthiopien',
      FI: 'Finnland',
      FJ: 'Fidschi',
      FK: 'Falklandinseln',
      FM: 'Föderierte Staaten von Mikronesien',
      FO: 'Färöer-Inseln',
      FR: 'Frankreich',
      GA: 'Gabun',
      GB: 'Vereinigtes Königreich (England, Schottland, Wales und Nordirland)',
      GD: 'Grenada',
      GE: 'Georgien',
      GF: 'Französisch-Guayana',
      GG: 'Guernsey',
      GI: 'Gibraltar',
      GH: 'Ghana',
      GL: 'Grönland',
      GM: 'Gambia',
      GN: 'Guinea',
      GP: 'Guadeloupe',
      GQ: 'Äquatorialguinea',
      GR: 'Griechenland',
      GS: 'Südgeorgien',
      GT: 'Guatemala',
      GU: 'Guam',
      GW: 'Guinea-Bissau',
      GY: 'Guyana',
      HK: 'Hongkong',
      HM: 'Heard und die McDonaldinseln',
      HN: 'Honduras',
      HR: 'Kroatien',
      HT: 'Haiti',
      HU: 'Ungarn',
      ID: 'Indonesien',
      IE: 'Republik Irland',
      IL: 'Israel',
      IM: 'Isle of Man',
      IN: 'Indien',
      IO: 'Britisches Territorium im Indischen Ozean',
      IQ: 'Irak',
      IR: 'Iran',
      IS: 'Island',
      IT: 'Italien',
      JE: 'Jersey',
      JM: 'Jamaika',
      JO: 'Jordanien',
      JP: 'Japan',
      KE: 'Kenia',
      KG: 'Kirgisistan',
      KH: 'Kambodscha',
      KI: 'Kiribati',
      KM: 'Komoren',
      KN: 'St. Kitts und Nevis',
      KP: 'Nordkorea',
      KR: 'Südkorea',
      KW: 'Kuwait',
      KY: 'Kaimaninseln',
      KZ: 'Kasachstan',
      LA: 'Laos',
      LB: 'Libanon',
      LC: 'St. Lucia',
      LI: 'Liechtenstein',
      LK: 'Sri Lanka',
      LR: 'Liberia',
      LS: 'Lesotho',
      LT: 'Litauen',
      LU: 'Luxemburg',
      LV: 'Lettland',
      MA: 'Marokko',
      LY: 'Libyen',
      MC: 'Monaco',
      MD: 'Moldawien',
      ME: 'Montenegro',
      MG: 'Madagaskar',
      MF: 'St. Martin',
      MH: 'Marshallinseln',
      MK: 'Republik Mazedonien',
      ML: 'Mali',
      MM: 'Myanmar',
      MN: 'Mongolei',
      MO: 'Macau',
      MP: 'Nördliche Marianen',
      MQ: 'Martinique',
      MR: 'Mauretanien',
      MS: 'Montserrat',
      MT: 'Malta',
      MU: 'Mauritius',
      MV: 'Malediven',
      MW: 'Malawi',
      MX: 'Mexiko',
      MY: 'Malaysia',
      MZ: 'Mosambik',
      NA: 'Namibia',
      NC: 'Neukaledonien',
      NE: 'Niger',
      NF: 'Norfolkinsel',
      NG: 'Nigeria',
      NI: 'Nicaragua',
      NL: 'Niederlande',
      NO: 'Norwegen',
      NP: 'Nepal',
      NR: 'Nauru',
      NU: 'Niue',
      NZ: 'Neuseeland',
      OM: 'Oman',
      PA: 'Panama',
      PE: 'Peru',
      PF: 'Französisch-Polynesien',
      PG: 'Papua-Neuguinea',
      PH: 'Philippinen',
      PK: 'Pakistan',
      PL: 'Polen',
      PM: 'St. Pierre und Miquelon',
      PN: 'Pitcairninseln',
      PR: 'Puerto Rico',
      PS: 'Palästina',
      PT: 'Portugal',
      PW: 'Palau',
      PY: 'Paraguay',
      QA: 'Katar',
      RE: 'Réunion',
      RO: 'Rumänien',
      RS: 'Serbien',
      RU: 'Russland',
      RW: 'Ruanda',
      SA: 'Saudi-Arabien',
      SB: 'Salomonen',
      SC: 'Seychellen',
      SD: 'Sudan',
      SE: 'Schweden',
      SG: 'Singapur',
      SH: 'St. Helena',
      SI: 'Slowenien',
      SJ: 'Spitzbergen und Jan Mayen',
      SK: 'Slowakei',
      SL: 'Sierra Leone',
      SM: 'San Marino',
      SN: 'Senegal',
      SO: 'Somalia',
      SR: 'Surinam',
      SS: 'Südsudan',
      ST: 'São Tomé und Príncipe',
      SV: 'El Salvador',
      SX: 'Sint Maarten',
      SY: 'Syrien',
      SZ: 'Swasiland',
      TC: 'Turks- und Caicosinseln',
      TD: 'Tschad',
      TF: 'Französische Süd- und Antarktisgebiete',
      TG: 'Togo',
      TH: 'Thailand',
      TJ: 'Tadschikistan',
      TK: 'Tokelau',
      TL: 'Osttimor',
      TM: 'Turkmenistan',
      TN: 'Tunesien',
      TO: 'Tonga',
      TR: 'Türkei',
      TT: 'Trinidad und Tobago',
      TV: 'Tuvalu',
      TW: 'Taiwan',
      TZ: 'Tansania',
      UA: 'Ukraine',
      UG: 'Uganda',
      UM: 'Kleinere Inselbesitzungen der Vereinigten Staaten',
      US: 'USA',
      UY: 'Uruguay',
      UZ: 'Usbekistan',
      VA: 'Heiliger Stuhl',
      VC: 'St. Vincent und die Grenadinen',
      VE: 'Venezuela',
      VG: 'Britische Jungferninseln',
      VI: 'Amerikanische Jungferninseln',
      VN: 'Vietnam',
      VU: 'Vanuatu',
      WF: 'Wallis und Futuna',
      WS: 'Samoa',
      XK: 'Republik Kosovo',
      YE: 'Jemen',
      YT: 'Mayotte',
      ZA: 'Südafrika',
      ZM: 'Sambia',
      ZW: 'Simbabwe'
    }
  },
  'gsw-CH': {
    country: {
      AD: 'Andorra',
      AE: 'Vereinigte Arabische Emirate',
      AF: 'Afghanistan',
      AG: 'Antigua und Barbuda',
      AI: 'Anguilla',
      AL: 'Albanien',
      AM: 'Armenien',
      AO: 'Angola',
      AQ: 'Antarktis',
      AR: 'Argentinien',
      AS: 'Amerikanisch-Samoa',
      AT: 'Österreich',
      AU: 'Australien',
      AW: 'Aruba',
      AX: 'Åland-Inseln',
      AZ: 'Aserbaidschan',
      BA: 'Bosnien und Herzegowina',
      BB: 'Barbados',
      BD: 'Bangladesch',
      BE: 'Belgien',
      BF: 'Burkina Faso',
      BG: 'Bulgarien',
      BH: 'Bahrain',
      BI: 'Burundi',
      BJ: 'Benin',
      BL: 'St. Barthélemy',
      BM: 'Bermuda',
      BN: 'Brunei',
      BO: 'Bolivien',
      BQ: 'Bonaire',
      BR: 'Brasilien',
      BS: 'Die Bahamas',
      BT: 'Bhutan',
      BV: 'Bouvetinsel',
      BW: 'Botswana',
      BY: 'Weißrussland',
      BZ: 'Belize',
      CA: 'Kanada',
      CC: 'Kokosinseln (Keeling)',
      CD: 'Demokratische Republik Kongo',
      CF: 'Zentralafrikanische Republik',
      CG: 'Republik Kongo',
      CH: 'Schweiz',
      CI: 'Elfenbeinküste',
      CK: 'Cook-Inseln',
      CL: 'Chile',
      CM: 'Kamerun',
      CN: 'China',
      CO: 'Kolumbien',
      CR: 'Costa Rica',
      CU: 'Kuba',
      CV: 'Kap Verde',
      CW: 'Curaçao',
      CX: 'Weihnachtsinsel',
      CY: 'Zypern',
      CZ: 'Tschechische Republik',
      DE: 'Deutschland',
      DJ: 'Dschibuti',
      DK: 'Dänemark',
      DM: 'Dominica',
      DO: 'Dominikanische Republik',
      DZ: 'Algerien',
      EE: 'Estland',
      EC: 'Ecuador',
      EG: 'Ägypten',
      EH: 'Westsahara',
      EN: 'Global',
      ER: 'Eritrea',
      ES: 'Spanien',
      ET: 'Äthiopien',
      FI: 'Finnland',
      FJ: 'Fidschi',
      FK: 'Falklandinseln',
      FM: 'Föderierte Staaten von Mikronesien',
      FO: 'Färöer-Inseln',
      FR: 'Frankreich',
      GA: 'Gabun',
      GB: 'Vereinigtes Königreich (England, Schottland, Wales und Nordirland)',
      GD: 'Grenada',
      GE: 'Georgien',
      GF: 'Französisch-Guayana',
      GG: 'Guernsey',
      GI: 'Gibraltar',
      GH: 'Ghana',
      GL: 'Grönland',
      GM: 'Gambia',
      GN: 'Guinea',
      GP: 'Guadeloupe',
      GQ: 'Äquatorialguinea',
      GR: 'Griechenland',
      GS: 'Südgeorgien',
      GT: 'Guatemala',
      GU: 'Guam',
      GW: 'Guinea-Bissau',
      GY: 'Guyana',
      HK: 'Hongkong',
      HM: 'Heard und die McDonaldinseln',
      HN: 'Honduras',
      HR: 'Kroatien',
      HT: 'Haiti',
      HU: 'Ungarn',
      ID: 'Indonesien',
      IE: 'Republik Irland',
      IL: 'Israel',
      IM: 'Isle of Man',
      IN: 'Indien',
      IO: 'Britisches Territorium im Indischen Ozean',
      IQ: 'Irak',
      IR: 'Iran',
      IS: 'Island',
      IT: 'Italien',
      JE: 'Jersey',
      JM: 'Jamaika',
      JO: 'Jordanien',
      JP: 'Japan',
      KE: 'Kenia',
      KG: 'Kirgisistan',
      KH: 'Kambodscha',
      KI: 'Kiribati',
      KM: 'Komoren',
      KN: 'St. Kitts und Nevis',
      KP: 'Nordkorea',
      KR: 'Südkorea',
      KW: 'Kuwait',
      KY: 'Kaimaninseln',
      KZ: 'Kasachstan',
      LA: 'Laos',
      LB: 'Libanon',
      LC: 'St. Lucia',
      LI: 'Liechtenstein',
      LK: 'Sri Lanka',
      LR: 'Liberia',
      LS: 'Lesotho',
      LT: 'Litauen',
      LU: 'Luxemburg',
      LV: 'Lettland',
      MA: 'Marokko',
      LY: 'Libyen',
      MC: 'Monaco',
      MD: 'Moldawien',
      ME: 'Montenegro',
      MG: 'Madagaskar',
      MF: 'St. Martin',
      MH: 'Marshallinseln',
      MK: 'Republik Mazedonien',
      ML: 'Mali',
      MM: 'Myanmar',
      MN: 'Mongolei',
      MO: 'Macau',
      MP: 'Nördliche Marianen',
      MQ: 'Martinique',
      MR: 'Mauretanien',
      MS: 'Montserrat',
      MT: 'Malta',
      MU: 'Mauritius',
      MV: 'Malediven',
      MW: 'Malawi',
      MX: 'Mexiko',
      MY: 'Malaysia',
      MZ: 'Mosambik',
      NA: 'Namibia',
      NC: 'Neukaledonien',
      NE: 'Niger',
      NF: 'Norfolkinsel',
      NG: 'Nigeria',
      NI: 'Nicaragua',
      NL: 'Niederlande',
      NO: 'Norwegen',
      NP: 'Nepal',
      NR: 'Nauru',
      NU: 'Niue',
      NZ: 'Neuseeland',
      OM: 'Oman',
      PA: 'Panama',
      PE: 'Peru',
      PF: 'Französisch-Polynesien',
      PG: 'Papua-Neuguinea',
      PH: 'Philippinen',
      PK: 'Pakistan',
      PL: 'Polen',
      PM: 'St. Pierre und Miquelon',
      PN: 'Pitcairninseln',
      PR: 'Puerto Rico',
      PS: 'Palästina',
      PT: 'Portugal',
      PW: 'Palau',
      PY: 'Paraguay',
      QA: 'Katar',
      RE: 'Réunion',
      RO: 'Rumänien',
      RS: 'Serbien',
      RU: 'Russland',
      RW: 'Ruanda',
      SA: 'Saudi-Arabien',
      SB: 'Salomonen',
      SC: 'Seychellen',
      SD: 'Sudan',
      SE: 'Schweden',
      SG: 'Singapur',
      SH: 'St. Helena',
      SI: 'Slowenien',
      SJ: 'Spitzbergen und Jan Mayen',
      SK: 'Slowakei',
      SL: 'Sierra Leone',
      SM: 'San Marino',
      SN: 'Senegal',
      SO: 'Somalia',
      SR: 'Surinam',
      SS: 'Südsudan',
      ST: 'São Tomé und Príncipe',
      SV: 'El Salvador',
      SX: 'Sint Maarten',
      SY: 'Syrien',
      SZ: 'Swasiland',
      TC: 'Turks- und Caicosinseln',
      TD: 'Tschad',
      TF: 'Französische Süd- und Antarktisgebiete',
      TG: 'Togo',
      TH: 'Thailand',
      TJ: 'Tadschikistan',
      TK: 'Tokelau',
      TL: 'Osttimor',
      TM: 'Turkmenistan',
      TN: 'Tunesien',
      TO: 'Tonga',
      TR: 'Türkei',
      TT: 'Trinidad und Tobago',
      TV: 'Tuvalu',
      TW: 'Taiwan',
      TZ: 'Tansania',
      UA: 'Ukraine',
      UG: 'Uganda',
      UM: 'Kleinere Inselbesitzungen der Vereinigten Staaten',
      US: 'USA',
      UY: 'Uruguay',
      UZ: 'Usbekistan',
      VA: 'Heiliger Stuhl',
      VC: 'St. Vincent und die Grenadinen',
      VE: 'Venezuela',
      VG: 'Britische Jungferninseln',
      VI: 'Amerikanische Jungferninseln',
      VN: 'Vietnam',
      VU: 'Vanuatu',
      WF: 'Wallis und Futuna',
      WS: 'Samoa',
      XK: 'Republik Kosovo',
      YE: 'Jemen',
      YT: 'Mayotte',
      ZA: 'Südafrika',
      ZM: 'Sambia',
      ZW: 'Simbabwe'
    }
  },
  'fr-FR': {
    country: {
      AD: 'Andorre',
      AE: 'Émirats arabes unis',
      AF: 'Afghanistan',
      AG: 'Antigua-et-Barbuda',
      AI: 'Anguilla',
      AL: 'Albanie',
      AM: 'Arménie',
      AO: 'Angola',
      AQ: 'Antarctique',
      AR: 'Argentine',
      AS: 'Samoa américaines',
      AT: 'Autriche',
      AU: 'Australie',
      AW: 'Aruba',
      AX: 'Îles d’Åland',
      AZ: 'Azerbaïdjan',
      BA: 'Bosnie-Herzégovine',
      BB: 'La Barbade',
      BD: 'Bangladesh',
      BE: 'Belgique',
      BF: 'Burkina Faso',
      BG: 'Bulgarie',
      BH: 'Bahreïn',
      BI: 'Burundi',
      BJ: 'Bénin',
      BL: 'Saint-Barthélemy',
      BM: 'Bermudes',
      BN: 'Brunei',
      BO: 'Bolivie',
      BQ: 'Bonaire',
      BR: 'Brésil',
      BS: 'Les Bahamas',
      BT: 'Bhoutan',
      BV: 'Île Bouvet',
      BW: 'Botswana',
      BY: 'Bélarus',
      BZ: 'Belize',
      CA: 'Canada',
      CC: 'Îles Cocos (Keeling)',
      CD: 'République démocratique du Congo',
      CF: 'République centrafricaine',
      CG: 'République du Congo',
      CH: 'Suisse',
      CI: 'Côte d’Ivoire',
      CK: 'Îles Cook',
      CL: 'Chili',
      CM: 'Cameroun',
      CN: 'Chine',
      CO: 'Colombie',
      CR: 'Costa Rica',
      CU: 'Cuba',
      CV: 'Cap-Vert',
      CW: 'Curaçao',
      CX: 'Île Christmas',
      CY: 'Chypre',
      CZ: 'République tchèque',
      DE: 'Allemagne',
      DJ: 'Djibouti',
      DK: 'Danemark',
      DM: 'Dominique',
      DO: 'République dominicaine',
      DZ: 'Algérie',
      EE: 'Estonie',
      EC: 'Équateur',
      EG: 'Égypte',
      EH: 'Sahara-Occidental',
      EN: 'Global',
      ER: 'Érythrée',
      ES: 'Espagne',
      ET: 'Éthiopie',
      FI: 'Finlande',
      FJ: 'Fidji',
      FK: 'Îles Malouines',
      FM: 'États fédérés de Micronésie',
      FO: 'Îles Féroé',
      FR: 'France',
      GA: 'Gabon',
      GB: 'Royaume-Uni',
      GD: 'Grenade',
      GE: 'Géorgie',
      GF: 'Guyane française',
      GG: 'Guernesey',
      GI: 'Gibraltar',
      GH: 'Ghana',
      GL: 'Groenland',
      GM: 'Gambie',
      GN: 'Guinée',
      GP: 'Guadeloupe',
      GQ: 'Guinée équatoriale',
      GR: 'Grèce',
      GS: 'Géorgie du Sud',
      GT: 'Guatémala',
      GU: 'Guam',
      GW: 'Guinée-Bissau',
      GY: 'Guyane',
      HK: 'Hong Kong',
      HM: 'Îles Heard-et-MacDonald',
      HN: 'Honduras',
      HR: 'Croatie',
      HT: 'Haïti',
      HU: 'Hongrie',
      ID: 'Indonésie',
      IE: 'République d’Irlande',
      IL: 'Israël',
      IM: 'Île de Man',
      IN: 'Inde',
      IO: 'Territoire britannique de l’océan Indien',
      IQ: 'Irak',
      IR: 'Iran',
      IS: 'Islande',
      IT: 'Italie',
      JE: 'Jersey',
      JM: 'Jamaïque',
      JO: 'Jordanie',
      JP: 'Japon',
      KE: 'Kenya',
      KG: 'Kirghizistan',
      KH: 'Cambodge',
      KI: 'Kiribati',
      KM: 'Comores',
      KN: 'Saint-Kitts-et-Nevis',
      KP: 'Corée du Nord',
      KR: 'Corée du Sud',
      KW: 'Koweït',
      KY: 'Îles Caïmans',
      KZ: 'Kazakhstan',
      LA: 'Laos',
      LB: 'Liban',
      LC: 'Sainte-Lucie',
      LI: 'Liechtenstein',
      LK: 'Sri Lanka',
      LR: 'Libéria',
      LS: 'Lesotho',
      LT: 'Lituanie',
      LU: 'Luxembourg',
      LV: 'Lettonie',
      MA: 'Maroc',
      LY: 'Libye',
      MC: 'Monaco',
      MD: 'Moldavie',
      ME: 'Monténégro',
      MG: 'Madagascar',
      MF: 'Saint-Martin',
      MH: 'Îles Marshall',
      MK: 'République de Macédoine',
      ML: 'Mali',
      MM: 'Myanmar',
      MN: 'Mongolie',
      MO: 'Macao',
      MP: 'Îles Mariannes du Nord',
      MQ: 'Martinique',
      MR: 'Mauritanie',
      MS: 'Montserrat',
      MT: 'Malte',
      MU: 'Maurice',
      MV: 'Maldives',
      MW: 'Malawi',
      MX: 'Mexique',
      MY: 'Malaisie',
      MZ: 'Mozambique',
      NA: 'Namibie',
      NC: 'Nouvelle-Calédonie',
      NE: 'Niger',
      NF: 'Île Norfolk',
      NG: 'Nigéria',
      NI: 'Nicaragua',
      NL: 'Pays-Bas',
      NO: 'Norvège',
      NP: 'Népal',
      NR: 'Nauru',
      NU: 'Niue',
      NZ: 'Nouvelle-Zélande',
      OM: 'Oman',
      PA: 'Panama',
      PE: 'Pérou',
      PF: 'Polynésie française',
      PG: 'Papouasie-Nouvelle-Guinée',
      PH: 'Philippines',
      PK: 'Pakistan',
      PL: 'Pologne',
      PM: 'Saint-Pierre-et-Miquelon',
      PN: 'Îles Pitcairn',
      PR: 'Porto Rico',
      PS: 'Palestine',
      PT: 'Portugal',
      PW: 'Palaos',
      PY: 'Paraguay',
      QA: 'Qatar',
      RE: 'Réunion',
      RO: 'Roumanie',
      RS: 'Serbie',
      RU: 'Russie',
      RW: 'Rwanda',
      SA: 'Arabie saoudite',
      SB: 'Îles Salomon',
      SC: 'Seychelles',
      SD: 'Soudan',
      SE: 'Suède',
      SG: 'Singapour',
      SH: 'Sainte-Hélène',
      SI: 'Slovénie',
      SJ: 'Svalbard et Jan Mayen',
      SK: 'Slovaquie',
      SL: 'Sierra Leone',
      SM: 'Saint-Marin',
      SN: 'Sénégal',
      SO: 'Somalie',
      SR: 'Suriname',
      SS: 'Soudan du Sud',
      ST: 'Sao Tomé-et-Principe',
      SV: 'Salvador',
      SX: 'Saint-Martin',
      SY: 'Syrie',
      SZ: 'Eswatini',
      TC: 'Îles Turques-et-Caïques',
      TD: 'Tchad',
      TF: 'Terres australes et antarctiques françaises',
      TG: 'Togo',
      TH: 'Thaïlande',
      TJ: 'Tadjikistan',
      TK: 'Tokelau',
      TL: 'Timor-Leste',
      TM: 'Turkménistan',
      TN: 'Tunisie',
      TO: 'Tonga',
      TR: 'Turquie',
      TT: 'Trinité-et-Tobago',
      TV: 'Tuvalu',
      TW: 'Taïwan',
      TZ: 'Tanzanie',
      UA: 'Ukraine',
      UG: 'Ouganda',
      UM: 'Îles mineures éloignées des États-Unis',
      US: 'États-Unis',
      UY: 'Uruguay',
      UZ: 'Ouzbékistan',
      VA: 'Saint-Siège',
      VC: 'Saint-Vincent-et-les-Grenadines',
      VE: 'Vénézuéla',
      VG: 'Îles Vierges (britanniques)',
      VI: 'Îles Vierges (américaines)',
      VN: 'Viet Nam',
      VU: 'Vanuatu',
      WF: 'Wallis-et-Futuna',
      WS: 'Samoa',
      XK: 'République du Kosovo',
      YE: 'Yémen',
      YT: 'Mayotte',
      ZA: 'Afrique du Sud',
      ZM: 'Zambie',
      ZW: 'Zimbabwe'
    }
  },
  'da-DK': {
    country: {
      AD: 'Andorra',
      AE: 'Forenede Arabiske Emirater',
      AF: 'Afghanistan',
      AG: 'Antigua og Barbuda',
      AI: 'Anguilla',
      AL: 'Albanien',
      AM: 'Armenien',
      AO: 'Angola',
      AQ: 'Antarktis',
      AR: 'Argentina',
      AS: 'Amerikansk Samoa',
      AT: 'Østrig',
      AU: 'Australien',
      AW: 'Aruba',
      AX: 'Ålandsøerne',
      AZ: 'Aserbajdsjan',
      BA: 'Bosnien-Hercegovina',
      BB: 'Barbados',
      BD: 'Bangladesh',
      BE: 'Belgien',
      BF: 'Burkina Faso',
      BG: 'Bulgarien',
      BH: 'Bahrain',
      BI: 'Burundi',
      BJ: 'Benin',
      BL: 'Saint Barthélemy',
      BM: 'Bermuda',
      BN: 'Brunei',
      BO: 'Bolivia',
      BQ: 'Bonaire',
      BR: 'Brasilien',
      BS: 'Bahamas',
      BT: 'Bhutan',
      BV: 'Bouvetøen',
      BW: 'Botswana',
      BY: 'Belarus',
      BZ: 'Belize',
      CA: 'Canada',
      CC: 'Cocosøerne, Keeling Islands',
      CD: 'Den Demokratiske Republik Congo',
      CF: 'Den Centralafrikanske Republik',
      CG: 'Congo',
      CH: 'Schweiz',
      CI: 'Elfenbenskysten',
      CK: 'Cookøerne',
      CL: 'Chile',
      CM: 'Cameroun',
      CN: 'Kina',
      CO: 'Colombia',
      CR: 'Costa Rica',
      CU: 'Cuba',
      CV: 'Kap Verde',
      CW: 'Curaçao',
      CX: 'Juleøen',
      CY: 'Cypern',
      CZ: 'Tjekkiet',
      DE: 'Tyskland',
      DJ: 'Djibouti',
      DK: 'Danmark',
      DM: 'Dominica',
      DO: 'Dominikanske Republik',
      DZ: 'Algeriet',
      EE: 'Estland',
      EC: 'Ecuador',
      EG: 'Egypten',
      EH: 'Vestsahara',
      EN: 'Global',
      ER: 'Eritrea',
      ES: 'Spanien',
      ET: 'Etiopien',
      FI: 'Finland',
      FJ: 'Fiji',
      FK: 'Falkland Islands',
      FM: 'Mikronesiens Forenede Stater',
      FO: 'Færøerne',
      FR: 'Frankrig',
      GA: 'Gabon',
      GB: 'Storbritannien',
      GD: 'Grenada',
      GE: 'Georgien',
      GF: 'Fransk Guyana',
      GG: 'Guernsey',
      GI: 'Gibraltar',
      GH: 'Ghana',
      GL: 'Grønland',
      GM: 'Gambia',
      GN: 'Guinea',
      GP: 'Guadeloupe',
      GQ: 'Ækvatorialguinea',
      GR: 'Grækenland',
      GS: 'South Georgia',
      GT: 'Guatemala',
      GU: 'Guam',
      GW: 'Guinea-Bissau',
      GY: 'Guyana',
      HK: 'Hongkong',
      HM: 'Heard Island og McDonald Island',
      HN: 'Honduras',
      HR: 'Kroatien',
      HT: 'Haiti',
      HU: 'Ungarn',
      ID: 'Indonesien',
      IE: 'Irland',
      IL: 'Israel',
      IM: 'Isle of Man',
      IN: 'Indien',
      IO: 'Det Britiske Territorium i Det Indiske Ocean',
      IQ: 'Irak',
      IR: 'Iran',
      IS: 'Island',
      IT: 'Italien',
      JE: 'Jersey',
      JM: 'Jamaica',
      JO: 'Jordan',
      JP: 'Japan',
      KE: 'Kenya',
      KG: 'Kirgisistan',
      KH: 'Cambodia',
      KI: 'Kiribati',
      KM: 'Comorerne',
      KN: 'Saint Kitts og Nevis',
      KP: 'Nordkorea',
      KR: 'Sydkorea',
      KW: 'Kuwait',
      KY: 'Caymanøerne',
      KZ: 'Kasakhstan',
      LA: 'Laos',
      LB: 'Libanon',
      LC: 'Saint Lucia',
      LI: 'Liechtenstein',
      LK: 'Sri Lanka',
      LR: 'Liberia',
      LS: 'Lesotho',
      LT: 'Litauen',
      LU: 'Luxembourg',
      LV: 'Letland',
      MA: 'Marokko',
      LY: 'Libyen',
      MC: 'Monaco',
      MD: 'Moldova',
      ME: 'Montenegro',
      MG: 'Madagaskar',
      MF: 'Saint Martin',
      MH: 'Marshalløerne',
      MK: 'Nordmakedonien',
      ML: 'Mali',
      MM: 'Myanmar',
      MN: 'Mongoliet',
      MO: 'Macau',
      MP: 'Nordmarianerne',
      MQ: 'Martinique',
      MR: 'Mauretanien',
      MS: 'Montserrat',
      MT: 'Malta',
      MU: 'Mauritius',
      MV: 'Maldiverne',
      MW: 'Malawi',
      MX: 'Mexico',
      MY: 'Malaysia',
      MZ: 'Mozambique',
      NA: 'Namibia',
      NC: 'Ny Kaledonien',
      NE: 'Niger',
      NF: 'Norfolk Island',
      NG: 'Nigeria',
      NI: 'Nicaragua',
      NL: 'Holland',
      NO: 'Norge',
      NP: 'Nepal',
      NR: 'Nauru',
      NU: 'Niue',
      NZ: 'New Zealand',
      OM: 'Oman',
      PA: 'Panama',
      PE: 'Peru',
      PF: 'Fransk Polynesien',
      PG: 'Papua New Guinea',
      PH: 'Filippinerne',
      PK: 'Pakistan',
      PL: 'Polen',
      PM: 'Saint Pierre og Miquelon',
      PN: 'Pitcairn-øerne',
      PR: 'Puerto Rico',
      PS: 'Palæstina',
      PT: 'Portugal',
      PW: 'Palau',
      PY: 'Paraguay',
      QA: 'Qatar',
      RE: 'Réunion',
      RO: 'Rumænien',
      RS: 'Serbien',
      RU: 'Rusland',
      RW: 'Rwanda',
      SA: 'Saudi-Arabien',
      SB: 'Salomonøerne',
      SC: 'Seychellerne',
      SD: 'Sudan',
      SE: 'Sverige',
      SG: 'Singapore',
      SH: 'Saint Helena',
      SI: 'Slovenien',
      SJ: 'Svalbard og Jan Mayen',
      SK: 'Slovakiet',
      SL: 'Sierra Leone',
      SM: 'San Marino',
      SN: 'Senegal',
      SO: 'Somalia',
      SR: 'Surinam',
      SS: 'Sydsudan',
      ST: 'São Tomé og Príncipe',
      SV: 'El Salvador',
      SX: 'Sint Maarten',
      SY: 'Syrien',
      SZ: 'Swaziland',
      TC: 'Turks- og Caicosøerne',
      TD: 'Tchad',
      TF: 'De Franske Sydlige og Antarktiske Territorier',
      TG: 'Togo',
      TH: 'Thailand',
      TJ: 'Tadsjikistan',
      TK: 'Tokelau',
      TL: 'Østtimor',
      TM: 'Turkmenistan',
      TN: 'Tunesien',
      TO: 'Tonga',
      TR: 'Tyrkiet',
      TT: 'Trinidad og Tobago',
      TV: 'Tuvalu',
      TW: 'Taiwan',
      TZ: 'Tanzania',
      UA: 'Ukraine',
      UG: 'Uganda',
      UM: 'USA\'s ydre småøer',
      US: 'USA',
      UY: 'Uruguay',
      UZ: 'Usbekistan',
      VA: 'Vatikanstaten',
      VC: 'Saint Vincent og Grenadinerne',
      VE: 'Venezuela',
      VG: 'De Britiske Jomfruøer',
      VI: 'De Amerikanske Jomfruøer',
      VN: 'Vietnam',
      VU: 'Vanuatu',
      WF: 'Wallis og Futuna',
      WS: 'Samoa',
      XK: 'Kosovo',
      YE: 'Yemen',
      YT: 'Mayotte',
      ZA: 'Sydafrika',
      ZM: 'Zambia',
      ZW: 'Zimbabwe'
    }
  },
  'sv-SE': {
    country: {
      AD: 'Andorra',
      AE: 'Förenade Arabemiraten',
      AF: 'Afghanistan',
      AG: 'Antigua och Barbuda',
      AI: 'Anguilla',
      AL: 'Albanien',
      AM: 'Armenien',
      AO: 'Angola',
      AQ: 'Antarktis',
      AR: 'Argentina',
      AS: 'Amerikanska Samoa',
      AT: 'Österrike',
      AU: 'Australien',
      AW: 'Aruba',
      AX: 'Åland',
      AZ: 'Azerbajdzjan',
      BA: 'Bosnien och Hercegovina',
      BB: 'Barbados',
      BD: 'Bangladesh',
      BE: 'Belgien',
      BF: 'Burkina Faso',
      BG: 'Bulgarien',
      BH: 'Bahrain',
      BI: 'Burundi',
      BJ: 'Benin',
      BL: 'Saint-Barthélemy',
      BM: 'Bermuda',
      BN: 'Brunei',
      BO: 'Bolivia',
      BQ: 'Bonaire',
      BR: 'Brasilien',
      BS: 'Bahamas',
      BT: 'Bhutan',
      BV: 'Bouvetön',
      BW: 'Botswana',
      BY: 'Belarus',
      BZ: 'Belize',
      CA: 'Kanada',
      CC: 'Kokosöarna',
      CD: 'Demokratiska republiken Kongo',
      CF: 'Centralafrikanska republiken',
      CG: 'Republiken Kongo',
      CH: 'Schweiz',
      CI: 'Elfenbenskusten',
      CK: 'Cooköarna',
      CL: 'Chile',
      CM: 'Kamerun',
      CN: 'Kina',
      CO: 'Colombia',
      CR: 'Costa Rica',
      CU: 'Kuba',
      CV: 'Kap Verde',
      CW: 'Curaçao',
      CX: 'Julön',
      CY: 'Cypern',
      CZ: 'Tjeckien',
      DE: 'Tyskland',
      DJ: 'Djibouti',
      DK: 'Danmark',
      DM: 'Dominica',
      DO: 'Dominikanska republiken',
      DZ: 'Algeriet',
      EE: 'Estland',
      EC: 'Ecuador',
      EG: 'Egypten',
      EH: 'Västsahara',
      EN: 'Global',
      ER: 'Eritrea',
      ES: 'Spanien',
      ET: 'Etiopien',
      FI: 'Finland',
      FJ: 'Fiji',
      FK: 'Falklandsöarna',
      FM: 'Mikronesiens federerade stater',
      FO: 'Färöarna',
      FR: 'Frankrike',
      GA: 'Gabon',
      GB: 'Storbritannien',
      GD: 'Grenada',
      GE: 'Georgien',
      GF: 'Franska Guyana',
      GG: 'Guernsey',
      GI: 'Gibraltar',
      GH: 'Ghana',
      GL: 'Grönland',
      GM: 'Gambia',
      GN: 'Guinea',
      GP: 'Guadeloupe',
      GQ: 'Ekvatorialguinea',
      GR: 'Grekland',
      GS: 'Sydgeorgien',
      GT: 'Guatemala',
      GU: 'Guam',
      GW: 'Guinea-Bissau',
      GY: 'Guyana',
      HK: 'Hongkong',
      HM: 'Heard- och McDonaldsöarna',
      HN: 'Honduras',
      HR: 'Kroatien',
      HT: 'Haiti',
      HU: 'Ungern',
      ID: 'Indonesien',
      IE: 'Irland',
      IL: 'Israel',
      IM: 'Isle of Man',
      IN: 'Indien',
      IO: 'Brittiska territoriet i Indiska oceanen',
      IQ: 'Irak',
      IR: 'Iran',
      IS: 'Island',
      IT: 'Italien',
      JE: 'Jersey',
      JM: 'Jamaica',
      JO: 'Jordanien',
      JP: 'Japan',
      KE: 'Kenya',
      KG: 'Kirgizistan',
      KH: 'Kambodja',
      KI: 'Kiribati',
      KM: 'Komorerna',
      KN: 'Saint Kitts och Nevis',
      KP: 'Nordkorea',
      KR: 'Sydkorea',
      KW: 'Kuwait',
      KY: 'Caymanöarna',
      KZ: 'Kazakstan',
      LA: 'Laos',
      LB: 'Libanon',
      LC: 'Saint Lucia',
      LI: 'Liechtenstein',
      LK: 'Sri Lanka',
      LR: 'Liberia',
      LS: 'Lesotho',
      LT: 'Litauen',
      LU: 'Luxemburg',
      LV: 'Lettland',
      MA: 'Marocko',
      LY: 'Libyen',
      MC: 'Monaco',
      MD: 'Moldavien',
      ME: 'Montenegro',
      MG: 'Madagaskar',
      MF: 'Saint Martin',
      MH: 'Marshallöarna',
      MK: 'Republiken Nordmakedonien',
      ML: 'Mali',
      MM: 'Myanmar',
      MN: 'Mongoliet',
      MO: 'Macau',
      MP: 'Nordmarianerna',
      MQ: 'Martinique',
      MR: 'Mauretanien',
      MS: 'Montserrat',
      MT: 'Malta',
      MU: 'Mauritius',
      MV: 'Maldiverna',
      MW: 'Malawi',
      MX: 'Mexiko',
      MY: 'Malaysia',
      MZ: 'Moçambique',
      NA: 'Namibia',
      NC: 'Nya Kaledonien',
      NE: 'Niger',
      NF: 'Norfolkön',
      NG: 'Nigeria',
      NI: 'Nicaragua',
      NL: 'Nederländerna',
      NO: 'Norge',
      NP: 'Nepal',
      NR: 'Nauru',
      NU: 'Niue',
      NZ: 'Nya Zeeland',
      OM: 'Oman',
      PA: 'Panama',
      PE: 'Peru',
      PF: 'Franska Polynesien',
      PG: 'Papua Nya Guinea',
      PH: 'Filippinerna',
      PK: 'Pakistan',
      PL: 'Polen',
      PM: 'Saint-Pierre och Miquelon',
      PN: 'Pitcairnöarna',
      PR: 'Puerto Rico',
      PS: 'Palestina',
      PT: 'Portugal',
      PW: 'Palau',
      PY: 'Paraguay',
      QA: 'Qatar',
      RE: 'Réunion',
      RO: 'Rumänien',
      RS: 'Serbien',
      RU: 'Ryssland',
      RW: 'Rwanda',
      SA: 'Saudiarabien',
      SB: 'Salomonöarna',
      SC: 'Seychellerna',
      SD: 'Sudan',
      SE: 'Sverige',
      SG: 'Singapore',
      SH: 'Sankta Helena',
      SI: 'Slovenien',
      SJ: 'Svalbard och Jan Mayen',
      SK: 'Slovakien',
      SL: 'Sierra Leone',
      SM: 'San Marino',
      SN: 'Senegal',
      SO: 'Somalia',
      SR: 'Surinam',
      SS: 'Sydsudan',
      ST: 'São Tomé och Príncipe',
      SV: 'El Salvador',
      SX: 'Sint Maarten',
      SY: 'Syrien',
      SZ: 'Swaziland',
      TC: 'Turks- och Caicosöarna',
      TD: 'Tchad',
      TF: 'Franska syd- och Antarktisterritorierna',
      TG: 'Togo',
      TH: 'Thailand',
      TJ: 'Tadzjikistan',
      TK: 'Tokelau',
      TL: 'Östtimor',
      TM: 'Turkmenistan',
      TN: 'Tunisien',
      TO: 'Tonga',
      TR: 'Turkiet',
      TT: 'Trinidad och Tobago',
      TV: 'Tuvalu',
      TW: 'Taiwan',
      TZ: 'Tanzania',
      UA: 'Ukraina',
      UG: 'Uganda',
      UM: 'USA:s yttre öar',
      US: 'USA',
      UY: 'Uruguay',
      UZ: 'Uzbekistan',
      VA: 'Heliga stolen',
      VC: 'Saint Vincent och Grenadinerna',
      VE: 'Venezuela',
      VG: 'Brittiska Jungfruöarna',
      VI: 'Amerikanska Jungfruöarna',
      VN: 'Vietnam',
      VU: 'Vanuatu',
      WF: 'Wallis- och Futunaöarna',
      WS: 'Samoa',
      XK: 'Republiken Kosovo',
      YE: 'Jemen',
      YT: 'Mayotte',
      ZA: 'Sydafrika',
      ZM: 'Zambia',
      ZW: 'Zimbabwe'
    }
  },
  'nb-NO': {
    country: {
      AD: 'Andorra',
      AE: 'De forente arabiske emirater',
      AF: 'Afghanistan',
      AG: 'Antigua og Barbuda',
      AI: 'Anguilla',
      AL: 'Albania',
      AM: 'Armenia',
      AO: 'Angola',
      AQ: 'Antarktis',
      AR: 'Argentina',
      AS: 'Amerikansk Samoa',
      AT: 'Østerrike',
      AU: 'Australia',
      AW: 'Aruba',
      AX: 'Åland',
      AZ: 'Aserbajdsjan',
      BA: 'Bosnia-Hercegovina',
      BB: 'Barbados',
      BD: 'Bangladesh',
      BE: 'Belgia',
      BF: 'Burkina Faso',
      BG: 'Bulgaria',
      BH: 'Bahrain',
      BI: 'Burundi',
      BJ: 'Benin',
      BL: 'Saint-Barthélemy',
      BM: 'Bermuda',
      BN: 'Brunei',
      BO: 'Bolivia',
      BQ: 'Bonaire',
      BR: 'Brasil',
      BS: 'Bahamas',
      BT: 'Bhutan',
      BV: 'Bouvetøya',
      BW: 'Botswana',
      BY: 'Belarus',
      BZ: 'Belize',
      CA: 'Canada',
      CC: 'Kokosøyene',
      CD: 'Kongo',
      CF: 'Den sentralafrikanske republikk',
      CG: 'Kongo',
      CH: 'Sveits',
      CI: 'Elfenbenskysten',
      CK: 'Cookøyene',
      CL: 'Chile',
      CM: 'Kamerun',
      CN: 'Kina',
      CO: 'Colombia',
      CR: 'Costa Rica',
      CU: 'Cuba',
      CV: 'Kapp Verde',
      CW: 'Curaçao',
      CX: 'Christmasøya',
      CY: 'Kypros',
      CZ: 'Tsjekkia',
      DE: 'Tyskland',
      DJ: 'Djibouti',
      DK: 'Danmark',
      DM: 'Dominica',
      DO: 'Den dominikanske republikk',
      DZ: 'Algerie',
      EE: 'Estland',
      EC: 'Ecuador',
      EG: 'Egypt',
      EH: 'Vest-Sahara',
      EN: 'Global',
      ER: 'Eritrea',
      ES: 'Spania',
      ET: 'Etiopia',
      FI: 'Finland',
      FJ: 'Fiji',
      FK: 'Falklandsøyene',
      FM: 'Mikronesiaføderasjonen',
      FO: 'Færøyene',
      FR: 'Frankrike',
      GA: 'Gabon',
      GB: 'Storbritannia',
      GD: 'Grenada',
      GE: 'Georgia',
      GF: 'Fransk Guyana',
      GG: 'Guernsey',
      GI: 'Gibraltar',
      GH: 'Ghana',
      GL: 'Grønland',
      GM: 'Gambia',
      GN: 'Guinea',
      GP: 'Guadeloupe',
      GQ: 'Ekvatorial-Guinea',
      GR: 'Hellas',
      GS: 'Sør-Georgia',
      GT: 'Guatemala',
      GU: 'Guam',
      GW: 'Guinea-Bissau',
      GY: 'Guyana',
      HK: 'Hong Kong',
      HM: 'Heardøya og McDonaldøyene',
      HN: 'Honduras',
      HR: 'Kroatia',
      HT: 'Haiti',
      HU: 'Ungarn',
      ID: 'Indonesia',
      IE: 'Irland',
      IL: 'Israel',
      IM: 'Man',
      IN: 'India',
      IO: 'Det britiske territoriet i Indiahavet',
      IQ: 'Irak',
      IR: 'Iran',
      IS: 'Island',
      IT: 'Italia',
      JE: 'Jersey',
      JM: 'Jamaica',
      JO: 'Jordan',
      JP: 'Japan',
      KE: 'Kenya',
      KG: 'Kirgisistan',
      KH: 'Kambodsja',
      KI: 'Kiribati',
      KM: 'Komorene',
      KN: 'Saint Kitts og Nevis',
      KP: 'Nord-Korea',
      KR: 'Sør-Korea',
      KW: 'Kuwait',
      KY: 'Caymanøyene',
      KZ: 'Kasakhstan',
      LA: 'Laos',
      LB: 'Libanon',
      LC: 'Saint Lucia',
      LI: 'Liechtenstein',
      LK: 'Sri Lanka',
      LR: 'Liberia',
      LS: 'Lesotho',
      LT: 'Litauen',
      LU: 'Luxembourg',
      LV: 'Latvia',
      MA: 'Marokko',
      LY: 'Libya',
      MC: 'Monaco',
      MD: 'Moldova',
      ME: 'Montenegro',
      MG: 'Madagaskar',
      MF: 'Saint Martin',
      MH: 'Marshalløyene',
      MK: 'Nord-Makedonia',
      ML: 'Mali',
      MM: 'Myanmar',
      MN: 'Mongolia',
      MO: 'Macao',
      MP: 'Nord-Marianene',
      MQ: 'Martinique',
      MR: 'Mauritania',
      MS: 'Montserrat',
      MT: 'Malta',
      MU: 'Mauritius',
      MV: 'Maldivene',
      MW: 'Malawi',
      MX: 'Mexico',
      MY: 'Malaysia',
      MZ: 'Mosambik',
      NA: 'Namibia',
      NC: 'Ny-Caledonia',
      NE: 'Niger',
      NF: 'Norfolkøya',
      NG: 'Nigeria',
      NI: 'Nicaragua',
      NL: 'Nederland',
      NO: 'Norge',
      NP: 'Nepal',
      NR: 'Nauru',
      NU: 'Niue',
      NZ: 'New Zealand',
      OM: 'Oman',
      PA: 'Panama',
      PE: 'Peru',
      PF: 'Fransk Polynesia',
      PG: 'Papua Ny-Guinea',
      PH: 'Filippinene',
      PK: 'Pakistan',
      PL: 'Polen',
      PM: 'Saint-Pierre og Miquelon',
      PN: 'Pitcairnøyene',
      PR: 'Puerto Rico',
      PS: 'Palestina',
      PT: 'Portugal',
      PW: 'Palau',
      PY: 'Paraguay',
      QA: 'Qatar',
      RE: 'Réunion',
      RO: 'Romania',
      RS: 'Serbia',
      RU: 'Russland',
      RW: 'Rwanda',
      SA: 'Saudi-Arabia',
      SB: 'Salomonøyene',
      SC: 'Seychellene',
      SD: 'Sudan',
      SE: 'Sverige',
      SG: 'Singapore',
      SH: 'Sankt Helena',
      SI: 'Slovenia',
      SJ: 'Svalbard og Jan Mayen',
      SK: 'Slovakia',
      SL: 'Sierra Leone',
      SM: 'San Marino',
      SN: 'Senegal',
      SO: 'Somalia',
      SR: 'Surinam',
      SS: 'Sør-Sudan',
      ST: 'São Tomé og Príncipe',
      SV: 'El Salvador',
      SX: 'Sint Maarten',
      SY: 'Syria',
      SZ: 'Eswatini',
      TC: 'Turks- og Caicosøyene',
      TD: 'Tsjad',
      TF: 'De franske sørterritorier',
      TG: 'Togo',
      TH: 'Thailand',
      TJ: 'Tadsjikistan',
      TK: 'Tokelau',
      TL: 'Øst-Timor',
      TM: 'Turkmenistan',
      TN: 'Tunisia',
      TO: 'Tonga',
      TR: 'Tyrkia',
      TT: 'Trinidad og Tobago',
      TV: 'Tuvalu',
      TW: 'Taiwan',
      TZ: 'Tanzania',
      UA: 'Ukraina',
      UG: 'Uganda',
      UM: 'USAs ytre småøyer',
      US: 'USA',
      UY: 'Uruguay',
      UZ: 'Usbekistan',
      VA: 'Den hellige stol',
      VC: 'Saint Vincent og Grenadinene',
      VE: 'Venezuela',
      VG: 'De britiske Jomfruøyer',
      VI: 'De amerikanske Jomfruøyer',
      VN: 'Vietnam',
      VU: 'Vanuatu',
      WF: 'Wallis og Futuna',
      WS: 'Samoa',
      XK: 'Kosovo',
      YE: 'Jemen',
      YT: 'Mayotte',
      ZA: 'Sør-Afrika',
      ZM: 'Zambia',
      ZW: 'Zimbabwe'
    }
  },
  'en': {
    country: {
      AD: 'Andorra',
      AE: 'United Arab Emirates',
      AF: 'Afghanistan',
      AG: 'Antigua and Barbuda',
      AI: 'Anguilla',
      AL: 'Albania',
      AM: 'Armenia',
      AO: 'Angola',
      AQ: 'Antarctica',
      AR: 'Argentina',
      AS: 'American Samoa',
      AT: 'Austria',
      AU: 'Australia',
      AW: 'Aruba',
      AX: 'Åland Islands',
      AZ: 'Azerbaijan',
      BA: 'Bosnia and Herzegovina',
      BB: 'Barbados',
      BD: 'Bangladesh',
      BE: 'Belgium',
      BF: 'Burkina Faso',
      BG: 'Bulgaria',
      BH: 'Bahrain',
      BI: 'Burundi',
      BJ: 'Benin',
      BL: 'Saint Barthélemy',
      BM: 'Bermuda',
      BN: 'Brunei',
      BO: 'Bolivia',
      BQ: 'Bonaire',
      BR: 'Brazil',
      BS: 'The Bahamas',
      BT: 'Bhutan',
      BV: 'Bouvet Island',
      BW: 'Botswana',
      BY: 'Belarus',
      BZ: 'Belize',
      CA: 'Canada',
      CC: 'Cocos (Keeling) Islands',
      CD: 'Democratic Republic of the Congo',
      CF: 'Central African Republic',
      CG: 'Republic of the Congo',
      CH: 'Switzerland',
      CI: 'Ivory Coast',
      CK: 'Cook Islands',
      CL: 'Chile',
      CM: 'Cameroon',
      CN: 'China',
      CO: 'Colombia',
      CR: 'Costa Rica',
      CU: 'Cuba',
      CV: 'Cape Verde',
      CW: 'Curaçao',
      CX: 'Christmas Island',
      CY: 'Cyprus',
      CZ: 'Czech Republic',
      DE: 'Germany',
      DJ: 'Djibouti',
      DK: 'Denmark',
      DM: 'Dominica',
      DO: 'Dominican Republic',
      DZ: 'Algeria',
      EE: 'Estonia',
      EC: 'Ecuador',
      EG: 'Egypt',
      EH: 'Western Sahara',
      EN: 'Global',
      ER: 'Eritrea',
      ES: 'Spain',
      ET: 'Ethiopia',
      FI: 'Finland',
      FJ: 'Fiji',
      FK: 'Falkland Islands',
      FM: 'Federated States of Micronesia',
      FO: 'Faroe Islands',
      FR: 'France',
      GA: 'Gabon',
      GB: 'United Kingdom',
      GD: 'Grenada',
      GE: 'Georgia',
      GF: 'French Guiana',
      GG: 'Guernsey',
      GI: 'Gibraltar',
      GH: 'Ghana',
      GL: 'Greenland',
      GM: 'The Gambia',
      GN: 'Guinea',
      GP: 'Guadeloupe',
      GQ: 'Equatorial Guinea',
      GR: 'Greece',
      GS: 'South Georgia',
      GT: 'Guatemala',
      GU: 'Guam',
      GW: 'Guinea-Bissau',
      GY: 'Guyana',
      HK: 'Hong Kong',
      HM: 'Heard Island and McDonald Islands',
      HN: 'Honduras',
      HR: 'Croatia',
      HT: 'Haiti',
      HU: 'Hungary',
      ID: 'Indonesia',
      IE: 'Republic of Ireland',
      IL: 'Israel',
      IM: 'Isle of Man',
      IN: 'India',
      IO: 'British Indian Ocean Territory',
      IQ: 'Iraq',
      IR: 'Iran',
      IS: 'Iceland',
      IT: 'Italy',
      JE: 'Jersey',
      JM: 'Jamaica',
      JO: 'Jordan',
      JP: 'Japan',
      KE: 'Kenya',
      KG: 'Kyrgyzstan',
      KH: 'Cambodia',
      KI: 'Kiribati',
      KM: 'Comoros',
      KN: 'Saint Kitts and Nevis',
      KP: 'North Korea',
      KR: 'South Korea',
      KW: 'Kuwait',
      KY: 'Cayman Islands',
      KZ: 'Kazakhstan',
      LA: 'Laos',
      LB: 'Lebanon',
      LC: 'Saint Lucia',
      LI: 'Liechtenstein',
      LK: 'Sri Lanka',
      LR: 'Liberia',
      LS: 'Lesotho',
      LT: 'Lithuania',
      LU: 'Luxembourg',
      LV: 'Latvia',
      MA: 'Morocco',
      LY: 'Libya',
      MC: 'Monaco',
      MD: 'Moldova',
      ME: 'Montenegro',
      MG: 'Madagascar',
      MF: 'Saint Martin',
      MH: 'Marshall Islands',
      MK: 'Republic of Macedonia',
      ML: 'Mali',
      MM: 'Myanmar',
      MN: 'Mongolia',
      MO: 'Macau',
      MP: 'Northern Mariana Islands',
      MQ: 'Martinique',
      MR: 'Mauritania',
      MS: 'Montserrat',
      MT: 'Malta',
      MU: 'Mauritius',
      MV: 'Maldives',
      MW: 'Malawi',
      MX: 'Mexico',
      MY: 'Malaysia',
      MZ: 'Mozambique',
      NA: 'Namibia',
      NC: 'New Caledonia',
      NE: 'Niger',
      NF: 'Norfolk Island',
      NG: 'Nigeria',
      NI: 'Nicaragua',
      NL: 'Netherlands',
      NO: 'Norway',
      NP: 'Nepal',
      NR: 'Nauru',
      NU: 'Niue',
      NZ: 'New Zealand',
      OM: 'Oman',
      PA: 'Panama',
      PE: 'Peru',
      PF: 'French Polynesia',
      PG: 'Papua New Guinea',
      PH: 'Philippines',
      PK: 'Pakistan',
      PL: 'Poland',
      PM: 'Saint Pierre and Miquelon',
      PN: 'Pitcairn Islands',
      PR: 'Puerto Rico',
      PS: 'Palestine',
      PT: 'Portugal',
      PW: 'Palau',
      PY: 'Paraguay',
      QA: 'Qatar',
      RE: 'Réunion',
      RO: 'Romania',
      RS: 'Serbia',
      RU: 'Russia',
      RW: 'Rwanda',
      SA: 'Saudi Arabia',
      SB: 'Solomon Islands',
      SC: 'Seychelles',
      SD: 'Sudan',
      SE: 'Sweden',
      SG: 'Singapore',
      SH: 'Saint Helena',
      SI: 'Slovenia',
      SJ: 'Svalbard and Jan Mayen',
      SK: 'Slovakia',
      SL: 'Sierra Leone',
      SM: 'San Marino',
      SN: 'Senegal',
      SO: 'Somalia',
      SR: 'Suriname',
      SS: 'South Sudan',
      ST: 'São Tomé and Príncipe',
      SV: 'El Salvador',
      SX: 'Sint Maarten',
      SY: 'Syria',
      SZ: 'Swaziland',
      TC: 'Turks and Caicos Islands',
      TD: 'Chad',
      TF: 'French Southern and Antarctic Lands',
      TG: 'Togo',
      TH: 'Thailand',
      TJ: 'Tajikistan',
      TK: 'Tokelau',
      TL: 'East Timor',
      TM: 'Turkmenistan',
      TN: 'Tunisia',
      TO: 'Tonga',
      TR: 'Turkey',
      TT: 'Trinidad and Tobago',
      TV: 'Tuvalu',
      TW: 'Taiwan',
      TZ: 'Tanzania',
      UA: 'Ukraine',
      UG: 'Uganda',
      UM: 'United States Minor Outlying Islands',
      US: 'United States',
      UY: 'Uruguay',
      UZ: 'Uzbekistan',
      VA: 'Holy See',
      VC: 'Saint Vincent and the Grenadines',
      VE: 'Venezuela',
      VG: 'Virgin Islands (British)',
      VI: 'Virgin Islands (U.S.)',
      VN: 'Vietnam',
      VU: 'Vanuatu',
      WF: 'Wallis and Futuna',
      WS: 'Samoa',
      XK: 'Republic of Kosovo',
      YE: 'Yemen',
      YT: 'Mayotte',
      ZA: 'South Africa',
      ZM: 'Zambia',
      ZW: 'Zimbabwe'
    }
  }
}

export type TCountry = {
  country: {
    AD: string;
    AE: string;
    AF: string;
    AG: string;
    AI: string;
    AL: string;
    AM: string;
    AO: string;
    AQ: string;
    AR: string;
    AS: string;
    AT: string;
    AU: string;
    AW: string;
    AX: string;
    AZ: string;
    BA: string;
    BB: string;
    BD: string;
    BE: string;
    BF: string;
    BG: string;
    BH: string;
    BI: string;
    BJ: string;
    BL: string;
    BM: string;
    BN: string;
    BO: string;
    BQ: string;
    BR: string;
    BS: string;
    BT: string;
    BV: string;
    BW: string;
    BY: string;
    BZ: string;
    CA: string;
    CC: string;
    CD: string;
    CF: string;
    CG: string;
    CH: string;
    CI: string;
    CK: string;
    CL: string;
    CM: string;
    CN: string;
    CO: string;
    CR: string;
    CU: string;
    CV: string;
    CW: string;
    CX: string;
    CY: string;
    CZ: string;
    DE: string;
    DJ: string;
    DK: string;
    DM: string;
    DO: string;
    DZ: string;
    EE: string;
    EC: string;
    EG: string;
    EH: string;
    EN: string;
    ER: string;
    ES: string;
    ET: string;
    FI: string;
    FJ: string;
    FK: string;
    FM: string;
    FO: string;
    FR: string;
    GA: string;
    GB: string;
    GD: string;
    GE: string;
    GF: string;
    GG: string;
    GI: string;
    GH: string;
    GL: string;
    GM: string;
    GN: string;
    GP: string;
    GQ: string;
    GR: string;
    GS: string;
    GT: string;
    GU: string;
    GW: string;
    GY: string;
    HK: string;
    HM: string;
    HN: string;
    HR: string;
    HT: string;
    HU: string;
    ID: string;
    IE: string;
    IL: string;
    IM: string;
    IN: string;
    IO: string;
    IQ: string;
    IR: string;
    IS: string;
    IT: string;
    JE: string;
    JM: string;
    JO: string;
    JP: string;
    KE: string;
    KG: string;
    KH: string;
    KI: string;
    KM: string;
    KN: string;
    KP: string;
    KR: string;
    KW: string;
    KY: string;
    KZ: string;
    LA: string;
    LB: string;
    LC: string;
    LI: string;
    LK: string;
    LR: string;
    LS: string;
    LT: string;
    LU: string;
    LV: string;
    MA: string;
    LY: string;
    MC: string;
    MD: string;
    ME: string;
    MG: string;
    MF: string;
    MH: string;
    MK: string;
    ML: string;
    MM: string;
    MN: string;
    MO: string;
    MP: string;
    MQ: string;
    MR: string;
    MS: string;
    MT: string;
    MU: string;
    MV: string;
    MW: string;
    MX: string;
    MY: string;
    MZ: string;
    NA: string;
    NC: string;
    NE: string;
    NF: string;
    NG: string;
    NI: string;
    NL: string;
    NO: string;
    NP: string;
    NR: string;
    NU: string;
    NZ: string;
    OM: string;
    PA: string;
    PE: string;
    PF: string;
    PG: string;
    PH: string;
    PK: string;
    PL: string;
    PM: string;
    PN: string;
    PR: string;
    PS: string;
    PT: string;
    PW: string;
    PY: string;
    QA: string;
    RE: string;
    RO: string;
    RS: string;
    RU: string;
    RW: string;
    SA: string;
    SB: string;
    SC: string;
    SD: string;
    SE: string;
    SG: string;
    SH: string;
    SI: string;
    SJ: string;
    SK: string;
    SL: string;
    SM: string;
    SN: string;
    SO: string;
    SR: string;
    SS: string;
    ST: string;
    SV: string;
    SX: string;
    SY: string;
    SZ: string;
    TC: string;
    TD: string;
    TF: string;
    TG: string;
    TH: string;
    TJ: string;
    TK: string;
    TL: string;
    TM: string;
    TN: string;
    TO: string;
    TR: string;
    TT: string;
    TV: string;
    TW: string;
    TZ: string;
    UA: string;
    UG: string;
    UM: string;
    US: string;
    UY: string;
    UZ: string;
    VA: string;
    VC: string;
    VE: string;
    VG: string;
    VI: string;
    VN: string;
    VU: string;
    WF: string;
    WS: string;
    XK: string;
    YE: string;
    YT: string;
    ZA: string;
    ZM: string;
    ZW: string;
  };
}
