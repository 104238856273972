import { isFeatureEnabled } from '@utils';

const SP_ACTIVE_CHAT_KEY = 'SP_ACTIVE_CHAT_KEY';
const SP_ROOT_CONTAINER = 'sp-root-container';
const SP_CHAT_SCRIPT_ID = 'sp-chat-script';

let spIsLoaded = false;

const onMessage = (e: MessageEvent<any>) => {
  if (e.data === 'sp-session-start') {
    localStorage.setItem(SP_ACTIVE_CHAT_KEY, 'true');
  } else if (
    e.data === 'sp-session-end' ||
    e.data === 'bp-stop-cobrowsing-and-close-chat'
  ) {
    localStorage.removeItem(SP_ACTIVE_CHAT_KEY);
  }
};

const addScript = () => {
  const script = document.createElement('script');
  script.id = SP_CHAT_SCRIPT_ID;
  script.src =
    'https://hurtigruten.brightpattern.com/clientweb/chat-client-v4/js/init.js';
  document.body.appendChild(script);

  window.removeEventListener('message', onMessage);
  window.addEventListener('message', onMessage);
};

/**
 * Used to check if user has an active chat session
 */
export const hasActiveChat = () =>
  localStorage.getItem(SP_ACTIVE_CHAT_KEY) === 'true';

/**
 * Will load the Bright Pattern chat script if it is not already added
 * and if brightPattern chat flag in enabled.
 * If already loaded it will show the chat widget.
 */
export const indicateShowChat = () => {
  if (
    !spIsLoaded &&
    !document.getElementById(SP_CHAT_SCRIPT_ID) &&
    isFeatureEnabled('brightPatternChat')
  ) {
    try {
      addScript();
    } catch (error) {
      console.error('Unable to load Bright Pattern script');
    } finally {
      spIsLoaded = true;
    }
  } else {
    try {
      const container = document.getElementById(SP_ROOT_CONTAINER);
      if (container) {
        container.style.display = 'block';
      }
    } catch (error) {
      console.error('Unable to show Bright Pattern widget');
    }
  }
};

/**
 * Will hide the Bright Pattern widget unless user has active chat.
 */
export const indicateHideChat = () => {
  if (!hasActiveChat()) {
    try {
      const container = document.getElementById(SP_ROOT_CONTAINER);
      if (container) {
        container.style.display = 'none';
      }
    } catch (error) {
      console.error('Unable to hide Bright Pattern widget');
    }
  }
};
