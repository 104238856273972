import { useRouter } from 'next/router';

import { isLocale } from '@src/utils/typeGuards';

/* 
  Important: defaultLocale is important in one respect, our sitemap 
  (sitemap-expeditions.xml) uses defaultLocale to tell search engines 
  our default site. Change this with caution.
*/
export const defaultLocale: TLocale = 'en-us';

const useLocale = (): TLocale => {
  const { locale } = useRouter() ?? { locale: defaultLocale };

  if (isLocale(locale)) {
    return locale;
  }
  return defaultLocale;
};

export default useLocale;
