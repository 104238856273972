export const hxLocale = 'hx-locale';

export const addLocaleToLocalStorage = (window: Window, locale: TLocale) => {
  const storedLocale = window.localStorage.getItem(hxLocale) as TLocale;
  if (storedLocale !== locale) {
    window.localStorage.setItem(hxLocale, locale);
  }
};

export const getLocaleFromLocalStorage = (window: Window): string | null =>
  window.localStorage?.getItem(hxLocale) ?? null;
