import { useContext, Dispatch } from 'react';

import { GlobalStore } from '@context/StateProvider';

export function useAppContext(): {
  state: IApplicationState;
  dispatch: Dispatch<IStateAction>;
} {
  const { state, dispatch } = useContext(GlobalStore);

  return {
    state,
    dispatch
  };
}
