import { getValidLocales, getSitemapLocales } from '@constants/locales';

export function isLocale(value: string | undefined | null): value is TLocale {
  return getValidLocales().includes(value as TLocale);
}

export function isSitemapLocale(
  value: string | undefined | null
): value is TLocale {
  return getSitemapLocales().includes(value as TLocale);
}
