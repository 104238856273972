import { gql } from '@apollo/client';

import { HeaderRibbon } from '@content/gql/graphql';

export type HeaderRibbonData = HeaderRibbon;

export const getHeaderRibbon = gql`
  query GetHeaderRibbon($locale: String!, $id: String!) {
    headerRibbon(locale: $locale, id: $id) {
      text
      link {
        url
        label
      }
      logo {
        url
        alt
      }
    }
  }
`;
