import { getContentApiClient } from '@content/apolloClient';

import {
  getHeaderRibbon,
  HeaderRibbonData
} from '../../queries/contentApi/getHeaderRibbon';
import { RequestConfig } from '../../types';

export const getHeaderRibbonQuery = async (
  id: string,
  { locale }: RequestConfig
): Promise<HeaderRibbonData | null> => {
  const response = await getContentApiClient().query<
    Record<'headerRibbon', HeaderRibbonData>
  >({
    query: getHeaderRibbon,
    variables: {
      locale,
      id
    }
  });

  const pageData = response.data.headerRibbon;

  if (!pageData) {
    return null;
  }

  return pageData;
};
