export const mapLocaleToCruisesUri = (locale: TLocale): string => {
  switch (locale) {
    case 'de-de':
    case 'de-ch':
      return 'reisen';
    case 'fr-fr':
      return 'croisieres';
    case 'sv-se':
      return 'kryssningar';
    case 'da-dk':
      return 'krydstogter';
    case 'nb-no':
      return 'cruise';
    default:
      return 'cruises';
  }
};

export const mapLocaleToDestinationsUri = (locale: TLocale): string => {
  switch (locale) {
    case 'de-ch':
    case 'de-de':
      return 'reiseziele';
    case 'nb-no':
      return 'destinasjoner';
    case 'sv-se':
    case 'da-dk':
      return 'destinationer';
    default:
      return 'destinations';
  }
};

export const mapLocaleToOffersUri = (locale: TLocale): string => {
  switch (locale) {
    case 'de-de':
    case 'de-ch':
      return 'angebote';
    case 'fr-fr':
      return 'offres';
    case 'nb-no':
    case 'da-dk':
      return 'tilbud';
    case 'sv-se':
      return 'erbjudanden';
    default:
      return 'offers';
  }
};

export const mapLocaleToStoriesUri = (locale: TLocale): string => {
  switch (locale) {
    case 'de-de':
    case 'de-ch':
    case 'fr-fr':
    case 'sv-se':
    case 'da-dk':
      return 'inspiration';
    case 'nb-no':
      return 'inspirasjon';
    default:
      return 'stories';
  }
};

export const mapLocaleToCampaignsUri = (locale: TLocale): string => {
  switch (locale) {
    case 'de-de':
    case 'de-ch':
      return 'focusthema';
    case 'fr-fr':
      return 'campagnes-promotionelles';
    case 'nb-no':
    case 'sv-se':
      return 'kampanjer';
    case 'da-dk':
      return 'kampagner';
    default:
      return 'campaigns';
  }
};

export const mapLocaleToEnhanceYourCruiseUri = (locale: TLocale): string => {
  switch (locale) {
    case 'de-de':
    case 'de-ch':
      return 'zusaetzliche-angebote';
    case 'fr-fr':
      return 'completez-votre-croisiere';
    case 'nb-no':
      return 'oppgrader-cruise';
    case 'sv-se':
      return 'uppgradera-kryssning';
    case 'da-dk':
      return 'opgrader-krydstogt';
    default:
      return 'enhance-your-cruise';
  }
};

export const mapLocaleToCatalogueUri = (locale: TLocale): string => {
  switch (locale) {
    case 'de-de':
    case 'de-ch':
      return 'uebersicht';
    case 'fr-fr':
      return 'catalogue';
    case 'nb-no':
    case 'sv-se':
    case 'da-dk':
      return 'katalog';
    default:
      return 'catalog';
  }
};

export const mapLocaleToCategoriesUri = (locale: TLocale): string => {
  switch (locale) {
    case 'de-de':
    case 'de-ch':
      return 'kategorien';
    case 'nb-no':
    case 'sv-se':
    case 'da-dk':
      return 'kategorier';
    default:
      return 'categories';
  }
};

// Kept for consistency.
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const mapLocaleToHxExplorersUri = (locale: TLocale): string =>
  'hx-explorers';

export const mapLocaleToLoyaltyUri = (locale: TLocale): string => {
  switch (locale) {
    case 'de-de':
    case 'de-ch':
      return 'treueprogramm';
    case 'fr-fr':
      return 'loyaute';
    case 'nb-no':
    case 'sv-se':
      return 'lojalitet';
    case 'da-dk':
      return 'loyalitet';
    default:
      return 'loyalty';
  }
};

export const mapLocaleToMakeAPaymentUri = (locale: TLocale): string => {
  switch (locale) {
    case 'de-de':
    case 'de-ch':
      return 'zahlungsdaten';
    case 'fr-fr':
      return 'effectuer-un-paiement';
    case 'nb-no':
      return 'betal';
    case 'sv-se':
      return 'gor-betalning';
    case 'da-dk':
      return 'foretag-betaling';
    default:
      return 'make-a-payment';
  }
};

export const mapLocaleToPaymentUri = (locale: TLocale): string => {
  switch (locale) {
    case 'de-de':
    case 'de-ch':
      return 'zahlung';
    case 'fr-fr':
      return 'paiement';
    case 'nb-no':
    case 'da-dk':
      return 'betaling';
    case 'sv-se':
      return 'betalning';
    default:
      return 'payment';
  }
};

export const mapLocaleToMapUri = (locale: TLocale): string => {
  switch (locale) {
    case 'de-de':
    case 'de-ch':
      return 'routenkarte';
    case 'fr-fr':
      return 'carte';
    case 'nb-no':
      return 'kart';
    case 'sv-se':
      return 'karta';
    case 'da-dk':
      return 'kort';
    default:
      return 'map';
  }
};

export const mapLocaleToThankYouUri = (locale: TLocale): string => {
  switch (locale) {
    case 'de-de':
    case 'de-ch':
      return 'danke';
    case 'fr-fr':
      return 'merci';
    case 'nb-no':
    case 'sv-se':
      return 'takk';
    case 'da-dk':
      return 'tak';
    default:
      return 'thank-you';
  }
};

export const mapLocaleToOrderBrochuresUri = (locale: TLocale): string => {
  switch (locale) {
    case 'de-de':
    case 'de-ch':
      return 'kataloge-bestellen';
    case 'fr-fr':
      return 'commande-brochures';
    case 'nb-no':
      return 'bestill-brosjyrer';
    case 'sv-se':
      return 'bestall-broschyr';
    case 'da-dk':
      return 'bestilling-brochurer';
    default:
      return 'order-brochures';
  }
};

export const mapLocaleToConfirmationUri = (locale: TLocale): string => {
  switch (locale) {
    case 'de-de':
    case 'de-ch':
      return 'bestaetigung';
    case 'nb-no':
      return 'bekreftelse';
    case 'sv-se':
      return 'bekraftelse';
    case 'da-dk':
      return 'bekraeftelse';
    default:
      return 'confirmation';
  }
};

export const mapLocaleToMyAccountUri = (locale: TLocale): string => {
  switch (locale) {
    case 'de-de':
    case 'de-ch':
      return 'mein-konto';
    case 'fr-fr':
      return 'mon-espace';
    case 'sv-se':
      return 'min-sida';
    case 'nb-no':
    case 'da-dk':
      return 'min-side';
    default:
      return 'my-account';
  }
};

export const mapLocaleToBookingUri = (locale: TLocale): string => {
  switch (locale) {
    case 'fr-fr':
      return 'reservation';
    case 'de-de':
    case 'de-ch':
      return 'buchung';
    case 'nb-no':
      return 'bestilling';
    case 'sv-se':
      return 'bokning';
    default:
      return 'booking';
  }
};

export const mapLocaleToBookingsUri = (locale: TLocale): string => {
  switch (locale) {
    case 'de-de':
    case 'de-ch':
      return 'buchungen';
    case 'fr-fr':
      return 'reservations';
    case 'nb-no':
      return 'bestillinger';
    case 'sv-se':
      return 'bokningar';
    case 'da-dk':
      return 'bookinger';
    default:
      return 'bookings';
  }
};

export const mapLocaleToCabinUri = (locale: TLocale): string => {
  switch (locale) {
    case 'de-de':
    case 'de-ch':
      return 'kabine';
    case 'fr-fr':
      return 'cabine';
    case 'nb-no':
      return 'lugar';
    case 'sv-se':
      return 'hytt';
    case 'da-dk':
      return 'kahyt';
    default:
      return 'cabin';
  }
};

export const mapLocaleToPersonaliaUri = (locale: TLocale): string => {
  switch (locale) {
    case 'de-de':
    case 'de-ch':
      return 'persoenliche-daten';
    case 'fr-fr':
      return 'personnel';
    case 'sv-se':
      return 'personlig-information';
    default:
      return 'personalia';
  }
};

export const mapLocaleToCheckoutUri = (locale: TLocale): string => {
  switch (locale) {
    case 'de-de':
    case 'de-ch':
      return 'kasse';
    case 'fr-fr':
      return 'verifier';
    case 'nb-no':
      return 'sjekk-ut';
    case 'sv-se':
      return 'utcheckning';
    default:
      return 'checkout';
  }
};

export const mapLocaleToExpiredUri = (locale: TLocale): string => {
  switch (locale) {
    case 'de-de':
    case 'de-ch':
      return 'abgelaufen';
    case 'fr-fr':
      return 'expire';
    case 'nb-no':
      return 'utlopt';
    case 'sv-se':
      return 'utlopt';
    case 'da-dk':
      return 'udlobet';
    default:
      return 'expired';
  }
};

export const mapLocaleToBookingErrorUri = (locale: TLocale): string => {
  switch (locale) {
    case 'de-de':
    case 'de-ch':
    case 'fr-fr':
    case 'nb-no':
    case 'sv-se':
    case 'da-dk':
    default:
      return 'error';
  }
};

export const mapLocaleToSummaryUri = (locale: TLocale): string => {
  switch (locale) {
    case 'de-de':
    case 'de-ch':
      return 'meine-reise';
    case 'fr-fr':
      return 'sommaire';
    case 'nb-no':
      return 'oppsummering';
    case 'sv-se':
      return 'sammanfattning';
    case 'da-dk':
      return 'oversigt';
    default:
      return 'summary';
  }
};

export const mapLocaleToRebookCruiseUri = (locale: TLocale): string => {
  switch (locale) {
    case 'de-de':
    case 'de-ch':
      return 'kreuzfahrt-umbuchen';
    case 'fr-fr':
      return 'reserver-a-nouveau-une-croisiere';
    case 'nb-no':
      return 'ombook-cruise';
    case 'sv-se':
      return 'omboka-kryssning';
    case 'da-dk':
      return 'ombook-krydstogt';
    default:
      return 'rebook-cruise';
  }
};

export const mapLocaleToSupportUri = (locale: TLocale): string => {
  switch (locale) {
    case 'de-de':
    case 'de-ch':
      return 'unterstutzung';
    case 'fr-fr':
      return 'assistance';
    case 'nb-no':
      return 'hjelp';
    case 'sv-se':
      return 'hjalp';
    case 'da-dk':
      return 'hjaelp';
    default:
      return 'support';
  }
};

export const mapLocaleToPracticalInfoUri = (locale: TLocale): string => {
  switch (locale) {
    case 'de-de':
    case 'de-ch':
      return 'unterstutzung/praktische-informationen';
    case 'fr-fr':
      return 'assistance/informations-pratiques';
    case 'nb-no':
      return 'hjelp/praktisk-informasjon';
    case 'sv-se':
      return 'hjalp/praktisk-information';
    case 'da-dk':
      return 'hjaelp/praktisk-information';
    default:
      return 'support/practical-information';
  }
};

export const mapLocaleToFAQUri = (locale: TLocale): string => {
  switch (locale) {
    case 'de-de':
    case 'de-ch':
      return 'unterstutzung/fragen-und-antworten';
    case 'fr-fr':
      return 'assistance/faq';
    case 'nb-no':
      return 'hjelp/faq';
    case 'sv-se':
      return 'hjalp/faq';
    case 'da-dk':
      return 'hjaelp/faq';
    default:
      return 'support/faq';
  }
};

export const mapLocaleToHelpTopicsUri = (locale: TLocale): string => {
  switch (locale) {
    case 'de-de':
    case 'de-ch':
      return 'unterstutzung/hilfethemen';
    case 'fr-fr':
      return 'assistance/sujets-d-assistance';
    case 'nb-no':
      return 'hjelp/hjelpetemaer';
    case 'sv-se':
      return 'hjalp/hjalpamnen';
    case 'da-dk':
      return 'hjaelp/hjaelpemner';
    default:
      return 'support/help-topics';
  }
};

export const mapLocaleToHealthAndSafetyUri = (locale: TLocale): string => {
  switch (locale) {
    case 'de-de':
    case 'de-ch':
      return 'gesundheit-und-sicherheit';
    case 'fr-fr':
      return 'sante-et-securite';
    case 'nb-no':
      return 'helse-og-sikkerhet';
    case 'sv-se':
      return 'halsa-och-sakerhet';
    case 'da-dk':
      return 'sundhed-og-sikkerhed';
    default:
      return 'health-and-safety';
  }
};

export const mapLocaleToShipsUri = (locale: TLocale): string => {
  switch (locale) {
    case 'de-de':
    case 'de-ch':
      return 'schiffe';
    case 'fr-fr':
      return 'navires';
    case 'nb-no':
      return 'skip';
    case 'sv-se':
      return 'fartyg';
    case 'da-dk':
      return 'skibe';
    default:
      return 'ships';
  }
};

export const mapLocaleToRequestARefundUri = (locale: TLocale): string => {
  switch (locale) {
    case 'de-de':
    case 'de-ch':
      return 'rueckerstattung-anfordern';
    case 'fr-fr':
      return 'demander-un-remboursement';
    case 'nb-no':
      return 'be-om-refusjon';
    case 'sv-se':
      return 'begar-aterbetalning';
    case 'da-dk':
      return 'anmodning-tilbagebetaling';
    default:
      return 'request-a-refund';
  }
};

export const mapLocaleToRequestAQuoteUri = (locale: TLocale): string => {
  switch (locale) {
    case 'de-de':
    case 'de-ch':
      return 'angebot-anfordern';
    case 'fr-fr':
      return 'demander-un-devis';
    case 'nb-no':
      return 'be-om-tilbud';
    case 'sv-se':
      return 'begar-offert';
    case 'da-dk':
      return 'anmodning-tilbud';
    default:
      return 'request-a-quote';
  }
};

export const mapLocaleToSaferTogetherUri = (locale: TLocale): string => {
  switch (locale) {
    case 'de-de':
    case 'de-ch':
      return 'gemeinsam-sicher';
    case 'fr-fr':
      return 'plus-en-securite-ensemble';
    case 'nb-no':
      return 'tryggere-sammen';
    case 'sv-se':
      return 'sakra-tillsammans';
    case 'da-dk':
      return 'sikrere-sammen';
    default:
      return 'safer-together';
  }
};

export const mapLocaleToSearchUri = (locale: TLocale): string => {
  switch (locale) {
    case 'de-de':
    case 'de-ch':
      return 'suche';
    case 'fr-fr':
      return 'recherche';
    case 'nb-no':
    case 'sv-se':
      return 'sok';
    case 'da-dk':
      return 'sog';
    default:
      return 'search';
  }
};

export const mapLocaleToTopicsUri = (locale: TLocale): string => {
  switch (locale) {
    case 'de-de':
    case 'de-ch':
      return 'themen';
    case 'fr-fr':
      return 'sujets';
    case 'nb-no':
    case 'da-dk':
      return 'emner';
    case 'sv-se':
      return 'amnen';
    default:
      return 'topics';
  }
};

export const mapLocaleToTermsUri = (locale: TLocale): string => {
  switch (locale) {
    case 'de-de':
    case 'de-ch':
      return 'bedingungen';
    case 'fr-fr':
      return 'termes';
    case 'nb-no':
    case 'da-dk':
      return 'vilkar';
    case 'sv-se':
      return 'villkor';
    default:
      return 'terms';
  }
};

export const mapLocaleToNewsLetterUri = (locale: TLocale): string => {
  switch (locale) {
    case 'nb-no':
    case 'sv-se':
      return 'nyhetsbrev';
    case 'da-dk':
      return 'nyhedsbrev';
    default:
      return 'newsletter';
  }
};

export const mapLocaleToCompleteYourOrderUri = (locale: TLocale): string => {
  switch (locale) {
    case 'de-de':
    case 'de-ch':
      return 'bestellung-abschliessen';
    case 'nb-no':
      return 'fullfor-bestillingen';
    case 'sv-se':
      return 'slutfora-bestallning';
    case 'da-dk':
      return 'slutfora-bestallning';
    default:
      return 'complete-your-order';
  }
};

export const mapLocaleToPrivacyPolicyUri = (locale: TLocale): string => {
  switch (locale) {
    case 'fr-fr':
      return '/termes/politique-de-confidentialite';
    case 'de-ch':
    case 'de-de':
      return '/bedingungen/datenschutzerklarung';
    case 'sv-se':
      return '/villkor/sekretesspolicy';
    case 'nb-no':
      return '/vilkar/personvernerklaring';
    case 'da-dk':
      return '/praktiske-oplysninger/information-om-fortrolighed/';
    default:
      return '/terms/privacy-policy';
  }
};
