enum ButtonModes {
  primary = 'primary',
  secondary = 'secondary',
  secondaryBlack = 'secondary-black',
  secondaryWhite = 'secondary-white',
  secondaryLightGray = 'secondary-light-gray',
  secondaryWarmGray2 = 'secondary-warm-gray-2',
  tertiary = 'tertiary',
  icon = 'icon',
  flat = 'flat',
  flatBlack = 'flat black',
  flatWhite = 'flat white',
  fab = 'fab',
  menuLink = 'menu-link',
  none = 'none'
}

export default ButtonModes;
