import React, { ReactNode, useState, createContext } from 'react';

type TXrayProviderProps = {
  children: ReactNode;
  defaultValue: boolean;
};

type TStateContext = {
  xRayState: boolean;
  setXray: (newData: boolean) => void;
};

export const XrayContext = createContext({} as TStateContext);

export function XrayProvider({ children, defaultValue }: TXrayProviderProps) {
  const [xRayState, setState] = useState<boolean>(defaultValue);

  const setXray = (newData: boolean) => {
    setState(newData);

    localStorage?.setItem('XRAY', newData.toString());
  };

  const value = {
    xRayState,
    setXray
  };

  return <XrayContext.Provider value={value}>{children}</XrayContext.Provider>;
}
