/* eslint-disable max-len */
// Auto generated file. Do not edit.

export const supportHub: Record<string, TSupportHub> = {
  'en-AU': {
    supportHub: {
      title: 'Help & FAQs',
      description: 'Browse our collection of help topics and FAQ’s',
      browseAll: 'Browse all',
      helpTopics: 'Help Topics',
      faqs: 'FAQs',
      support: 'Support',
      contactUs: 'Contact us',
      refundRequest: 'Refund request form',
      feedback: 'Send us your feedback',
      requestAndFeedbackForm: 'Refund request/feedback form',
      messageUs: 'Message us',
      helpCentre: 'Help centre',
      callUs: 'Call us'
    }
  },
  'en-GB': {
    supportHub: {
      title: 'Help & FAQs',
      description: 'Browse our collection of help topics and FAQ’s',
      browseAll: 'Browse all',
      helpTopics: 'Help Topics',
      faqs: 'FAQs',
      support: 'Support',
      contactUs: 'Contact us',
      refundRequest: 'Refund request form',
      feedback: 'Send us your feedback',
      requestAndFeedbackForm: 'Refund request/feedback form',
      messageUs: 'Message us',
      helpCentre: 'Help centre',
      callUs: 'Call us'
    }
  },
  'en-US': {
    supportHub: {
      title: 'Help & FAQs',
      description: 'Browse our collection of help topics and FAQ’s',
      browseAll: 'Browse all',
      helpTopics: 'Help Topics',
      faqs: 'FAQs',
      support: 'Support',
      contactUs: 'Contact us',
      refundRequest: 'Refund request form',
      feedback: 'Send us your feedback',
      requestAndFeedbackForm: 'Refund request/feedback form',
      messageUs: 'Message us',
      helpCentre: 'Help center',
      callUs: 'Call us'
    }
  },
  'de-DE': {
    supportHub: {
      title: 'Hilfe und FAQs',
      description: 'Durchsuchen Sie unsere Sammlung von Hilfethemen und FAQs',
      browseAll: 'Alle durchsuchen',
      helpTopics: 'Hilfethemen',
      faqs: 'Häufig gestellte Fragen',
      support: 'Unterstützung',
      contactUs: 'Kontaktieren Sie uns',
      refundRequest: 'Rückerstattungsantragsformular',
      feedback: 'Schicken Sie uns Ihr Feedback',
      requestAndFeedbackForm: 'Rückerstattungsantrags-/Feedback-Formular',
      messageUs: 'Nachricht an uns',
      helpCentre: 'Hilfethemen',
      callUs: 'Rufen Sie uns an'
    }
  },
  'gsw-CH': {
    supportHub: {
      title: 'Hilfe und FAQs',
      description: 'Durchsuchen Sie unsere Sammlung von Hilfethemen und FAQs',
      browseAll: 'Alle durchsuchen',
      helpTopics: 'Hilfethemen',
      faqs: 'Häufig gestellte Fragen',
      support: 'Unterstützung',
      contactUs: 'Kontaktieren Sie uns',
      refundRequest: 'Rückerstattungsantragsformular',
      feedback: 'Schicken Sie uns Ihr Feedback',
      requestAndFeedbackForm: 'Rückerstattungsantrags-/Feedback-Formular',
      messageUs: 'Nachricht an uns',
      helpCentre: 'Hilfethemen',
      callUs: 'Rufen Sie uns an'
    }
  },
  'fr-FR': {
    supportHub: {
      title: 'Assistance et FAQ',
      description: 'Parcourez notre collection de sujets d\'assistance et de FAQ',
      browseAll: 'Parcourir tout',
      helpTopics: 'Sujets d\'assistance',
      faqs: 'Foire aux questions fréquentes',
      support: 'Assistance',
      contactUs: 'Contactez-nous',
      refundRequest: 'Formulaire de demande de remboursement',
      feedback: 'Envoyez-nous vos commentaires',
      requestAndFeedbackForm: 'Formulaire de demande de remboursement/retour d\'expérience',
      messageUs: 'Nous envoyer un message',
      helpCentre: 'Sujets d\'assistance',
      callUs: 'Appelez-nous'
    }
  },
  'da-DK': {
    supportHub: {
      title: 'Hjælp og Ofte stillede spørgsmål',
      description: 'Gennemse vores samling af hjælpeemner og FAQ\'er',
      browseAll: 'Gennemse alle',
      helpTopics: 'Hjælpemner',
      faqs: 'Ofte stillede spørgsmål',
      support: 'Hjælp',
      contactUs: 'Kontakt os',
      refundRequest: '"Formular til anmodning om tilbagebetaling',
      feedback: 'Send os din tilbagemelding',
      requestAndFeedbackForm: 'Tilbagebetalings-/feedback-anmodningsformular',
      messageUs: 'Send os en besked',
      helpCentre: 'Hjælpemner',
      callUs: 'Ring til os'
    }
  },
  'sv-SE': {
    supportHub: {
      title: 'Hjälp och Vanliga frågor',
      description: 'Bläddra i vår samling av hjälptexter och vanliga frågor (FAQ)',
      browseAll: 'Bläddra i allt',
      helpTopics: 'Hjälpämnen',
      faqs: 'Vanliga frågor och svar',
      support: 'Hjälp',
      contactUs: 'Kontakta oss',
      refundRequest: 'Återbetalningsformulär',
      feedback: 'Ge oss din återkoppling',
      requestAndFeedbackForm: 'Återbetalnings-/feedback-formulär',
      messageUs: 'Meddela oss',
      helpCentre: 'Hjälpämnen',
      callUs: 'Ring oss'
    }
  },
  'nb-NO': {
    supportHub: {
      title: 'Hjelp og Ofte stilte spørsmål',
      description: 'Bla gjennom vår samling av hjelpetemaer og FAQ-er',
      browseAll: 'Bla gjennom alt',
      helpTopics: 'Hjelpetemaer',
      faqs: 'Ofte stilte spørsmål',
      support: 'Hjelp',
      contactUs: 'Kontakt oss',
      refundRequest: 'Skjema for refusjonsforespørsel',
      feedback: 'Gi oss tilbakemelding',
      requestAndFeedbackForm: 'Refusjonsforespørsel-/tilbakemeldingsskjema',
      messageUs: 'Send oss en melding',
      helpCentre: 'Hjelpetemaer',
      callUs: 'Ring oss'
    }
  },
  'en': {
    supportHub: {
      title: 'Help & FAQs',
      description: 'Browse our collection of help topics and FAQ’s',
      browseAll: 'Browse all',
      helpTopics: 'Help Topics',
      faqs: 'FAQs',
      support: 'Support',
      contactUs: 'Contact us',
      refundRequest: 'Refund request form',
      feedback: 'Send us your feedback',
      requestAndFeedbackForm: 'Refund request/feedback form',
      messageUs: 'Message us',
      helpCentre: 'Help centre',
      callUs: 'Call us'
    }
  }
}

export type TSupportHub = {
  supportHub: {
    title: string;
    description: string;
    browseAll: string;
    helpTopics: string;
    faqs: string;
    support: string;
    contactUs: string;
    refundRequest: string;
    feedback: string;
    requestAndFeedbackForm: string;
    messageUs: string;
    helpCentre: string;
    callUs: string;
  };
}
