import {
  buildBookingStepUrlWithLocale,
  buildPaymentStepUrlWithLocale,
  buildTermsUrlWithLocale,
  buildUrlWithLocale
} from '@src/utils';
import useLocale from '@hooks/useLocale';

const useUrlBuilder = () => {
  const locale = useLocale();

  const buildBookingStepUrl = buildBookingStepUrlWithLocale(locale);
  const buildPaymentStepUrl = buildPaymentStepUrlWithLocale(locale);
  const buildUrl = buildUrlWithLocale(locale);
  const buildTermsUrl = buildTermsUrlWithLocale(locale);

  return {
    buildBookingStepUrl,
    buildPaymentStepUrl,
    buildUrl,
    buildTermsUrl
  };
};

export default useUrlBuilder;
