const BANNER_INFORMATION_STORAGE_KEY = 'GLOBAL_BANNER_INFORMATION';

export const setBannerInLocalStorage = ({ savedAt }: { savedAt: number }) => {
  localStorage.setItem(
    BANNER_INFORMATION_STORAGE_KEY,
    JSON.stringify({ savedAt })
  );
};

export const getBannerFromLocalStorage = () => {
  const dataFromStorage = localStorage.getItem(BANNER_INFORMATION_STORAGE_KEY);

  if (dataFromStorage) {
    const data: {
      savedAt: number;
    } = JSON.parse(dataFromStorage);
    return data;
  }

  return undefined;
};

export const removeBannerFromLocalStorage = () => {
  localStorage.removeItem(BANNER_INFORMATION_STORAGE_KEY);
};
