import React, { createContext, Dispatch, useReducer } from 'react';

import rootReducer from './rootReducer';
import { initialState } from './initialState';

interface IStateProviderProps {
  children: any;
}

interface IStateContext {
  state: IApplicationState;
  dispatch: Dispatch<IStateAction>;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export const GlobalStore = createContext({} as IStateContext);

export function StateProvider(props: IStateProviderProps) {
  const [state, dispatch] = useReducer(rootReducer, initialState);

  return (
    <GlobalStore.Provider value={{ state, dispatch }}>
      {props.children}
    </GlobalStore.Provider>
  );
}
