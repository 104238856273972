/* eslint-disable max-len */
// Auto generated file. Do not edit.

export const landing: Record<string, TLanding> = {
  'en-AU': {
    landing: {
      destinationsTitle: 'Must-see destinations',
      destinationsButton: 'Explore all destinations',
      planYourAdventureNow: 'Plan your adventure now',
      findYourAdventureNow: 'Find your adventure now',
      areYouReadyToExplore: 'Are you ready to explore?',
      joinOurWorldAndGetRewarded: 'Join our loyalty programme',
      signUpToLoyaltyAndMore: 'As a member you will be part of a community of like-minded travellers. Enjoy exclusive benefits from day 1 of your membership, inspiring newsletters, partner discounts and much more.',
      joinTheProgramme: 'Become a member now',
      storiesTitle: 'Inspirational stories',
      storiesButton: 'See all stories',
      ambassadorProgrammeTitle: 'HX Explorers',
      explorerTitle: 'Are you ready to explore?',
      whyExpeditions: 'Why Hurtigruten Expeditions',
      readMoreAboutUsButton: 'Read more about us',
      seeAllOffers: 'See all offers',
      offerDaysLeft: '{days} days left',
      offerUntil: 'Until {date}',
      mainTitle: 'Hurtigruten Home Page',
      cruisesTitle: 'Featured cruises',
      cruisesButton: 'See more cruises'
    }
  },
  'en-GB': {
    landing: {
      destinationsTitle: 'Must-see destinations',
      destinationsButton: 'Explore all destinations',
      planYourAdventureNow: 'Plan your adventure now',
      findYourAdventureNow: 'Find your adventure now',
      areYouReadyToExplore: 'Are you ready to explore?',
      joinOurWorldAndGetRewarded: 'Join our loyalty programme',
      signUpToLoyaltyAndMore: 'As a member you will be part of a community of like-minded travellers. Enjoy exclusive benefits from day 1 of your membership, inspiring newsletters, partner discounts and much more.',
      joinTheProgramme: 'Become a member now',
      storiesTitle: 'Inspirational stories',
      storiesButton: 'See all stories',
      ambassadorProgrammeTitle: 'HX Explorers',
      explorerTitle: 'Are you ready to explore?',
      whyExpeditions: 'Why Hurtigruten Expeditions',
      readMoreAboutUsButton: 'Read more about us',
      seeAllOffers: 'See all offers',
      offerDaysLeft: '{days} days left',
      offerUntil: 'Until {date}',
      mainTitle: 'Hurtigruten Home Page',
      cruisesTitle: 'Featured cruises',
      cruisesButton: 'See more cruises'
    }
  },
  'en-US': {
    landing: {
      destinationsTitle: 'Must-see destinations',
      destinationsButton: 'Explore all destinations',
      planYourAdventureNow: 'Plan your adventure now',
      findYourAdventureNow: 'Find your adventure now',
      areYouReadyToExplore: 'Shift Your Latitude',
      joinOurWorldAndGetRewarded: 'Join our loyalty program',
      signUpToLoyaltyAndMore: 'As a member you will be part of a community of like-minded travelers. Enjoy exclusive benefits from day 1 of your membership, inspiring newsletters, partner discounts and much more.',
      joinTheProgramme: 'Become a member now',
      storiesTitle: 'Inspiration',
      storiesButton: 'See all stories',
      ambassadorProgrammeTitle: 'HX Explorers',
      explorerTitle: 'Shift Your Latitude',
      whyExpeditions: 'Why Hurtigruten Expeditions',
      readMoreAboutUsButton: 'Read more about us',
      seeAllOffers: 'See all offers',
      offerDaysLeft: '{days} days left',
      offerUntil: 'Until {date}',
      mainTitle: 'Hurtigruten Home Page',
      cruisesTitle: 'Featured cruises',
      cruisesButton: 'See more cruises'
    }
  },
  'de-DE': {
    landing: {
      destinationsTitle: 'Unsere Reiseziele',
      destinationsButton: 'Entdecken Sie alle Reiseziele',
      planYourAdventureNow: 'Planen Sie jetzt Ihr Abenteuer',
      findYourAdventureNow: 'Finden Sie Ihr Abenteuer',
      areYouReadyToExplore: 'Sind Sie bereit für Ihre Entdeckungsreise?',
      joinOurWorldAndGetRewarded: 'Werden Sie Ambassador',
      signUpToLoyaltyAndMore: 'Als Mitglied werden Sie Teil einer Gemeinschaft von gleichgesinnten Reisenden. Genießen Sie vom ersten Tag Ihrer Mitgliedschaft an exklusive Vorteile, inspirierende Newsletter, Partnerrabatte und vieles mehr.',
      joinTheProgramme: 'Werden Sie jetzt Mitglied',
      storiesTitle: 'Inspirierende Geschichten',
      storiesButton: 'Alle Geschichten ansehen',
      ambassadorProgrammeTitle: 'HX Explorers',
      explorerTitle: 'Sind Sie bereit für Ihre Entdeckungsreise?',
      whyExpeditions: 'Warum Hurtigruten Expeditions',
      readMoreAboutUsButton: 'Erfahren Sie mehr über uns',
      seeAllOffers: 'Alle Angebote ansehen',
      offerDaysLeft: '{days} verbleibende Tage',
      offerUntil: 'Bis {date}',
      mainTitle: 'Hurtigruten-Startseite',
      cruisesTitle: 'Ausgewählte Seereisen',
      cruisesButton: 'Mehr Seereisen anzeigen'
    }
  },
  'gsw-CH': {
    landing: {
      destinationsTitle: 'Unsere Reiseziele',
      destinationsButton: 'Entdecken Sie alle Reiseziele',
      planYourAdventureNow: 'Planen Sie jetzt Ihr Abenteuer',
      findYourAdventureNow: 'Finden Sie Ihr Abenteuer',
      areYouReadyToExplore: 'Sind Sie bereit für Ihre Entdeckungsreise?',
      joinOurWorldAndGetRewarded: 'Werden Sie Ambassador',
      signUpToLoyaltyAndMore: 'Als Mitglied werden Sie Teil einer Gemeinschaft von gleichgesinnten Reisenden. Geniessen Sie vom ersten Tag Ihrer Mitgliedschaft an exklusive Vorteile, inspirierende Newsletter, Partnerrabatte und vieles mehr.',
      joinTheProgramme: 'Werden Sie jetzt Mitglied',
      storiesTitle: 'Inspirierende Geschichten',
      storiesButton: 'Alle Geschichten ansehen',
      ambassadorProgrammeTitle: 'HX Explorers',
      explorerTitle: 'Sind Sie bereit für Ihre Entdeckungsreise?',
      whyExpeditions: 'Warum Hurtigruten Expeditions',
      readMoreAboutUsButton: 'Erfahren Sie mehr über uns',
      seeAllOffers: 'Alle Angebote ansehen',
      offerDaysLeft: '{days} verbleibende Tage',
      offerUntil: 'Bis {date}',
      mainTitle: 'Hurtigruten-Startseite',
      cruisesTitle: 'Ausgewählte Seereisen',
      cruisesButton: 'Mehr Seereisen anzeigen'
    }
  },
  'fr-FR': {
    landing: {
      destinationsTitle: 'Destinations incontournables',
      destinationsButton: 'Explorer toutes les destinations',
      planYourAdventureNow: 'Planifiez votre aventure dès maintenant',
      findYourAdventureNow: 'Trouvez votre aventure dès maintenant',
      areYouReadyToExplore: 'Préparez-vous à explorer !',
      joinOurWorldAndGetRewarded: 'Rejoignez notre programme de fidélité',
      signUpToLoyaltyAndMore: 'En tant que membre, vous intégrerez une communauté de voyageurs qui vous ressemblent. Profitez d’avantages exclusifs dès le premier jour de votre adhésion, de newsletters inspirantes, de remises auprès de nos partenaires et de bien d’autres choses encore.',
      joinTheProgramme: 'Devenez membre dès maintenant',
      storiesTitle: 'Articles inspirants',
      storiesButton: 'Voir tous les articles',
      ambassadorProgrammeTitle: 'HX Explorers',
      explorerTitle: 'Préparez-vous à explorer !',
      whyExpeditions: 'Pourquoi Hurtigruten Expeditions',
      readMoreAboutUsButton: 'En savoir plus à propos de nous',
      seeAllOffers: 'Voir toutes les offres',
      offerDaysLeft: '{days} jours restants',
      offerUntil: 'Jusqu’au {date}',
      mainTitle: 'Page d’accueil Hurtigruten',
      cruisesTitle: 'Idées de croisières',
      cruisesButton: 'Voir plus de croisières'
    }
  },
  'da-DK': {
    landing: {
      destinationsTitle: 'Destinationer du må opleve',
      destinationsButton: 'Udforsk alle destinationer',
      planYourAdventureNow: 'Planlæg din eventyrrejse nu',
      findYourAdventureNow: 'Find dit eventyr nu',
      areYouReadyToExplore: 'Er du klar til eventyr?',
      joinOurWorldAndGetRewarded: 'Tilmeld dig vores loyalitetsprogram',
      signUpToLoyaltyAndMore: 'Som medlem bliver du en del af et fællesskab af ligesindede rejsende. Få eksklusive fordele af dit medlemskab med det samme, inspirerende nyhedsbreve, rabatter fra partnere og meget mere.',
      joinTheProgramme: 'Bliv medlem nu',
      storiesTitle: 'Inspiration',
      storiesButton: 'Find mere inspiration',
      ambassadorProgrammeTitle: 'HX Explorers',
      explorerTitle: 'Er du klar til eventyr?',
      whyExpeditions: 'Hvorfor Hurtigruten Expeditions?',
      readMoreAboutUsButton: 'Læs mere om os',
      seeAllOffers: 'Se alle tilbud',
      offerDaysLeft: '{days} dage tilbage',
      offerUntil: 'Indtil {date}',
      mainTitle: 'Hurtigrutens startside',
      cruisesTitle: 'Udvalgte krydstogter',
      cruisesButton: 'Vis flere krydstogter'
    }
  },
  'sv-SE': {
    landing: {
      destinationsTitle: 'Resmål du inte får missa',
      destinationsButton: 'Upptäck alla våra resmål',
      planYourAdventureNow: 'Planera ditt äventyr nu',
      findYourAdventureNow: 'Hitta ditt äventyr nu',
      areYouReadyToExplore: 'Är du redo för äventyr?',
      joinOurWorldAndGetRewarded: 'Gå med i vårt lojalitetsprogram',
      signUpToLoyaltyAndMore: 'Som medlem blir du del av en grupp likasinnade reseentusiaster. Du får inspirerande nyhetsbrev, rabatt hos våra partner, exklusiva förmåner som du kan använda redan från första dagen som medlem och mycket mer.',
      joinTheProgramme: 'Bli medlem nu',
      storiesTitle: 'Inspiration',
      storiesButton: 'Hitta mer inspiration',
      ambassadorProgrammeTitle: 'HX Explorers',
      explorerTitle: 'Är du redo för äventyr?',
      whyExpeditions: 'Varför Hurtigruten Expeditions?',
      readMoreAboutUsButton: 'Läs mer om oss',
      seeAllOffers: 'Visa alla erbjudanden',
      offerDaysLeft: '{days} dagar kvar',
      offerUntil: 'Till {date}',
      mainTitle: 'Hurtigruten hemsida',
      cruisesTitle: 'Utvalda kryssningar',
      cruisesButton: 'Se fler kryssningar'
    }
  },
  'nb-NO': {
    landing: {
      destinationsTitle: 'Destinasjoner som må oppleves',
      destinationsButton: 'Utforsk alle destinasjoner',
      planYourAdventureNow: 'Planlegg eventyret ditt nå',
      findYourAdventureNow: 'Finn eventyret ditt nå',
      areYouReadyToExplore: 'Er du klar til å utforske?',
      joinOurWorldAndGetRewarded: 'Bli med i lojalitetsprogrammet vårt',
      signUpToLoyaltyAndMore: 'Som medlem blir du en del av et fellesskap av likesinnede reiseentusiaster. Få eksklusive fordeler fra første dag av medlemskapet, inspirerende nyhetsbrev, rabatter og mye mer.',
      joinTheProgramme: 'Bli medlem nå',
      storiesTitle: 'Inspirasjon',
      storiesButton: 'Finn mer inspirasjon',
      ambassadorProgrammeTitle: 'HX Explorers',
      explorerTitle: 'Er du klar til å utforske?',
      whyExpeditions: 'Hvorfor Hurtigruten Expeditions',
      readMoreAboutUsButton: 'Les mer om oss',
      seeAllOffers: 'Se alle tilbud',
      offerDaysLeft: '{days} dager igjen',
      offerUntil: 'Frem til {date}',
      mainTitle: 'Hurtigrutens hjemmeside',
      cruisesTitle: 'Utvalgte ekspedisjonscruise',
      cruisesButton: 'Se alle reiser'
    }
  },
  'en': {
    landing: {
      destinationsTitle: 'Must-see destinations',
      destinationsButton: 'Explore all destinations',
      planYourAdventureNow: 'Plan your adventure now',
      findYourAdventureNow: 'Find your adventure now',
      areYouReadyToExplore: 'Are you ready to explore?',
      joinOurWorldAndGetRewarded: 'Join our loyalty programme',
      signUpToLoyaltyAndMore: 'As a member you will be part of a community of like-minded travellers. Enjoy exclusive benefits from day 1 of your membership, inspiring newsletters, partner discounts and much more.',
      joinTheProgramme: 'Become a member now',
      storiesTitle: 'Inspirational stories',
      storiesButton: 'See all stories',
      ambassadorProgrammeTitle: 'HX Explorers',
      explorerTitle: 'Are you ready to explore?',
      whyExpeditions: 'Why Hurtigruten Expeditions',
      readMoreAboutUsButton: 'Read more about us',
      seeAllOffers: 'See all offers',
      offerDaysLeft: '{days} days left',
      offerUntil: 'Until {date}',
      mainTitle: 'Hurtigruten Home Page',
      cruisesTitle: 'Featured cruises',
      cruisesButton: 'See more cruises'
    }
  }
}

export type TLanding = {
  landing: {
    destinationsTitle: string;
    destinationsButton: string;
    planYourAdventureNow: string;
    findYourAdventureNow: string;
    areYouReadyToExplore: string;
    joinOurWorldAndGetRewarded: string;
    signUpToLoyaltyAndMore: string;
    joinTheProgramme: string;
    storiesTitle: string;
    storiesButton: string;
    ambassadorProgrammeTitle: string;
    explorerTitle: string;
    whyExpeditions: string;
    readMoreAboutUsButton: string;
    seeAllOffers: string;
    offerDaysLeft: string;
    offerUntil: string;
    mainTitle: string;
    cruisesTitle: string;
    cruisesButton: string;
  };
}
