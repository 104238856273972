import { useEffect, useState } from 'react';

const useNow = (updateInterval: number = 1000) => {
  const [now, setNow] = useState<Date>(new Date());
  useEffect(() => {
    const interval = setInterval(() => {
      setNow(new Date());
    }, updateInterval);

    return () => {
      clearInterval(interval);
    };
  }, []);
  return now;
};

export default useNow;
