/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */

import { EVENT_TYPES } from './eventBuilder';

const shouldClearEcommerce = (event: Analytics.Events.TBaseEvent) => {
  if (!event || !event.event) return false;
  switch (event.event) {
    case EVENT_TYPES.viewItem:
    case EVENT_TYPES.viewItemList:
    case EVENT_TYPES.addToCart:
    case EVENT_TYPES.purchase:
    case EVENT_TYPES.sendToPayment:
    case EVENT_TYPES.selectItem:
      return true;
    default:
      return false;
  }
};

export function trackEvent<T extends Analytics.Events.TBaseEvent>(event: T) {
  if (!window) {
    console.warn('track event can only be used on the client side');
    return;
  }
  const shouldClear = shouldClearEcommerce(event);
  window.dataLayer = window.dataLayer || [];
  if (shouldClear) {
    window.dataLayer.push({ ecommerce: null });
  }
  window.dataLayer.push(event);
}
