export const sha256 = async (str: string | undefined):
  Promise<string | undefined> => {

  const msgUint8 = new TextEncoder().encode(str);

  if (typeof crypto.subtle?.digest !== 'function') {
    return undefined;
  }

  const hashBuffer = await crypto.subtle.digest('SHA-256', msgUint8);
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  const hashHex = hashArray.map((b) => b.toString(16).padStart(2, '0')).join('');

  return str ? hashHex : undefined;
}
