/* eslint-disable max-len */
// Auto generated file. Do not edit.

export const footer: Record<string, TFooter> = {
  'en-AU': {
    footer: {
      campaign: {
        title: 'Sign up for our newsletter',
        text: 'Receive special offers, exciting new itineraries and fascinating articles delivered straight to your inbox.',
        button: 'Sign up'
      },
      followUs: 'Follow us',
      about: {
        title: 'About',
        aboutHRG: 'About Hurtigruten Group',
        ambassadorProgramme: 'HX Explorers Loyalty Programme',
        sustainability: 'Sustainability',
        careers: 'Careers'
      },
      support: {
        title: 'Support',
        covidInfo: 'Covid-19: Updated information',
        faq: 'FAQ',
        contactUs: 'Contact us',
        requestQuote: 'Request a quote',
        orderBrochure: 'Order brochure'
      },
      goTo: {
        title: 'Go to',
        norwegianCoastalExpress: 'Hurtigruten',
        HRG: 'Hurtigruten Group'
      },
      termsAndConditions: 'Terms and conditions',
      privacyStatement: 'Privacy statement',
      cookiePolicy: 'Cookie policy',
      copyright: '© 2024 Hurtigruten Expeditions',
      contact: {
        title: 'Contact'
      },
      unableToLoadContact: 'Unable to load contact information, please try again later.',
      changeCountry: 'Change country',
      managePreferences: 'Manage preferences',
      hurtigrutenExpeditions: 'HX Hurtigruten Expeditions',
      norwegianCoastalExpress: 'Hurtigruten',
      expeditionCruises: 'Expedition Cruises',
      northernLightsCruise: 'Northern Lights Cruise',
      title: 'Footer'
    }
  },
  'en-GB': {
    footer: {
      campaign: {
        title: 'Sign up for our newsletter',
        text: 'Receive special offers, exciting new itineraries and fascinating articles delivered straight to your inbox.',
        button: 'Sign up'
      },
      followUs: 'Follow us',
      about: {
        title: 'About',
        aboutHRG: 'About Hurtigruten Group',
        ambassadorProgramme: 'HX Explorers Loyalty Programme',
        sustainability: 'Sustainability',
        careers: 'Careers'
      },
      support: {
        title: 'Support',
        covidInfo: 'Covid-19: Updated information',
        faq: 'FAQ',
        contactUs: 'Contact us',
        requestQuote: 'Request a quote',
        orderBrochure: 'Order brochure'
      },
      goTo: {
        title: 'Go to',
        norwegianCoastalExpress: 'Hurtigruten',
        HRG: 'Hurtigruten Group'
      },
      termsAndConditions: 'Terms and conditions',
      privacyStatement: 'Privacy statement',
      cookiePolicy: 'Cookie policy',
      copyright: '© 2024 Hurtigruten Expeditions',
      contact: {
        title: 'Contact'
      },
      unableToLoadContact: 'Unable to load contact information, please try again later.',
      changeCountry: 'Change country',
      managePreferences: 'Manage preferences',
      hurtigrutenExpeditions: 'HX Hurtigruten Expeditions',
      norwegianCoastalExpress: 'Hurtigruten',
      expeditionCruises: 'Expedition Cruises',
      northernLightsCruise: 'Northern Lights Cruise',
      title: 'Footer'
    }
  },
  'en-US': {
    footer: {
      campaign: {
        title: 'Receive $90 onboard credit',
        text: 'Sign up for our newsletter and receive $90 to spend onboard during your first expedition.',
        button: 'Yes, sign me up!'
      },
      followUs: 'Follow us',
      about: {
        title: 'About',
        aboutHRG: 'About Hurtigruten Group',
        ambassadorProgramme: 'HX Explorers Loyalty Program',
        sustainability: 'Sustainability',
        careers: 'Careers'
      },
      support: {
        title: 'Support',
        covidInfo: 'Covid-19: Updated information',
        faq: 'FAQ',
        contactUs: 'Contact us',
        requestQuote: 'Request a quote',
        orderBrochure: 'Order brochure'
      },
      goTo: {
        title: 'Go to',
        norwegianCoastalExpress: 'Hurtigruten',
        HRG: 'Hurtigruten Group'
      },
      termsAndConditions: 'Terms and conditions',
      privacyStatement: 'Privacy statement',
      cookiePolicy: 'Cookie policy',
      copyright: '© 2024 Hurtigruten Expeditions',
      contact: {
        title: 'Contact'
      },
      unableToLoadContact: 'Unable to load contact information, please try again later.',
      changeCountry: 'Change country',
      managePreferences: 'Manage preferences',
      hurtigrutenExpeditions: 'HX Hurtigruten Expeditions',
      norwegianCoastalExpress: 'Hurtigruten',
      expeditionCruises: 'Expedition Cruises',
      northernLightsCruise: 'Northern Lights Cruise',
      title: 'Footer'
    }
  },
  'de-DE': {
    footer: {
      campaign: {
        title: 'Zum Newsletter anmelden',
        text: 'Freuen Sie sich auf Sonderangebote, aufregende neue Reiserouten und faszinierende Reiseberichte direkt in Ihrem Posteingang.',
        button: 'Anmelden'
      },
      followUs: 'Folgen Sie uns',
      about: {
        title: 'Über uns',
        aboutHRG: 'Über die Hurtigruten Group',
        ambassadorProgramme: 'HX Explorers Treueprogramm',
        sustainability: 'Nachhaltigkeit',
        careers: 'Karriere'
      },
      support: {
        title: 'Support',
        covidInfo: 'COVID-19: Aktualisierte Informationen',
        faq: 'FAQ',
        contactUs: 'Kontaktieren Sie uns',
        requestQuote: 'Angebot anfordern',
        orderBrochure: 'Katalog bestellen'
      },
      goTo: {
        title: 'Weiter zu',
        norwegianCoastalExpress: 'Hurtigruten',
        HRG: 'Hurtigruten Group'
      },
      termsAndConditions: 'Allgemeine Bedingungen',
      privacyStatement: 'Datenschutzerklärung',
      cookiePolicy: 'Cookie-Richtlinie',
      copyright: '© 2024 Hurtigruten Expeditions',
      contact: {
        title: 'Kontakt'
      },
      unableToLoadContact: 'Die Kontaktinformationen konnten nicht geladen werden; bitte versuchen Sie es später noch einmal.',
      changeCountry: 'Land auswählen',
      managePreferences: 'Cookie-Einstellungen',
      hurtigrutenExpeditions: 'HX Hurtigruten Expeditions',
      norwegianCoastalExpress: 'Hurtigruten',
      expeditionCruises: 'Expeditions-Seereisen',
      northernLightsCruise: 'Seereise zu den Nordlichtern',
      title: 'Footer'
    }
  },
  'gsw-CH': {
    footer: {
      campaign: {
        title: 'Zum Newsletter anmelden',
        text: 'Freuen Sie sich auf Sonderangebote, aufregende neue Reiserouten und faszinierende Reiseberichte direkt in Ihrem Posteingang.',
        button: 'Anmelden'
      },
      followUs: 'Folgen Sie uns',
      about: {
        title: 'Über uns',
        aboutHRG: 'Über die Hurtigruten Group',
        ambassadorProgramme: 'HX Explorers Treueprogramm',
        sustainability: 'Nachhaltigkeit',
        careers: 'Karriere'
      },
      support: {
        title: 'Support',
        covidInfo: 'COVID-19: Aktualisierte Informationen',
        faq: 'FAQ',
        contactUs: 'Kontaktieren Sie uns',
        requestQuote: 'Angebot anfordern',
        orderBrochure: 'Katalog bestellen'
      },
      goTo: {
        title: 'Weiter zu',
        norwegianCoastalExpress: 'Hurtigruten',
        HRG: 'Hurtigruten Group'
      },
      termsAndConditions: 'Allgemeine Bedingungen',
      privacyStatement: 'Datenschutzerklärung',
      cookiePolicy: 'Cookie-Richtlinie',
      copyright: '© 2024 Hurtigruten Expeditions',
      contact: {
        title: 'Kontakt'
      },
      unableToLoadContact: 'Die Kontaktinformationen konnten nicht geladen werden; bitte versuchen Sie es später noch einmal.',
      changeCountry: 'Land auswählen',
      managePreferences: 'Cookie-Einstellungen',
      hurtigrutenExpeditions: 'HX Hurtigruten Expeditions',
      norwegianCoastalExpress: 'Hurtigruten',
      expeditionCruises: 'Expeditions-Seereisen',
      northernLightsCruise: 'Seereise zu den Nordlichtern',
      title: 'Footer'
    }
  },
  'fr-FR': {
    footer: {
      campaign: {
        title: 'Abonnez-vous à notre newsletter',
        text: 'Recevez des offres spéciales, de nouveaux itinéraires et des articles fascinants directement dans votre boîte de réception.',
        button: 'Inscription'
      },
      followUs: 'Suivez-nous',
      about: {
        title: 'À propos de',
        aboutHRG: 'À propos de Hurtigruten Group',
        ambassadorProgramme: 'Programme de fidélité HX Explorers',
        sustainability: 'Développement durable',
        careers: 'Carrières'
      },
      support: {
        title: 'Assistance',
        covidInfo: 'Covid-19 : informations à jour',
        faq: 'Questions fréquentes',
        contactUs: 'Contactez-nous',
        requestQuote: 'Demander un devis',
        orderBrochure: 'Commander une brochure'
      },
      goTo: {
        title: 'Aller à',
        norwegianCoastalExpress: 'Hurtigruten',
        HRG: 'Hurtigruten Group'
      },
      termsAndConditions: 'Conditions générales',
      privacyStatement: 'Déclaration de confidentialité',
      cookiePolicy: 'Politique relative aux cookies',
      copyright: '© 2024 Hurtigruten Expeditions',
      contact: {
        title: 'Contact'
      },
      unableToLoadContact: 'Impossible de charger les coordonnées, veuillez réessayer plus tard.',
      changeCountry: 'Modifier le pays',
      managePreferences: 'Gérer les préférences',
      hurtigrutenExpeditions: 'HX Hurtigruten Expeditions',
      norwegianCoastalExpress: 'Hurtigruten',
      expeditionCruises: 'Croisières d’expédition',
      northernLightsCruise: 'Croisières vers les aurores boréales',
      title: 'Pied de page'
    }
  },
  'da-DK': {
    footer: {
      campaign: {
        title: 'Tilmeld dig vores nyhedsbrev',
        text: 'Modtag tilbud, spændende nyheder og inspiration direkte i indbakken.',
        button: 'Tilmeld dig'
      },
      followUs: 'Følg os',
      about: {
        title: 'Om',
        aboutHRG: 'Om Hurtigruten Group',
        ambassadorProgramme: 'HX Explorers-loyalitetsprogram',
        sustainability: 'Bæredygtighed',
        careers: 'Stillinger'
      },
      support: {
        title: 'Support',
        covidInfo: 'Covid-19: Opdaterede oplysninger',
        faq: 'Ofte stillede spørgsmål',
        contactUs: 'Kontakt os',
        requestQuote: 'Få et tilbud',
        orderBrochure: 'Bestil en brochure'
      },
      goTo: {
        title: 'Gå til',
        norwegianCoastalExpress: 'Hurtigruten',
        HRG: 'Hurtigruten Group'
      },
      termsAndConditions: 'Vilkår og betingelser',
      privacyStatement: 'Erklæring om beskyttelse af personlige oplysninger',
      cookiePolicy: 'Cookiepolitik',
      copyright: '© 2024 Hurtigruten Expeditions',
      contact: {
        title: 'Kontakt'
      },
      unableToLoadContact: 'Kontaktoplysningerne kunne ikke indlæses. Prøv igen senere.',
      changeCountry: 'Skift land',
      managePreferences: 'Administrer indstillinger',
      hurtigrutenExpeditions: 'HX Hurtigruten Expeditions',
      norwegianCoastalExpress: 'Hurtigruten',
      expeditionCruises: 'Ekspeditionskrydstogter',
      northernLightsCruise: 'Nordlyskrydstogt',
      title: 'Sidefod'
    }
  },
  'sv-SE': {
    footer: {
      campaign: {
        title: 'Registrera dig för vårt nyhetsbrev',
        text: 'Få specialerbjudanden, tips om spännande nya resor och intressanta artiklar levererade direkt till din inkorg.',
        button: 'Registrera dig'
      },
      followUs: 'Följ oss',
      about: {
        title: 'Mer information',
        aboutHRG: 'Om Hurtigruten Group',
        ambassadorProgramme: 'Lojalitetsprogrammet HX Explorers',
        sustainability: 'Hållbarhet',
        careers: 'Jobb'
      },
      support: {
        title: 'Support',
        covidInfo: 'Covid-19: Uppdaterad information',
        faq: 'Vanliga frågor',
        contactUs: 'Kontakta oss',
        requestQuote: 'Begär en offert',
        orderBrochure: 'Beställ broschyr'
      },
      goTo: {
        title: 'Gå till',
        norwegianCoastalExpress: 'Hurtigruten',
        HRG: 'Hurtigruten Group'
      },
      termsAndConditions: 'Allmänna villkor',
      privacyStatement: 'Sekretesspolicy',
      cookiePolicy: 'Cookie-policy',
      copyright: '© 2024 Hurtigruten Expeditions',
      contact: {
        title: 'Kontakt'
      },
      unableToLoadContact: 'Det gick inte att läsa in kontaktuppgifter. Försök igen senare.',
      changeCountry: 'Ändra land',
      managePreferences: 'Hantera inställningar',
      hurtigrutenExpeditions: 'HX Hurtigruten Expeditions',
      norwegianCoastalExpress: 'Hurtigruten',
      expeditionCruises: 'Expeditionskryssningar',
      northernLightsCruise: 'Norrskenskryssning',
      title: 'Sidfot'
    }
  },
  'nb-NO': {
    footer: {
      campaign: {
        title: 'Meld deg på nyhetsbrevet vårt',
        text: 'Motta tilbud, spennende nyheter og fascinerende artikler levert rett til innboksen din.',
        button: 'Registrer deg'
      },
      followUs: 'Følg oss',
      about: {
        title: 'Om',
        aboutHRG: 'Om Hurtigruten Group',
        ambassadorProgramme: 'Lojalitetsprogrammet HX Explorers',
        sustainability: 'Bærekraft',
        careers: 'Jobb'
      },
      support: {
        title: 'Hjelp',
        covidInfo: 'Covid-19: Oppdatert informasjon',
        faq: 'FAQ – Ofte stilte spørsmål',
        contactUs: 'Kontakt oss',
        requestQuote: 'Be om pristilbud',
        orderBrochure: 'Bestill brosjyre'
      },
      goTo: {
        title: 'Gå til',
        norwegianCoastalExpress: 'Hurtigruten',
        HRG: 'Hurtigruten Group'
      },
      termsAndConditions: 'Vilkår og betingelser',
      privacyStatement: 'Personvernerklæring',
      cookiePolicy: 'Bruk av informasjonskapsler',
      copyright: '© 2024 Hurtigruten Expeditions',
      contact: {
        title: 'Kontakt'
      },
      unableToLoadContact: 'Kunne ikke laste inn kontaktinformasjon, prøv igjen senere.',
      changeCountry: 'Endre land',
      managePreferences: 'Endre preferanser',
      hurtigrutenExpeditions: 'HX Hurtigruten Expeditions',
      norwegianCoastalExpress: 'Hurtigruten',
      expeditionCruises: 'Ekspedisjonscruise',
      northernLightsCruise: 'Nordlyscruise',
      title: 'Bunntekst'
    }
  },
  'en': {
    footer: {
      campaign: {
        title: 'Sign up for our newsletter',
        text: 'Receive special offers, exciting new itineraries and fascinating articles delivered straight to your inbox.',
        button: 'Sign up'
      },
      followUs: 'Follow us',
      about: {
        title: 'About',
        aboutHRG: 'About Hurtigruten Group',
        ambassadorProgramme: 'HX Explorers Loyalty Programme',
        sustainability: 'Sustainability',
        careers: 'Careers'
      },
      support: {
        title: 'Support',
        covidInfo: 'Covid-19: Updated information',
        faq: 'FAQ',
        contactUs: 'Contact us',
        requestQuote: 'Request a quote',
        orderBrochure: 'Order brochure'
      },
      goTo: {
        title: 'Go to',
        norwegianCoastalExpress: 'Hurtigruten',
        HRG: 'Hurtigruten Group'
      },
      termsAndConditions: 'Terms and conditions',
      privacyStatement: 'Privacy statement',
      cookiePolicy: 'Cookie policy',
      copyright: '© 2024 Hurtigruten Expeditions',
      contact: {
        title: 'Contact'
      },
      unableToLoadContact: 'Unable to load contact information, please try again later.',
      changeCountry: 'Change country',
      managePreferences: 'Manage preferences',
      hurtigrutenExpeditions: 'HX Hurtigruten Expeditions',
      norwegianCoastalExpress: 'Hurtigruten',
      expeditionCruises: 'Expedition Cruises',
      northernLightsCruise: 'Northern Lights Cruise',
      title: 'Footer'
    }
  }
}

export type TFooter = {
  footer: {
    campaign: {
      title: string;
      text: string;
      button: string;
    };
    followUs: string;
    about: {
      title: string;
      aboutHRG: string;
      ambassadorProgramme: string;
      sustainability: string;
      careers: string;
    };
    support: {
      title: string;
      covidInfo: string;
      faq: string;
      contactUs: string;
      requestQuote: string;
      orderBrochure: string;
    };
    goTo: {
      title: string;
      norwegianCoastalExpress: string;
      HRG: string;
    };
    termsAndConditions: string;
    privacyStatement: string;
    cookiePolicy: string;
    copyright: string;
    contact: {
      title: string;
    };
    unableToLoadContact: string;
    changeCountry: string;
    managePreferences: string;
    hurtigrutenExpeditions: string;
    norwegianCoastalExpress: string;
    expeditionCruises: string;
    northernLightsCruise: string;
    title: string;
  };
}
