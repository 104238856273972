import React, { createContext, ReactNode, useState } from 'react';

import { TUserProfile } from '@src/types/auth';

type TUserProviderProps = {
  children: ReactNode;
};

type TStateContext = {
  user: TUserProfile | Record<string, string>;
  setUser: (user: TUserProfile) => void;
};

type TExpeditionsContext = {
  expeditions: ViewModel.Booking.ExpeditionView[] | [];
  setExpeditions: (
    expeditions: ViewModel.Booking.ExpeditionView[] | []
  ) => void;
};

// eslint-disable-next-line @typescript-eslint/naming-convention
export const UserContext = createContext({} as TStateContext);
export const ExpeditionsContext = createContext({} as TExpeditionsContext);

export function UserProvider(props: TUserProviderProps) {
  const [state, setState] = useState({});

  return (
    <UserContext.Provider value={{ user: state, setUser: setState }}>
      {props.children}
    </UserContext.Provider>
  );
}

export function UserExpeditionsProvider(props: TUserProviderProps) {
  const [state, setState] = useState<ViewModel.Booking.ExpeditionView[]>([]);

  return (
    <ExpeditionsContext.Provider
      value={{ expeditions: state, setExpeditions: setState }}
    >
      {props.children}
    </ExpeditionsContext.Provider>
  );
}
