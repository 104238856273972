import { useEffect, useState } from 'react';
import Script from 'next/script';

import { isFeatureEnabled } from '@utils';
import { getSharedEnvironmentVariables } from '@src/utils/environmentVariables';

const CrimtanConsentScripts = () => {
  const [numLoadedScripts, setNumLoadedScripts] = useState(0);
  const [shouldLoadScripts, setShouldLoadScripts] = useState(false);
  const sharedEnvironmentVariables = getSharedEnvironmentVariables();
  const isStaging = sharedEnvironmentVariables.WEBSITE_URL?.includes('staging');
  const isProd = process?.env?.NODE_ENV === 'production'; // also includes staging

  /* TO TEST:
   * type consenTag into the console and it should bring up the object
   * if initialised it should then have initParams with the relevant containerID
   */

  useEffect(() => {
    if (numLoadedScripts === 3) {
      try {
        // eslint-disable-next-line max-len
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call, no-underscore-dangle
        window.__tcfapi(
          'getTCData',
          2,
          (tcData: { tcString: any }, success: any) => {
            if (success) {
              // eslint-disable-next-line max-len
              // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-call
              window.consenTag.init(
                { containerId: '82988678', silentMode: true },
                tcData.tcString
              );
            } else {
              // eslint-disable-next-line max-len
              // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-call
              window.consenTag.init(
                { containerId: '82988678', silentMode: true },
                false
              );
            }
          }
        );
      } catch (e) {
        console.error(e);
      }
    }
  }, [numLoadedScripts]);

  useEffect(() => {
    let timeout: NodeJS.Timeout | undefined = undefined;
    if (!shouldLoadScripts) {
      const hasConsents =
        /^(.*;)?\s*OptanonConsent\s*=\s*[^;]+(.*)?$/.exec(document.cookie) !=
        null;

      if (hasConsents) {
        timeout = setTimeout(() => setShouldLoadScripts(true), 3500);
      } else {
        setShouldLoadScripts(true);
      }
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [shouldLoadScripts]);

  if (!isFeatureEnabled('isCrimtanEnabled') || !shouldLoadScripts) {
    return null;
  }

  let oneTrustScriptId;

  if (isStaging) {
    oneTrustScriptId = '018ed292-213e-7995-aa5d-a2e42337268f-test';
  }

  // isProd catches both prod and staging so need to be very specific here
  if (isProd && !isStaging) {
    oneTrustScriptId = '018ed292-213e-7995-aa5d-a2e42337268f';
  }

  return (
    <>
      {isProd && (
        <>
          <Script
            strategy="lazyOnload"
            src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
            type="text/javascript"
            data-domain-script={oneTrustScriptId}
            defer
            onLoad={() => {
              setNumLoadedScripts((prev) => prev + 1);
            }}
          ></Script>
          <Script
            strategy="lazyOnload"
            src="https://cdn.cookielaw.org/consent/tcf.stub.js"
            defer
            type="text/javascript"
            onLoad={() => {
              setNumLoadedScripts((prev) => prev + 1);
            }}
          ></Script>
          <Script
            strategy="lazyOnload"
            src="/scripts/consentTag-sept-2024.js"
            defer
            onLoad={() => {
              setNumLoadedScripts((prev) => prev + 1);
            }}
          ></Script>
        </>
      )}
    </>
  );
};

export default CrimtanConsentScripts;
