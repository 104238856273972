const addOptinMonsterScript = () => {
  const s = document.createElement('script');
  s.type = 'text/javascript';
  s.src = 'https://a.omappapi.com/app/js/api.min.js';
  s.async = true;
  s.dataset.user = '328257';
  s.dataset.account = '347669';
  document.getElementsByTagName('head')[0].appendChild(s);
};

export default addOptinMonsterScript;
