// import { TCheckoutStepEvent } from '@src/types/analytics/event';

const ITEM_BRAND = 'Expedition';

export enum EVENT_TYPES {
  viewItem = 'view_item',
  viewItemList = 'view_item_list',
  selectItem = 'select_item',
  error = 'error',
  loyaltySignup = 'loyalty_signup',
  checkoutStep = 'checkout_step',
  purchase = 'purchase',
  addToCart = 'add_to_cart',
  removeFromCart = 'remove_from_cart',
  virtualPageView = 'virtual_page_view',
  userInfo = 'user_info',
  sendToPayment = 'save_to_firestore'
}

export enum ERROR_TYPES {
  pgError = 'pg_error',
  appError = 'application_error',
  cabinSelection = 'cabin_selection'
}

export enum BOOKING_STEPS {
  DepartureConfirm = 'Departure Confirm',
  Cabin = 'Cabin Selection',
  CabinSelected = 'Cabin Selected',
  FlightsAndExtras = 'Flights and Extras',
  PassengerInformation = 'Passenger Information',
  PassengerInformationSubmitted = 'Passenger Information Submitted',
  Checkout = 'Checkout',
  TermsAccepted = 'Accept Payment T&Cs',
  Payment = 'Payment',
  PaymentFailure = 'Payment Failure'
}

const bookingSteps: { name: string; sequence: number }[] = [
  { name: BOOKING_STEPS.DepartureConfirm, sequence: 1 },
  { name: BOOKING_STEPS.Cabin, sequence: 2 },
  { name: BOOKING_STEPS.CabinSelected, sequence: 3 },
  { name: BOOKING_STEPS.FlightsAndExtras, sequence: 4 },
  { name: BOOKING_STEPS.PassengerInformation, sequence: 5 },
  { name: BOOKING_STEPS.PassengerInformationSubmitted, sequence: 6 },
  { name: BOOKING_STEPS.Checkout, sequence: 7 },
  { name: BOOKING_STEPS.TermsAccepted, sequence: 8 },
  { name: BOOKING_STEPS.Payment, sequence: 9 },
  { name: BOOKING_STEPS.PaymentFailure, sequence: 10 }
];

export const createLoyaltySignupEvent = () => ({
  event: EVENT_TYPES.loyaltySignup
});

export const createUserinfoEvent = (hash: string) => ({
  event: EVENT_TYPES.userInfo,
  sha256_email: hash
});

export const createVirtualPageView = ({
  locale,
  pageType
}: {
  locale: TLocale;
  pageType?: string;
}) => ({
  event: EVENT_TYPES.virtualPageView,
  channel: ITEM_BRAND,
  pageType,
  locale
});

export const createBookingStepEvent = (
  bookingStep: BOOKING_STEPS
): Analytics.Events.TCheckoutStepEvent => {
  const step = bookingSteps.find((s) => s.name === bookingStep);
  return {
    event: EVENT_TYPES.checkoutStep,
    step_number: step?.sequence ?? null,
    step_name: step?.name ?? null
  };
};

/* TODO: figure out what we want to track errorwise */
export const createErrorEvent = (
  errorType: ERROR_TYPES,
  errorMessage: string
): Analytics.Events.TErrorEvent => ({
  event: EVENT_TYPES.error,
  errorMessage,
  errorType
});

export const createViewVoyageListEvent = (
  item_list_id: string,
  item_list_name: string,
  items: Analytics.Events.TVoyageItem[] | Analytics.Events.TExtraItem[]
): Analytics.Events.TViewItemListEvent => ({
  event: EVENT_TYPES.viewItemList,
  ecommerce: {
    item_list_id,
    item_list_name,
    items
  }
});

export const createSelectVoyageFromListEvent = (
  item_list_id: string,
  item_list_name: string,
  item: Analytics.Events.TVoyageItem
): Analytics.Events.TViewItemListEvent => ({
  event: EVENT_TYPES.selectItem,
  ecommerce: {
    item_list_id,
    item_list_name,
    items: [item]
  }
});

export const createViewSingleVoyageEvent = (
  currency: string,
  item: Analytics.Events.TVoyageItem
) => ({
  event: EVENT_TYPES.viewItem,
  ecommerce: {
    currency,
    value: item.price ?? 0,
    items: [item]
  }
});

export const createAddVoyageToCartEvent = (
  currency: string,
  totalPrice: number,
  items: Analytics.Events.TVoyageItem[]
) => ({
  event: EVENT_TYPES.addToCart,
  ecommerce: {
    currency,
    value: totalPrice,
    items
  }
});

export const createAddExtraItemToCartEvent = (
  currency: string,
  totalPrice: number,
  items: Analytics.Events.TExtraItem[]
) => ({
  event: EVENT_TYPES.addToCart,
  ecommerce: {
    currency,
    value: totalPrice,
    items
  }
});

export const createRemoveExtraItemToCartEvent = (
  currency: string,
  totalPrice: number,
  items: Analytics.Events.TExtraItem[]
) => ({
  event: EVENT_TYPES.removeFromCart,
  ecommerce: {
    currency,
    value: totalPrice,
    items
  }
});

/* This is more of a backup */
export const createPurchaseEvent = (
  currency: string,
  totalPrice: number,
  transactionId: string,
  items: Array<Analytics.Events.TExtraItem | Analytics.Events.TVoyageItem>
) => ({
  event: EVENT_TYPES.purchase,
  transactionId,
  ecommerce: {
    currency,
    value: totalPrice,
    transaction_id: transactionId,
    items
  }
});

/* track the correlationId and paymentId that gets
sent to payment portal */
export const createSendToPaymentEvent = (
  currency: string,
  totalPrice: number,
  correlationId: string,
  paymentId: string,
  items: Array<Analytics.Events.TExtraItem | Analytics.Events.TVoyageItem>
) => ({
  event: EVENT_TYPES.sendToPayment,
  correlationId,
  paymentId,
  ecommerce: {
    currency,
    value: totalPrice,
    items
  }
});

export const buildEcommerceItem = ({
  id,
  name,
  itemCategory,
  itemCategory2,
  price,
  itemVariant,
  quantity,
  departureDate,
  arrivalDate,
  daysUntilDeparture,
  duration,
  bookingCode,
  shipName,
  discount,
  coupon,
  included,
  destination
}: {
  id: string;
  name: string;
  itemCategory: 'Voyage' | 'Excursion' | 'Arr/Dep Package' | 'Cabin';
  itemCategory2?: string;
  price: number;
  itemVariant?: string;
  quantity?: number;
  departureDate?: string;
  arrivalDate?: string;
  daysUntilDeparture?: number;
  duration?: number | null;
  bookingCode?: string;
  shipName?: string;
  discount?: number;
  coupon?: string;
  included?: boolean;
  destination?: string;
}) => {
  const item: { [key: string]: any } = {
    item_id: id,
    item_name: name,
    item_brand: ITEM_BRAND,
    item_category: itemCategory,
    price
  };

  if (itemCategory2) {
    item.itemCategory2 = itemCategory2;
  }

  if (destination) {
    item.destination = destination;
  }

  if (coupon) {
    item.coupon = coupon;
  }

  if (discount) {
    item.discount = discount;
  }

  if (bookingCode) {
    item.bookingCode = bookingCode;
  }

  if (itemVariant) {
    item.item_variant = itemVariant;
  }

  if (quantity) {
    item.quantity = quantity;
  }

  if (departureDate) {
    item.departureDate = departureDate;
  }

  if (arrivalDate) {
    item.arrivalDate = arrivalDate;
  }

  if (daysUntilDeparture) {
    item.daysUntilDeparture = daysUntilDeparture;
  }

  if (duration) {
    item.duration = duration;
  }

  if (shipName) {
    item.shipName = shipName;
  }

  if (included) {
    item.included = included.toString();
  }

  return item;
};
