import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import clsx from 'clsx';

import {
  TQuoteMetaInformation,
  TQuoteMetaPreviewInformation
} from '@src/types/quoteMetaInformation';
import { breakpoints, isFeatureEnabled } from '@utils';
import { useIsBookingFlow, useMediaQuery, useTranslate } from '@hooks';
import { Button } from '@atoms';
import { ButtonModes } from '@constants';
import { ArrowDownSLine, ArrowUpSLine } from '@icons/System';
import { appbar, common } from '@microcopies';
import { Deeplink } from '@utils/deeplink';
import { get, post } from '@utils/http';

import BookingInProgressPreview from './BookingInProgressPreview';

const BookingInProgressPreviewList = ({
  items,
  loadingId,
  closePreview,
  onItemClick
}: {
  items: TQuoteMetaInformation[];
  loadingId: string | null;
  closePreview: (quoteId: string) => void;
  onItemClick: (item: TQuoteMetaInformation) => void;
}) => {
  const router = useRouter();
  const [showAll, setShowAll] = useState(false);

  const translate = useTranslate(appbar, (x) => x.appbar);
  const translateCommon = useTranslate(common, (x) => x.common);

  const isMobile = useMediaQuery(breakpoints.tablet, true);
  const defaultItemsCount = isMobile ? 1 : 2;

  const isBookingFlow = useIsBookingFlow({
    includePaymentResponse: true,
    includeExpired: true,
    includeConfirmation: true
  });

  const [deeplinkPreview, setDeeplinkPreview] =
    useState<TQuoteMetaPreviewInformation | null>(null);
  const [deeplink, setDeeplink] = useState<Deeplink | null>(null);
  useEffect(() => {
    if (items.length === 0) {
      void (async () => {
        const json = window.localStorage.getItem('lastStartedBookingDeeplink');
        if (!json) {
          return;
        }
        const {
          deeplink: dl,
          departureDate,
          duration
        } = JSON.parse(json) as {
          deeplink: Deeplink;
          departureDate: string;
          duration: number;
        };
        let previewInfo: null | Pick<
          TQuoteMetaPreviewInformation,
          'voyageName' | 'imageSrc'
        > = null;

        try {
          previewInfo = await get<
            Pick<TQuoteMetaPreviewInformation, 'voyageName' | 'imageSrc'>
          >()(
            `/api/content/voyages/previewInfo/?id=${dl.search.voyageId}&locale=${dl.locale}`
          );
        } catch (err) {
          console.warn('[BookingInProgress]: Failed to find voyage.');
          return;
        }

        setDeeplinkPreview({
          voyageName: previewInfo.voyageName,
          imageSrc: previewInfo.imageSrc,
          departureDate,
          duration,
          currency: null,
          price: null,
          strikeThroughPrice: null
        });
        setDeeplink(dl);
      })();
    }
  }, []);

  const showContinueBookingPreview = isFeatureEnabled(
    'showContinueBookingPreview'
  );

  if (!showContinueBookingPreview || isBookingFlow) {
    return null;
  }

  const hasMore = items.length > 1;

  return (
    <div
      data-testid="booking-in-progress-preview-list"
      className={clsx(
        'absolute drop-shadow-card-shadow top-[200px] m-3 z-100 tablet:min-w-[500px] max-w-[500px] space-y-4 flex flex-col mobileLandscape:left-0 tablet:right-[20px] tablet:left-auto',
        {
          'bg-white rounded-6xl': hasMore,
          'py-6 px-3': hasMore && isMobile,
          'p-6': hasMore && !isMobile
        }
      )}
    >
      {items.length > 1 && (
        <h2 className="overline-text">
          {translate((x) => x.bookingsInProgress.title)}
        </h2>
      )}
      <div className="space-y-3">
        {deeplinkPreview && items.length === 0 && (
          <BookingInProgressPreview
            key="deeplinkitem"
            holdExpires={0}
            preview={deeplinkPreview}
            isLoading={false}
            onClose={() => {
              setDeeplinkPreview(null);
              window.localStorage.removeItem('lastStartedBookingDeeplink');
            }}
            onClick={async () => {
              const { encoded } = await post<{ encoded: string }>(deeplink)(
                '/api/deeplink/encode/'
              );
              await router.push(`/dl/${encoded}/`);
            }}
          />
        )}
        {(showAll ? items : items.slice(0, defaultItemsCount)).map((item) => (
          <BookingInProgressPreview
            key={item.quoteId}
            holdExpires={item.holdExpires}
            preview={item.preview}
            isLoading={item.quoteId === loadingId}
            onClose={() => closePreview(item.quoteId)}
            onClick={() => onItemClick(item)}
          />
        ))}
      </div>
      {items.length > defaultItemsCount && (
        <Button
          onClick={() => {
            setShowAll(!showAll);
          }}
          mode={ButtonModes.none}
          icon={showAll ? ArrowUpSLine : ArrowDownSLine}
          className="self-end mt-3 tablet:mt-8"
          iconPosition="right"
          size={'small'}
        >
          {translateCommon((x) => (showAll ? x.showLess : x.showMore))}
        </Button>
      )}
    </div>
  );
};

export default BookingInProgressPreviewList;
