import clsx from 'clsx';

import { ContentfulImage } from '@atoms';
import { HeaderRibbon as HeaderRibbonData } from '@content/gql/graphql';
import { useMediaQuery } from '@hooks';
import { breakpoints } from '@src/utils';

const HeaderRibbon = ({
  text,
  logo,
  link,
  isNewDestinationPage
}: {
  text: string;
  logo?: HeaderRibbonData['logo'];
  link?: HeaderRibbonData['link'];
  isNewDestinationPage: boolean;
}) => {
  const isDesktop = useMediaQuery(breakpoints.tabletLandscape);
  return (
    <div
      data-testid="HeaderRibbon"
      className={clsx(
        'h-[40px] flex  items-center px-8 laptop:px-12 bg-hx-neutrals-10 z-50 relative',
        {
          'justify-between': isDesktop,
          'justify-center': !isDesktop,
          'bg-hxv-primary-sand-30': isNewDestinationPage
        }
      )}
    >
      <div className="flex ">
        <div className="uppercase text-2xs text-[10px]">{text}</div>
        {logo && (
          <div className="h-[15px] w-[128px] ml-2">
            <ContentfulImage
              layout="responsive"
              src={logo.url}
              height={15}
              width={128}
              alt={logo.alt ?? ''}
            />
          </div>
        )}
      </div>

      {link && isDesktop && (
        <div className="flex items-center ">
          <a
            href={link.url}
            target="_blank"
            rel="noopener noreferrer"
            className="text-2xs underline underline-offset-1 text-[11px]"
          >
            {link.label}
          </a>
        </div>
      )}
    </div>
  );
};

export default HeaderRibbon;
