import React from 'react';
import clsx from 'clsx';

const CenteredSpinner = ({
  absolute = false,
  transparent = false
}: {
  absolute?: boolean;
  transparent?: boolean;
}) => (
  <div
    data-testid="centered-spinner"
    className={clsx(
      'inset-0 flex flex-col justify-center items-center z-[250] bg-black bg-opacity-40',
      {
        'fixed h-screen w-screen': !absolute,
        absolute,
        'bg-transparent': transparent
      }
    )}
  >
    <div className="spinner"></div>
  </div>
);

export default CenteredSpinner;
