import { searchCruisesButtonReducer } from './searchCruisesButtonReducer';
import { guestReducer } from './guestReducer';
import { stickyBarReducer } from './stickyBarReducer';

// A root-level reducer to capture all dispatched actions within the application
export default function rootReducer(
  state: IApplicationState,
  action: IStateAction
): IApplicationState {
  const { guest } = state;

  return {
    guest: guest ? guestReducer(guest, action as TGuestAction) : undefined,
    hideSearchCruisesButton: searchCruisesButtonReducer(action),
    showStickyBar: stickyBarReducer(action)
  };
}
