import { mapLocaleToContenfulFormat } from '@utils';

import useLocale from './useLocale';

export type TTranslator<T> = (
  select: (microcopies: T) => string,
  vars?: Record<string, string> | undefined
) => string;

function useTranslate<TSet, TPreselected>(
  set: Record<string, TSet>,
  preselect: (microcopies: TSet) => TPreselected = (x) =>
    x as unknown as TPreselected
) {
  const locale = useLocale();
  const lang = set[mapLocaleToContenfulFormat(locale)];
  if (!lang) {
    throw new Error(`Locale '${locale}' not found`);
  }
  const preselected = preselect(lang);
  const translate = (
    select: (microcopies: TPreselected) => string,
    vars?: Record<string, string>
  ) => {
    let str = select(preselected);
    if (vars && str) {
      Object.keys(vars).forEach((key) => {
        str = str.replace(new RegExp(`\\{${key}\\}`, 'g'), vars[key]);
      });
    }
    return str;
  };
  return translate;
}

export default useTranslate;
