import { overridenCountriesToLocaleMap } from '@src/hooks/useLocaleHelpers';

const countryCodes = overridenCountriesToLocaleMap.map((c) => c.countryCode);

export const validWebsiteCountryCodes = [
  'AU',
  'GB',
  'US',
  'CH',
  'DE',
  'DK',
  'FR',
  'NO',
  'SE',
  ...countryCodes
];
