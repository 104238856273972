import clsx from 'clsx';
import { ForwardedRef, forwardRef } from 'react';
import NextLink from 'next/link';

import { ButtonModes } from '@constants';
import useLocale from '@hooks/useLocale';
import { externalLinkMixin } from '@utils';

import Icon from './Icon';

const Button = forwardRef(
  (
    {
      size = 'large',
      children,
      className,
      isDisabled,
      type = 'button',
      mode = ButtonModes.primary,
      iconPosition = 'left',
      iconSize = 'xl',
      icon,
      href,
      rel,
      shallow,
      iconFill,
      isLoading,
      isStretchedMobile,
      ...rest
    }: IButtonProps,
    ref
  ): JSX.Element => {
    const locale = useLocale();
    const classes = clsx('btn relative', {
      'h-[56px] px-4': size === 'large',
      'h-[40px] px-4': size === 'small',
      'h-10 w-10 p-0 !min-w-0': mode === ButtonModes.icon,
      'btn-flat': mode === ButtonModes.flat,
      'btn-flat white': mode === ButtonModes.flatWhite,
      'btn-primary': mode === ButtonModes.primary,
      'btn-secondary': mode === ButtonModes.secondary,
      'btn-secondary-black': mode === ButtonModes.secondaryBlack,
      'btn-secondary-white': mode === ButtonModes.secondaryWhite,
      'btn-secondary btn-secondary-warmgray-2':
        mode === ButtonModes.secondaryWarmGray2,
      'btn-menu-link': mode === ButtonModes.menuLink,
      noDecorate: href,
      [className || '']: className,
      'min-w-0 w-full tablet:w-fit': isStretchedMobile
    });
    const isFlat = [
      ButtonModes.flat,
      ButtonModes.flatBlack,
      ButtonModes.flatWhite
    ].includes(mode);

    const buttonChildren = (
      <div className="flex items-end">
        {icon && iconPosition === 'left' && (
          <Icon
            className="mr-4 btn-secondary btn-secondary-warmgray-2"
            data-testid="button-icon"
            icon={icon}
            fill={iconFill}
            size={iconSize}
          />
        )}
        {children}
        {icon && iconPosition === 'right' && (
          <Icon
            className="ml-4"
            data-testid="button-icon"
            icon={icon}
            fill={iconFill}
            size={iconSize}
          />
        )}
      </div>
    );

    if (href && !isDisabled) {
      const { 'aria-label': ariaLabel } = rest;
      return (
        <NextLink
          shallow={shallow}
          locale={locale}
          href={href}
          prefetch={false}
        >
          <a
            onClick={(event) => {
              const proceed = rest.onClick?.(event as any);
              if (proceed !== undefined) {
                return proceed;
              }
              return true;
            }}
            data-testid="button"
            className={classes}
            ref={ref as ForwardedRef<HTMLAnchorElement>}
            aria-label={ariaLabel}
            rel={rel}
            {...externalLinkMixin(href, rel)}
          >
            {buttonChildren}
          </a>
        </NextLink>
      );
    }

    return (
      <button
        data-testid="button"
        className={classes}
        ref={ref as ForwardedRef<HTMLButtonElement>}
        type={type}
        disabled={isDisabled}
        {...rest}
      >
        {isLoading && isFlat && buttonChildren}
        {isLoading ? <span className="btn-spinner"></span> : buttonChildren}
      </button>
    );
  }
);

Button.displayName = 'Button';
export default Button;
