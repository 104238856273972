enum BookingSteps {
  'Departure' = 1,
  'Cabin' = 2,
  'CabinLocation' = 3,
  'Extras' = 4,
  'Personalia' = 5,
  'ExtrasFlightsOptions' = 6,
  'Checkout' = 7,
  'Payment' = 8
}

export default BookingSteps;
