import { useTranslate } from '@hooks';
import { country, TCountry } from '@microcopies/sets/country';
import { getLiveLocales } from '@constants/locales';
import { notEmpty } from '@utils/typeGuards/shared';

export interface IWebsite {
  countryCode: TCountryCode;
  locale: TLocale | null;
  name: string;
}

export type TCountryCode = keyof TCountry['country'];

export const overridenCountriesToLocaleMap: IWebsite[] = [
  { countryCode: 'CA', name: 'Canada', locale: 'en-us' },
  { countryCode: 'AR', name: 'Argentina', locale: 'en-us' },
  {
    countryCode: 'BO',
    name: 'Bolivia (Plurinational State of)',
    locale: 'en-us'
  },
  { countryCode: 'BR', name: 'Brazil', locale: 'en-us' },
  { countryCode: 'CL', name: 'Chile', locale: 'en-us' },
  { countryCode: 'CO', name: 'Colombia', locale: 'en-us' },
  { countryCode: 'EC', name: 'Ecuador', locale: 'en-us' },
  { countryCode: 'GY', name: 'Guyana', locale: 'en-us' },
  { countryCode: 'PY', name: 'Paraguay', locale: 'en-us' },
  { countryCode: 'PE', name: 'Peru', locale: 'en-us' },
  { countryCode: 'SR', name: 'Suriname', locale: 'en-us' },
  { countryCode: 'UY', name: 'Uruguay', locale: 'en-us' },
  {
    countryCode: 'VE',
    name: 'Venezuela (Bolivarian Republic of)',
    locale: 'en-us'
  },
  { countryCode: 'BZ', name: 'Belize', locale: 'en-us' },
  { countryCode: 'CR', name: 'Costa Rica', locale: 'en-us' },
  { countryCode: 'SV', name: 'El Salvador', locale: 'en-us' },
  { countryCode: 'GT', name: 'Guatemala', locale: 'en-us' },
  { countryCode: 'HN', name: 'Honduras', locale: 'en-us' },
  { countryCode: 'NI', name: 'Nicaragua', locale: 'en-us' },
  { countryCode: 'PA', name: 'Panama', locale: 'en-us' },
  { countryCode: 'MX', name: 'Mexico', locale: 'en-us' }
];

export const useLocaleHelpers = () => {
  const translateCountry = useTranslate(country, (x) => x.country);

  const selectorCountriesToLocaleMap = getLiveLocales()
    .map((locale) => {
      const countryCode = locale.slice(-2).toUpperCase() as TCountryCode;
      return {
        locale,
        countryCode,
        name: translateCountry((x) => x[countryCode])
      };
    })
    .filter(notEmpty) as IWebsite[];

  const allCountriesToLocaleMap: IWebsite[] = [
    ...selectorCountriesToLocaleMap,
    ...overridenCountriesToLocaleMap
  ];

  const getSelectorCountryFromLocale = (locale: TLocale) =>
    selectorCountriesToLocaleMap.find((c) => c.locale === locale);

  const getCountryFromCountryCode = (countryCode: TCountryCode) =>
    allCountriesToLocaleMap.find((c) => c.countryCode === countryCode);

  return {
    selectorCountriesToLocaleMap,
    overridenCountriesToLocaleMap,
    allCountriesToLocaleMap,
    getCountryFromCountryCode,
    getSelectorCountryFromLocale
  };
};
