import Head from 'next/head';

import { getSharedEnvironmentVariables } from '@src/utils/environmentVariables';

export const MetaRobots = ({
  shouldFollow = true,
  shouldIndex = true
}: {
  shouldFollow: boolean;
  shouldIndex: boolean;
}) => {
  const sharedEnvironmentVariables = getSharedEnvironmentVariables();
  const isStaging =
    sharedEnvironmentVariables.WEBSITE_URL ===
    'https://com.staging.hurtigruten.com/';

  let follow = shouldFollow ? 'follow' : 'nofollow';
  let index = shouldIndex ? 'index' : 'noindex';

  if (isStaging) {
    index = 'noindex';
    follow = 'nofollow';
  }
  return (
    <Head>
      <meta name="robots" content={`${index},${follow}`} />
    </Head>
  );
};
