/* eslint-disable @typescript-eslint/no-use-before-define */
import { differenceInCalendarDays } from 'date-fns';

import { isCabin, isPackage, isQuote } from '@utils/typeGuards/quote';
import { notEmpty } from '@utils/typeGuards/shared';
import { formatPrice, createDateAsUTC, dateToString } from '@src/utils';

// Internal helpers

const getVoyageDetailsFromPackage = (
  packageDetails: PG.Response.Quote.TPackage
): PG.Response.Shared.TVoyageDetails | undefined | null => {
  const itinerary = packageDetails.packageItinerary.find(
    (itineraryItem) => itineraryItem.type === 'VOYAGE'
  );

  return itinerary && itinerary.voyageDetails;
};

const getVoyageDetailsFromQuote = (
  quote: PG.Response.Quote.TRootObject
): PG.Response.Shared.TVoyageDetails | undefined | null => {
  const packageDetails = getPackage(quote);

  return packageDetails
    ? getVoyageDetailsFromPackage(packageDetails)
    : undefined;
};

const getFlightsFromPackage = (
  packageDetails: PG.Response.Quote.TPackage
): PG.Response.Quote.TPackageItinerary[] =>
  packageDetails?.packageItinerary?.filter(
    (itinerary) => itinerary.type === 'FLIGHT'
  ) ?? [];

const getHotelsFromPackage = (
  packageDetails: PG.Response.Quote.TPackage
): PG.Response.Quote.TPackageItinerary[] =>
  packageDetails?.packageItinerary?.filter(
    (itinerary) => itinerary.type === 'HOTEL'
  ) ?? [];

// Exposed mappers

export const getPackage = (
  quote: PG.Response.Quote.TRootObject
): PG.Response.Quote.TPackage | undefined =>
  quote.packages &&
  quote.packages.find((packageItem) =>
    packageItem.packageItinerary.find(
      (itineraryItem) => itineraryItem.type === 'VOYAGE'
    )
  );

export const getArrivalDeparturePackages = (
  quote: PG.Response.Quote.TRootObject
): PG.Response.Quote.TPackage[] | undefined =>
  quote.packages &&
  quote.packages.filter((packageItem) =>
    packageItem.packageItinerary.find(
      (itineraryItem) =>
        itineraryItem.type === 'FLIGHT' || itineraryItem.type === 'TRANSFER'
    )
  );

export const getVoyageDetails = (
  from: PG.Response.Quote.TPackage | PG.Response.Quote.TRootObject
): PG.Response.Shared.TVoyageDetails | undefined | null => {
  if (isPackage(from)) {
    return getVoyageDetailsFromPackage(from);
  }

  if (isQuote(from)) {
    return getVoyageDetailsFromQuote(from);
  }

  return undefined;
};

export const getCabins = (
  from: PG.Response.Quote.TPackage | PG.Response.Quote.TRootObject
): PG.Response.Shared.TCabin[] | undefined | null => {
  const voyageDetails = getVoyageDetails(from);

  return voyageDetails && voyageDetails.cabins;
};

export const getPassengers = (
  quote: PG.Response.Quote.TRootObject
): PG.Response.Quote.TPassenger[] | undefined => quote.passengers;

export const getPassengersInCabin = (
  quote: PG.Response.Quote.TRootObject,
  cabin: PG.Response.Shared.TCabin
): PG.Response.Quote.TPassenger[] =>
  cabin.passengers
    .map(
      (cabinPassenger) =>
        quote.passengers &&
        quote.passengers.find(
          (passenger) => passenger.passengerId === cabinPassenger.passengerId
        )
    )
    .filter(notEmpty);

export const getPassengerById = (
  quote: PG.Response.Quote.TRootObject,
  passengerId: string
): PG.Response.Quote.TPassenger | undefined =>
  quote.passengers?.find((p) => p.passengerId === passengerId);

export const getExcursions = (
  from: PG.Response.Quote.TPackage | PG.Response.Quote.TRootObject
): PG.Response.Shared.TExcursion[] | undefined => {
  const voyageDetails = getVoyageDetails(from);

  return (voyageDetails && voyageDetails.excursions) ?? undefined;
};

export const getIncludedExcursions = (
  from: PG.Response.Quote.TPackage | PG.Response.Quote.TRootObject
): PG.Response.Shared.TExcursion[] | undefined => {
  const voyageDetails = getVoyageDetails(from);

  return (voyageDetails && voyageDetails.includedExcursions) ?? undefined;
};

export const getAddons = (
  from: PG.Response.Quote.TPackage | PG.Response.Quote.TRootObject
): PG.Response.Shared.TAddOn[] | undefined => {
  const voyageDetails = getVoyageDetails(from);

  return (voyageDetails && voyageDetails.addOns) ?? undefined;
};

export const getIncludedAddons = (
  from: PG.Response.Quote.TPackage | PG.Response.Quote.TRootObject
): PG.Response.Shared.TAddOn[] | undefined | null => {
  const voyageDetails = getVoyageDetails(from);

  return voyageDetails && voyageDetails.includedAddOns;
};

export const getTotalPrice = (quote: PG.Response.Quote.TRootObject): string =>
  formatPrice(quote.price, quote.currency);

export const getPrice = (
  quote: PG.Response.Quote.TRootObject,
  from: PG.Response.Shared.TCabin | PG.Response.Quote.TPassenger
): string => {
  if (isCabin(from)) {
    return formatPrice(from.includedPrice, quote.currency);
  }

  return formatPrice(from.price, quote.currency);
};

export const getStartDate = (
  quoteOrPackage: PG.Response.Quote.TPackage | PG.Response.Quote.TRootObject
): Date | undefined => {
  let packageDates: Date[] | undefined;

  if (isQuote(quoteOrPackage)) {
    const packageDetails = getPackage(quoteOrPackage);

    packageDates =
      packageDetails &&
      packageDetails.packageItinerary.map((itineraryItem) =>
        createDateAsUTC(itineraryItem.startDate)
      );
  } else {
    packageDates = quoteOrPackage.packageItinerary.map((itineraryItem) =>
      createDateAsUTC(itineraryItem.startDate)
    );
  }

  return (
    packageDates && packageDates.sort((a, b) => a.getTime() - b.getTime())[0]
  );
};

export const getFormattedStartDate = (
  quoteOrPackage: PG.Response.Quote.TPackage | PG.Response.Quote.TRootObject,
  locale: TLocale
): string | undefined => {
  const date = getStartDate(quoteOrPackage);
  return date ? dateToString(date, locale) : undefined;
};

export const getEndDate = (
  quoteOrPackage: PG.Response.Quote.TPackage | PG.Response.Quote.TRootObject
): Date | undefined => {
  let packageDates: Date[] | undefined;

  if (isQuote(quoteOrPackage)) {
    const packageDetails = getPackage(quoteOrPackage);

    packageDates =
      packageDetails &&
      packageDetails.packageItinerary.map((itineraryItem) =>
        createDateAsUTC(itineraryItem.endDate)
      );
  } else {
    packageDates = quoteOrPackage.packageItinerary.map((itineraryItem) =>
      createDateAsUTC(itineraryItem.endDate)
    );
  }

  const date =
    packageDates && packageDates.sort((a, b) => b.getTime() - a.getTime())[0];

  return date;
};

export const getFormattedEndDate = (
  quoteOrPackage: PG.Response.Quote.TPackage | PG.Response.Quote.TRootObject,
  locale: TLocale
): string | undefined => {
  const date = getEndDate(quoteOrPackage);
  return date ? dateToString(date, locale) : undefined;
};

export const getFormattedStartAndEndDate = (
  quoteOrPackage: PG.Response.Quote.TPackage | PG.Response.Quote.TRootObject,
  locale: TLocale
): string | undefined => {
  const startDate = getStartDate(quoteOrPackage);
  const endDate = getEndDate(quoteOrPackage);
  return startDate && endDate
    ? `${dateToString(startDate, locale)} - ${dateToString(endDate, locale)}`
    : undefined;
};

export const getDuration = (
  quoteOrPackage: PG.Response.Quote.TPackage | PG.Response.Quote.TRootObject
): number | undefined => {
  const startDate = getStartDate(quoteOrPackage);
  const endDate = getEndDate(quoteOrPackage);

  return startDate && endDate
    ? differenceInCalendarDays(endDate, startDate)
    : undefined;
};

export const getFlights = (
  quoteOrPackage: PG.Response.Quote.TPackage | PG.Response.Quote.TRootObject,
  includeFlightsFromAllPackages: boolean
) => {
  if (isQuote(quoteOrPackage)) {
    if (includeFlightsFromAllPackages) {
      quoteOrPackage.packages?.map(getFlightsFromPackage).flat();
    }

    const mainPackage = getPackage(quoteOrPackage);

    return mainPackage ? getFlightsFromPackage(mainPackage) : undefined;
  }

  return getFlightsFromPackage(quoteOrPackage);
};

export const getHotels = (
  quoteOrPackage: PG.Response.Quote.TPackage | PG.Response.Quote.TRootObject,
  includeHotelsFromAllPackages: boolean
) => {
  if (isQuote(quoteOrPackage)) {
    if (includeHotelsFromAllPackages) {
      quoteOrPackage.packages?.map(getHotelsFromPackage).flat();
    }

    const mainPackage = getPackage(quoteOrPackage);

    return mainPackage ? getHotelsFromPackage(mainPackage) : undefined;
  }

  return getHotelsFromPackage(quoteOrPackage);
};

export const getCabinIndex = (
  quoteCabin: PG.Response.Shared.TCabin,
  cabins?: Pick<
    ViewModel.Cabin.TRootObject | PG.Response.Cabin.TRootObject,
    'partyMixDetails'
  >
) => {
  if (!cabins) {
    return undefined;
  }

  return cabins.partyMixDetails.cabinsRequested?.findIndex((requestedCabin) =>
    quoteCabin.passengers.some(
      (p) => p.passengerId === requestedCabin.passengers[0].passengerId
    )
  );
};

export const getCabinByCabinIndex = (
  quote: PG.Response.Quote.TRootObject,
  cabinIndex: number,
  cabins?: ViewModel.Cabin.TRootObject | PG.Response.Cabin.TRootObject
) => {
  if (!cabins) {
    return undefined;
  }

  const quoteCabins = getCabins(quote);

  return quoteCabins?.find(
    (quoteCabin) => getCabinIndex(quoteCabin, cabins) === cabinIndex
  );
};

export const sortByCabinIndex = (
  quoteCabins: PG.Response.Shared.TCabin[] | null | undefined,
  cabins?: Pick<
    ViewModel.Cabin.TRootObject | PG.Response.Cabin.TRootObject,
    'partyMixDetails'
  >
) => {
  if (!cabins || !quoteCabins) {
    return quoteCabins;
  }

  return quoteCabins.sort(
    (a, b) =>
      (getCabinIndex(a, cabins) ?? -1) - (getCabinIndex(b, cabins) ?? -1)
  );
};

export const getArrivalPackages = (quote: PG.Response.Quote.TRootObject) =>
  quote.packages?.filter((pack) => pack.packageType === 'Arrival');

export const getDeparturePackages = (quote: PG.Response.Quote.TRootObject) =>
  quote.packages?.filter((pack) => pack.packageType === 'Departure');
