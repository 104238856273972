import clsx from 'clsx';
import { ForwardedRef, forwardRef } from 'react';

interface NavButtonProps extends IButtonProps {
  open?: boolean;
  active?: boolean;
}

const NavButton = forwardRef(
  (
    { children, active, open, className, ...rest }: NavButtonProps,
    ref
  ): JSX.Element => (
    <button
      data-testid="NavButton"
      className={clsx(
        'relative h-full text-black mx-1 tablet:mx-2 laptop:mx-[10px] my-0',
        'after:border-black after:h-0 after:w-0 after:bg-black after:absolute after:bottom-0 after:right-0 after:border-b after:transition-all after:duration-200 after:z-[101]',
        {
          'after:!left-0 after:!w-full after:delay-100': active,
          'text-hx-neutrals-70': open && !active
        },
        { [className || '']: className }
      )}
      ref={ref as ForwardedRef<HTMLButtonElement>}
      {...rest}
    >
      {children}
    </button>
  )
);

NavButton.displayName = 'NavButton';
export default NavButton;
