/* eslint-disable max-len */
// Auto generated file. Do not edit.

export const common: Record<string, TCommon> = {
  'en-AU': {
    common: {
      ship: 'Ship',
      loadMore: 'Load more',
      showLess: 'Show less',
      showMore: 'Show more',
      day: 'Day',
      travellers: 'Traveller(s)',
      cabins: 'Cabins',
      departure: 'Departure',
      duration: 'Duration',
      itinerary: 'Itinerary',
      days: 'days',
      readMore: 'Read more',
      unknownImage: 'Unknown image',
      sortBy: 'Sort',
      whatsIncluded: 'What\'s Included',
      knowBeforeYouGo: 'Know before you go',
      flights: 'Flights',
      hotel: 'Hotel',
      expeditionCruise: 'Expedition Cruise',
      destinations: 'Destinations',
      price: 'Price',
      topics: 'Topics',
      return: 'Return',
      select: 'Select',
      clear: 'Clear',
      done: 'Done',
      showAllDestinations: 'Show all destinations',
      showFeaturedDestinations: 'Show featured destinations',
      talkToUs: 'Talk to us',
      getBrochures: 'Get brochures',
      filter: 'Filter',
      sort: 'Sort',
      ambassador: 'Ambassador',
      email: 'Email',
      readingTime: '{readingTime} mins read',
      video: 'Video',
      loyalty: {
        tier: 'Loyalty tier',
        number: 'Loyalty number'
      },
      button: {
        viewDetails: 'View details',
        loadMoreCruises: 'Load more cruises',
        expand: 'Expand',
        seeAllStories: 'See all stories',
        collapse: 'Collapse',
        seeAllShips: 'See all ships',
        startExploring: 'Start exploring',
        seeMoreReasons: 'See more reasons',
        seeMoreCruises: 'See more cruises',
        seeMoreStories: 'See more stories',
        seeMore: 'See more',
        seeAllTopics: 'See all topics',
        seeLessTopics: 'See less topics',
        seeMoreActivities: 'See more',
        exploreAllDestinations: 'Explore all destinations',
        seeAllOffers: 'See complete list with offers',
        seeCollection: 'See collection'
      },
      close: 'Close',
      included: 'Included',
      priceFrom: 'Price from',
      activityLevel: 'Activity level',
      edit: 'Edit',
      ships: 'Ships',
      season: 'Season',
      bookingCode: 'Booking code',
      minNumber: 'Min. number',
      fromPrice: 'From {price}',
      when: 'When',
      departures: 'Departures',
      port: 'Port',
      lastMinute: 'Last minute',
      male: 'Male',
      female: 'Female',
      cancel: 'Cancel',
      continue: 'Continue',
      unavailable: 'Unavailable',
      cruises: 'Cruises',
      inspiration: 'Inspiration',
      offers: 'Offers',
      login: 'Login',
      logout: 'Logout',
      profile: 'Profile',
      perPerson: 'per person',
      search: 'Search',
      goTo: 'Go to',
      stories: 'Stories',
      bookings: 'Bookings',
      moreInformation: 'More information',
      shipsTo: 'Ships to',
      title: 'Title',
      mr: 'Mr',
      mrs: 'Mrs',
      miss: 'Miss',
      soldOut: 'Sold out',
      showOtherCruises: 'Explore other cruises',
      viewInMap: 'View in map',
      webcam: 'Webcam',
      unknownLocation: 'Unknown location',
      somethingWentWrong: 'Something went wrong',
      selectAll: 'Select all',
      multipleDestinations: 'Multiple Destinations',
      transfer: 'Transfer',
      nextDeparture: 'Next departure',
      imagePlaceholder: 'Image placeholder',
      from: 'From',
      free: 'Free',
      topTopics: 'Top topics',
      topicsSubtitle: 'View all stories that fit your interests',
      viewAllCruisesToDestination: 'View all cruises to {destination}',
      readLess: 'Read less',
      unknown: 'Unknown',
      excursionsAndActivities: 'Enhance your cruise',
      flightIncluded: 'Flight included',
      sortOptions: {
        featured: 'Featured',
        asc: 'A-Z',
        desc: 'Z-A'
      },
      filters: {
        of: 'of',
        xSelectedFilters: '{filters} selected filters',
        noResults: 'No results. Try adjusting your search by removing filters',
        advancedFilters: 'Advanced filters',
        filterBy: 'Filter by'
      },
      activities: 'activities',
      and: 'and',
      notFound404: '404 - Page Not Found',
      error500: '500 - Server-side error occurred',
      mostPopularDestinations: 'Most popular destinations',
      mostPopularTopics: 'Most popular topics',
      traveller: 'Traveller',
      lastEdited: 'Last edited',
      norwegianCoastalVoyage: 'Norwegian Coastal Voyage',
      download: 'Download',
      attempt: 'Attempt',
      overview: 'Overview',
      cruiseDestination: 'Your Expedition Cruise to {destination}',
      order: 'Order',
      offer: 'offer',
      nDaysLeft: '{n} days left',
      toTakeAdvantageOfOur: 'to take advantage of our',
      bestPriceGuarantee: 'best price guarantee',
      cookiePolicy: 'Cookie policy',
      managePreferences: 'Manage preferences',
      seeDetails: 'See details',
      anytime: 'Anytime',
      xMinuteRead: '{number} min read',
      years: 'years',
      discountOff: '~ {discountPercentage}% Off',
      total: 'Total',
      discounts: 'Discounts',
      time: 'time',
      to: 'to',
      selectForAllPassengers: 'Select for all passengers',
      remove: 'Remove',
      unknownError: 'Unknown error',
      availablePromotions: 'Available promotions',
      reserved: 'Reserved',
      success: 'Success',
      updated: 'Updated',
      removed: 'Removed',
      next: 'Next',
      previous: 'Previous',
      lastUpdated: 'Last updated',
      more: 'More',
      all: 'All',
      notIncluded: 'Not Included',
      addFrom: 'Add from',
      pleaseReserve: 'Please reserve',
      added: 'Added',
      ppPerPerson: 'pp',
      myaccount: 'My Account',
      optional: 'optional',
      addForFree: 'Add for free',
      apply: 'Apply',
      selectPreviousYear: 'Select previous year',
      selectNextYear: 'Select next year',
      returning: 'Returning',
      backToHome: 'Back to Home',
      scrollToTop: 'Scroll to top',
      home: 'Home',
      openMenu: 'Open menu',
      formUnavailable: 'The form is unfortunately not available, please get in touch with us via email or phone.',
      Cancelled: 'Cancelled',
      pause: 'Pause',
      mute: 'Mute',
      watchVideo: 'Watch video',
      save: 'Save',
      nonbinary: 'Non-binary'
    }
  },
  'en-GB': {
    common: {
      ship: 'Ship',
      loadMore: 'Load more',
      showLess: 'Show less',
      showMore: 'Show more',
      day: 'Day',
      travellers: 'Traveller(s)',
      cabins: 'Cabins',
      departure: 'Departure',
      duration: 'Duration',
      itinerary: 'Itinerary',
      days: 'days',
      readMore: 'Read more',
      unknownImage: 'Unknown image',
      sortBy: 'Sort',
      whatsIncluded: 'What\'s Included',
      knowBeforeYouGo: 'Know before you go',
      flights: 'Flights',
      hotel: 'Hotel',
      expeditionCruise: 'Expedition Cruise',
      destinations: 'Destinations',
      price: 'Price',
      topics: 'Topics',
      return: 'Return',
      select: 'Select',
      clear: 'Clear',
      done: 'Done',
      showAllDestinations: 'Show all destinations',
      showFeaturedDestinations: 'Show featured destinations',
      talkToUs: 'Talk to us',
      getBrochures: 'Get brochures',
      filter: 'Filter',
      sort: 'Sort',
      ambassador: 'Ambassador',
      email: 'Email',
      readingTime: '{readingTime} mins read',
      video: 'Video',
      loyalty: {
        tier: 'Loyalty tier',
        number: 'Loyalty number'
      },
      button: {
        viewDetails: 'View details',
        loadMoreCruises: 'Load more cruises',
        expand: 'Expand',
        seeAllStories: 'See all stories',
        collapse: 'Collapse',
        seeAllShips: 'See all ships',
        startExploring: 'Start exploring',
        seeMoreReasons: 'See more reasons',
        seeMoreCruises: 'See more cruises',
        seeMoreStories: 'See more stories',
        seeMore: 'See more',
        seeAllTopics: 'See all topics',
        seeLessTopics: 'See less topics',
        seeMoreActivities: 'See more',
        exploreAllDestinations: 'Explore all destinations',
        seeAllOffers: 'See complete list with offers',
        seeCollection: 'See collection'
      },
      close: 'Close',
      included: 'Included',
      priceFrom: 'Price from',
      activityLevel: 'Activity level',
      edit: 'Edit',
      ships: 'Ships',
      season: 'Season',
      bookingCode: 'Booking code',
      minNumber: 'Min. number',
      fromPrice: 'From {price}',
      when: 'When',
      departures: 'Departures',
      port: 'Port',
      lastMinute: 'Last minute',
      male: 'Male',
      female: 'Female',
      cancel: 'Cancel',
      continue: 'Continue',
      unavailable: 'Unavailable',
      cruises: 'Cruises',
      inspiration: 'Inspiration',
      offers: 'Offers',
      login: 'Login',
      logout: 'Logout',
      profile: 'Profile',
      perPerson: 'per person',
      search: 'Search',
      goTo: 'Go to',
      stories: 'Stories',
      bookings: 'Bookings',
      moreInformation: 'More information',
      shipsTo: 'Ships to',
      title: 'Title',
      mr: 'Mr',
      mrs: 'Mrs',
      miss: 'Miss',
      soldOut: 'Sold out',
      showOtherCruises: 'Explore other cruises',
      viewInMap: 'View in map',
      webcam: 'Webcam',
      unknownLocation: 'Unknown location',
      somethingWentWrong: 'Something went wrong',
      selectAll: 'Select all',
      multipleDestinations: 'Multiple Destinations',
      transfer: 'Transfer',
      nextDeparture: 'Next departure',
      imagePlaceholder: 'Image placeholder',
      from: 'From',
      free: 'Free',
      topTopics: 'Top topics',
      topicsSubtitle: 'View all stories that fit your interests',
      viewAllCruisesToDestination: 'View all cruises to {destination}',
      readLess: 'Read less',
      unknown: 'Unknown',
      excursionsAndActivities: 'Enhance your cruise',
      flightIncluded: 'Flight included',
      sortOptions: {
        featured: 'Featured',
        asc: 'A-Z',
        desc: 'Z-A'
      },
      filters: {
        of: 'of',
        xSelectedFilters: '{filters} selected filters',
        noResults: 'No results. Try adjusting your search by removing filters',
        advancedFilters: 'Advanced filters',
        filterBy: 'Filter by'
      },
      activities: 'activities',
      and: 'and',
      notFound404: '404 - Page Not Found',
      error500: '500 - Server-side error occurred',
      mostPopularDestinations: 'Most popular destinations',
      mostPopularTopics: 'Most popular topics',
      traveller: 'Traveller',
      lastEdited: 'Last edited',
      norwegianCoastalVoyage: 'Norwegian Coastal Voyage',
      download: 'Download',
      attempt: 'Attempt',
      overview: 'Overview',
      cruiseDestination: 'Your Expedition Cruise to {destination}',
      order: 'Order',
      offer: 'offer',
      nDaysLeft: '{n} days left',
      toTakeAdvantageOfOur: 'to take advantage of our',
      bestPriceGuarantee: 'best price guarantee',
      cookiePolicy: 'Cookie policy',
      managePreferences: 'Manage preferences',
      seeDetails: 'See details',
      anytime: 'Anytime',
      xMinuteRead: '{number} min read',
      years: 'years',
      discountOff: '~ {discountPercentage}% Off',
      total: 'Total',
      discounts: 'Discounts',
      time: 'time',
      to: 'to',
      selectForAllPassengers: 'Select for all passengers',
      remove: 'Remove',
      unknownError: 'Unknown error',
      availablePromotions: 'Available promotions',
      reserved: 'Reserved',
      success: 'Success',
      updated: 'Updated',
      removed: 'Removed',
      next: 'Next',
      previous: 'Previous',
      lastUpdated: 'Last updated',
      more: 'More',
      all: 'All',
      notIncluded: 'Not Included',
      addFrom: 'Add from',
      pleaseReserve: 'Please reserve',
      added: 'Added',
      ppPerPerson: 'pp',
      myaccount: 'My Account',
      optional: 'optional',
      addForFree: 'Add for free',
      apply: 'Apply',
      selectPreviousYear: 'Select previous year',
      selectNextYear: 'Select next year',
      returning: 'Returning',
      backToHome: 'Back to Home',
      scrollToTop: 'Scroll to top',
      home: 'Home',
      openMenu: 'Open menu',
      formUnavailable: 'The form is unfortunately not available, please get in touch with us via email or phone.',
      Cancelled: 'Cancelled',
      pause: 'Pause',
      mute: 'Mute',
      watchVideo: 'Watch video',
      save: 'Save',
      nonbinary: 'Non-binary'
    }
  },
  'en-US': {
    common: {
      ship: 'Ship',
      loadMore: 'Load more',
      showLess: 'Show less',
      showMore: 'Show more',
      day: 'Day',
      travellers: 'Traveller(s)',
      cabins: 'Cabins',
      departure: 'Departure',
      duration: 'Duration',
      itinerary: 'Itinerary',
      days: 'days',
      readMore: 'Read more',
      unknownImage: 'Unknown image',
      sortBy: 'Sort',
      whatsIncluded: 'What\'s Included',
      knowBeforeYouGo: 'Know before you go',
      flights: 'Flights',
      hotel: 'Hotel',
      expeditionCruise: 'Expedition Cruise',
      destinations: 'Destinations',
      price: 'Price',
      topics: 'Topics',
      return: 'Return',
      select: 'Select',
      clear: 'Clear',
      done: 'Done',
      showAllDestinations: 'Show all destinations',
      showFeaturedDestinations: 'Show featured destinations',
      talkToUs: 'Talk to us',
      getBrochures: 'Get brochures',
      filter: 'Filter',
      sort: 'Sort',
      ambassador: 'Ambassador',
      email: 'Email',
      readingTime: '{readingTime} mins read',
      video: 'Video',
      loyalty: {
        tier: 'Loyalty tier',
        number: 'Loyalty number'
      },
      button: {
        viewDetails: 'View details',
        loadMoreCruises: 'Load more cruises',
        expand: 'Expand',
        seeAllStories: 'See all stories',
        collapse: 'Collapse',
        seeAllShips: 'See all ships',
        startExploring: 'Start exploring',
        seeMoreReasons: 'See more reasons',
        seeMoreCruises: 'See more cruises',
        seeMoreStories: 'See more stories',
        seeMore: 'See more',
        seeAllTopics: 'See all topics',
        seeLessTopics: 'See less topics',
        seeMoreActivities: 'See more',
        exploreAllDestinations: 'Explore all destinations',
        seeAllOffers: 'See complete list with offers',
        seeCollection: 'See collection'
      },
      close: 'Close',
      included: 'Included',
      priceFrom: 'Price from',
      activityLevel: 'Activity level',
      edit: 'Edit',
      ships: 'Ships',
      season: 'Season',
      bookingCode: 'Booking code',
      minNumber: 'Min. number',
      fromPrice: 'From {price}',
      when: 'When',
      departures: 'Departures',
      port: 'Port',
      lastMinute: 'Last minute',
      male: 'Male',
      female: 'Female',
      cancel: 'Cancel',
      continue: 'Continue',
      unavailable: 'Unavailable',
      cruises: 'Cruises',
      inspiration: 'Inspiration',
      offers: 'Offers',
      login: 'Login',
      logout: 'Logout',
      profile: 'Profile',
      perPerson: 'per person',
      search: 'Search',
      goTo: 'Go to',
      stories: 'Inspiration',
      bookings: 'Bookings',
      moreInformation: 'More information',
      shipsTo: 'Ships to',
      title: 'Title',
      mr: 'Mr',
      mrs: 'Mrs',
      miss: 'Miss',
      soldOut: 'Sold out',
      showOtherCruises: 'Explore other cruises',
      viewInMap: 'View in map',
      webcam: 'Webcam',
      unknownLocation: 'Unknown location',
      somethingWentWrong: 'Something went wrong',
      selectAll: 'Select all',
      multipleDestinations: 'Multiple Destinations',
      transfer: 'Transfer',
      nextDeparture: 'Next departure',
      imagePlaceholder: 'Image placeholder',
      from: 'From',
      free: 'Free',
      topTopics: 'Top topics',
      topicsSubtitle: 'View all stories that fit your interests',
      viewAllCruisesToDestination: 'View all cruises to {destination}',
      readLess: 'Read less',
      unknown: 'Unknown',
      excursionsAndActivities: 'Enhance your cruise',
      flightIncluded: 'Flight included',
      sortOptions: {
        featured: 'Featured',
        asc: 'A-Z',
        desc: 'Z-A'
      },
      filters: {
        of: 'of',
        xSelectedFilters: '{filters} selected filters',
        noResults: 'No results. Try adjusting your search by removing filters',
        advancedFilters: 'Advanced filters',
        filterBy: 'Filter by'
      },
      activities: 'activities',
      and: 'and',
      notFound404: '404 - Page Not Found',
      error500: '500 - Server-side error occurred',
      mostPopularDestinations: 'Most popular destinations',
      mostPopularTopics: 'Most popular topics',
      traveller: 'Traveller',
      lastEdited: 'Last edited',
      norwegianCoastalVoyage: 'Norwegian Coastal Voyage',
      download: 'Download',
      attempt: 'Attempt',
      overview: 'Overview',
      cruiseDestination: 'Your Expedition Cruise to {destination}',
      order: 'Order',
      offer: 'offer',
      nDaysLeft: '{n} days left',
      toTakeAdvantageOfOur: 'to take advantage of our',
      bestPriceGuarantee: 'best price guarantee',
      cookiePolicy: 'Cookie policy',
      managePreferences: 'Manage preferences',
      seeDetails: 'See details',
      anytime: 'Anytime',
      xMinuteRead: '{number} min read',
      years: 'years',
      discountOff: '~ {discountPercentage}% Off',
      total: 'Total',
      discounts: 'Discounts',
      time: 'time',
      to: 'to',
      selectForAllPassengers: 'Select for all passengers',
      remove: 'Remove',
      unknownError: 'Unknown error',
      availablePromotions: 'Available promotions',
      reserved: 'Reserved',
      success: 'Success',
      updated: 'Updated',
      removed: 'Removed',
      next: 'Next',
      previous: 'Previous',
      lastUpdated: 'Last updated',
      more: 'More',
      all: 'All',
      notIncluded: 'Not Included',
      addFrom: 'Add from',
      pleaseReserve: 'Please reserve',
      added: 'Added',
      ppPerPerson: 'pp',
      myaccount: 'My Account',
      optional: 'optional',
      addForFree: 'Add for free',
      apply: 'Apply',
      selectPreviousYear: 'Select previous year',
      selectNextYear: 'Select next year',
      returning: 'Returning',
      backToHome: 'Back to Home',
      scrollToTop: 'Scroll to top',
      home: 'Home',
      openMenu: 'Open menu',
      formUnavailable: 'The form is unfortunately not available, please get in touch with us via email or phone.',
      Cancelled: 'Cancelled',
      pause: 'Pause',
      mute: 'Mute',
      watchVideo: 'Watch video',
      save: 'Save',
      nonbinary: 'Non-binary'
    }
  },
  'de-DE': {
    common: {
      ship: 'Schiff',
      loadMore: 'Mehr laden',
      showLess: 'Weniger anzeigen',
      showMore: 'Mehr anzeigen',
      day: 'Tag',
      travellers: 'Reisende',
      cabins: 'Kabinen',
      departure: 'Abreise',
      duration: 'Dauer',
      itinerary: 'Reiseverlauf',
      days: 'Tage',
      readMore: 'Mehr lesen',
      unknownImage: 'Unbekanntes Bild',
      sortBy: 'Sortieren',
      whatsIncluded: 'Ihre Inklusivleistungen',
      knowBeforeYouGo: 'Wissenswertes vor Ihrer Reise',
      flights: 'Flüge',
      hotel: 'Hotel',
      expeditionCruise: 'Expeditions-Seereise',
      destinations: 'Reiseziele',
      price: 'Preis',
      topics: 'Themen',
      return: 'Rückreise',
      select: 'Select',
      clear: 'Löschen',
      done: 'Fertig',
      showAllDestinations: 'Alle Reiseziele anzeigen',
      showFeaturedDestinations: 'Enthaltene Reiseziele anzeigen',
      talkToUs: 'Sprechen Sie mit uns',
      getBrochures: 'Kataloge anfordern',
      filter: 'Filter',
      sort: 'Sortieren',
      ambassador: 'Ambassador',
      email: 'E-Mail-Adresse',
      readingTime: 'Lesedauer {readingTime} Minuten ',
      video: 'Video',
      loyalty: {
        tier: 'Treuestufe',
        number: 'Treuenummer'
      },
      button: {
        viewDetails: 'Details anzeigen',
        loadMoreCruises: 'Weitere Seereisen laden',
        expand: 'Erweitern',
        seeAllStories: 'Alle Geschichten ansehen',
        collapse: 'Entfalten',
        seeAllShips: 'Alle Schiffe ansehen',
        startExploring: 'Gehen Sie auf Entdeckungsreise',
        seeMoreReasons: 'Mehr Gründe ansehen',
        seeMoreCruises: 'Mehr Seereisen anzeigen',
        seeMoreStories: 'Mehr Geschichten ansehen',
        seeMore: 'Mehr anzeigen',
        seeAllTopics: 'Alle Themen anzeigen',
        seeLessTopics: 'Weniger Themen anzeigen',
        seeMoreActivities: 'Mehr anzeigen',
        exploreAllDestinations: 'Entdecken Sie alle Reiseziele',
        seeAllOffers: 'Siehe vollständige Liste mit Angeboten',
        seeCollection: 'Angebot ansehen'
      },
      close: 'Schließen',
      included: 'Inklusive',
      priceFrom: 'Preis ab',
      activityLevel: 'Aktivitätslevel',
      edit: 'Bearbeiten',
      ships: 'Schiffe',
      season: 'Reisezeit',
      bookingCode: 'Buchungscode',
      minNumber: 'Mindestanzahl',
      fromPrice: 'Ab {price}',
      when: 'An',
      departures: 'Reisetermine',
      port: 'Hafen',
      lastMinute: 'Last Minute',
      male: 'Männlich',
      female: 'Weiblich',
      cancel: 'Stornieren',
      continue: 'Weiter',
      unavailable: 'Nicht verfügbar',
      cruises: 'Expeditions-Seereisen',
      inspiration: 'Inspiration',
      offers: 'Angebote',
      login: 'Anmelden',
      logout: 'Abmelden',
      profile: 'Profil',
      perPerson: 'pro Person',
      search: 'Suchen',
      goTo: 'Weiter zu',
      stories: 'Geschichten',
      bookings: 'Buchungen',
      moreInformation: 'Weitere Informationen',
      shipsTo: 'Schiffe nach',
      title: 'Titel',
      mr: 'Herr',
      mrs: 'Frau',
      miss: 'Frau',
      soldOut: 'Ausverkauft',
      showOtherCruises: 'Andere Seereisen erkunden',
      viewInMap: 'Auf der Karte anzeigen',
      webcam: 'Webcam',
      unknownLocation: 'Unbekannter Standort',
      somethingWentWrong: 'Leider ist ein Fehler aufgetreten',
      selectAll: 'Alle auswählen',
      multipleDestinations: 'Mehrere Reiseziele',
      transfer: 'Transfer',
      nextDeparture: 'Nächster Reisetermin',
      imagePlaceholder: 'Bild-Platzhalter',
      from: 'Von',
      free: 'Kostenlos',
      topTopics: 'Top-Themen',
      topicsSubtitle: 'Artikel nach Interessen filtern',
      viewAllCruisesToDestination: 'Alle Seereisen nach {destination} anzeigen',
      readLess: 'Weniger anzeigen',
      unknown: 'Unbekannt',
      excursionsAndActivities: 'Optimieren Sie Ihre Seereise',
      flightIncluded: 'Inklusive Flug',
      sortOptions: {
        featured: 'Ausgewählt',
        asc: 'A-Z',
        desc: 'Z-A'
      },
      filters: {
        of: 'von',
        xSelectedFilters: '{filters} ausgewählte Filter',
        noResults: 'Keine Ergebnisse. Versuchen Sie, Ihre Suche durch Entfernen von Filtern anzupassen',
        advancedFilters: 'Erweiterte Filter',
        filterBy: 'Filtern nach'
      },
      activities: 'Aktivitäten',
      and: 'und ',
      notFound404: '404 – Seite nicht gefunden',
      error500: '500 – Es ist ein Serverfehler aufgetreten',
      mostPopularDestinations: 'Beliebteste Reiseziele',
      mostPopularTopics: 'Beliebteste Themen',
      traveller: 'Reisender',
      lastEdited: 'Zuletzt bearbeitet',
      norwegianCoastalVoyage: 'Die klassische Postschiffroute',
      download: 'Herunterladen',
      attempt: 'Versuch',
      overview: 'Überblick',
      cruiseDestination: 'Ihre Expeditions-Seereise nach {destination}',
      order: 'Bestellung',
      offer: 'Angebot',
      nDaysLeft: '{n} Tage verbleibend',
      toTakeAdvantageOfOur: 'zur Nutzung unserer',
      bestPriceGuarantee: 'Bestpreis-Garantie',
      cookiePolicy: 'Cookie-Richtlinie',
      managePreferences: 'Cookie-Einstellungen',
      seeDetails: 'Details anzeigen',
      anytime: 'Zu jeder Zeit',
      xMinuteRead: '{number} Minuten Lesedauer',
      years: 'Jahre',
      discountOff: '~ {discountPercentage} % Ermäßigung',
      total: 'Gesamt',
      discounts: 'Ermäßigungen',
      time: 'Zeit',
      to: 'bis',
      selectForAllPassengers: 'Für alle Passagiere auswählen',
      remove: 'Entfernen',
      unknownError: 'Unbekannter Fehler',
      availablePromotions: 'Verfügbare Angebote',
      reserved: 'Reserved',
      success: 'Success',
      updated: 'Updated',
      removed: 'Removed',
      next: 'Nächste',
      previous: 'Vorherige',
      lastUpdated: 'Last updated',
      more: 'Mehr',
      all: 'Alle',
      notIncluded: 'Nicht inklusive',
      addFrom: 'Hinzufügen ab',
      pleaseReserve: 'Bitte reservieren',
      added: 'Hinzugefügt',
      ppPerPerson: 'p. P.',
      myaccount: 'My Account',
      optional: 'optional',
      addForFree: 'Add for free',
      apply: 'Anwenden',
      selectPreviousYear: 'Vorheriges Jahr auswählen',
      selectNextYear: 'Nächstes Jahr auswählen',
      returning: 'Rückreise',
      backToHome: 'Zurück zu Home',
      scrollToTop: 'Scroll to top',
      home: 'Startseite',
      openMenu: 'Menü öffnen',
      formUnavailable: 'Das Formular ist nicht verfügbar, bitte kontaktieren Sie uns per E-Mail oder Telefon.',
      Cancelled: 'Abgesagt',
      pause: 'Pause',
      mute: 'Stumm',
      watchVideo: 'Video ansehen',
      save: 'Speichern',
      nonbinary: 'Divers'
    }
  },
  'gsw-CH': {
    common: {
      ship: 'Schiff',
      loadMore: 'Mehr laden',
      showLess: 'Weniger anzeigen',
      showMore: 'Mehr anzeigen',
      day: 'Tag',
      travellers: 'Reisende',
      cabins: 'Kabinen',
      departure: 'Abreise',
      duration: 'Dauer',
      itinerary: 'Reiseverlauf',
      days: 'Tage',
      readMore: 'Mehr lesen',
      unknownImage: 'Unbekanntes Bild',
      sortBy: 'Sortieren',
      whatsIncluded: 'Ihre Inklusivleistungen',
      knowBeforeYouGo: 'Wissenswertes vor Ihrer Reise',
      flights: 'Flüge',
      hotel: 'Hotel',
      expeditionCruise: 'Expeditions-Seereise',
      destinations: 'Reiseziele',
      price: 'Preis',
      topics: 'Themen',
      return: 'Rückreise',
      select: 'Select',
      clear: 'Löschen',
      done: 'Fertig',
      showAllDestinations: 'Alle Reiseziele anzeigen',
      showFeaturedDestinations: 'Enthaltene Reiseziele anzeigen',
      talkToUs: 'Sprechen Sie mit uns',
      getBrochures: 'Kataloge anfordern',
      filter: 'Filter',
      sort: 'Sortieren',
      ambassador: 'Ambassador',
      email: 'E-Mail-Adresse',
      readingTime: 'Lesedauer {readingTime} Minuten ',
      video: 'Video',
      loyalty: {
        tier: 'Treuestufe',
        number: 'Treuenummer'
      },
      button: {
        viewDetails: 'Details anzeigen',
        loadMoreCruises: 'Weitere Seereisen laden',
        expand: 'Erweitern',
        seeAllStories: 'Alle Geschichten ansehen',
        collapse: 'Entfalten',
        seeAllShips: 'Alle Schiffe ansehen',
        startExploring: 'Gehen Sie auf Entdeckungsreise',
        seeMoreReasons: 'Mehr Gründe ansehen',
        seeMoreCruises: 'Mehr Seereisen anzeigen',
        seeMoreStories: 'Mehr Geschichten ansehen',
        seeMore: 'Mehr anzeigen',
        seeAllTopics: 'Alle Themen anzeigen',
        seeLessTopics: 'Weniger Themen anzeigen',
        seeMoreActivities: 'Mehr anzeigen',
        exploreAllDestinations: 'Entdecken Sie alle Reiseziele',
        seeAllOffers: 'Siehe vollständige Liste mit Angeboten',
        seeCollection: 'Angebot ansehen'
      },
      close: 'Schließen',
      included: 'Inklusive',
      priceFrom: 'Preis ab',
      activityLevel: 'Aktivitätslevel',
      edit: 'Bearbeiten',
      ships: 'Schiffe',
      season: 'Reisezeit',
      bookingCode: 'Buchungscode',
      minNumber: 'Mindestanzahl',
      fromPrice: 'Ab {price}',
      when: 'An',
      departures: 'Reisetermine',
      port: 'Hafen',
      lastMinute: 'Last Minute',
      male: 'Männlich',
      female: 'Weiblich',
      cancel: 'Stornieren',
      continue: 'Weiter',
      unavailable: 'Nicht verfügbar',
      cruises: 'Expeditions-Seereisen',
      inspiration: 'Inspiration',
      offers: 'Angebote',
      login: 'Anmelden',
      logout: 'Abmelden',
      profile: 'Profil',
      perPerson: 'pro Person',
      search: 'Suchen',
      goTo: 'Weiter zu',
      stories: 'Geschichten',
      bookings: 'Buchungen',
      moreInformation: 'Weitere Informationen',
      shipsTo: 'Schiffe nach',
      title: 'Titel',
      mr: 'Herr',
      mrs: 'Frau',
      miss: 'Frau',
      soldOut: 'Ausverkauft',
      showOtherCruises: 'Andere Seereisen erkunden',
      viewInMap: 'Auf der Karte anzeigen',
      webcam: 'Webcam',
      unknownLocation: 'Unbekannter Standort',
      somethingWentWrong: 'Leider ist ein Fehler aufgetreten',
      selectAll: 'Alle auswählen',
      multipleDestinations: 'Mehrere Reiseziele',
      transfer: 'Transfer',
      nextDeparture: 'Nächster Reisetermin',
      imagePlaceholder: 'Bild-Platzhalter',
      from: 'Von',
      free: 'Kostenlos',
      topTopics: 'Top-Themen',
      topicsSubtitle: 'Artikel nach Interessen filtern',
      viewAllCruisesToDestination: 'Alle Seereisen nach {destination} anzeigen',
      readLess: 'Weniger anzeigen',
      unknown: 'Unbekannt',
      excursionsAndActivities: 'Optimieren Sie Ihre Seereise',
      flightIncluded: 'Inklusive Flug',
      sortOptions: {
        featured: 'Ausgewählt',
        asc: 'A-Z',
        desc: 'Z-A'
      },
      filters: {
        of: 'von',
        xSelectedFilters: '{filters} ausgewählte Filter',
        noResults: 'Keine Ergebnisse. Versuchen Sie, Ihre Suche durch Entfernen von Filtern anzupassen',
        advancedFilters: 'Erweiterte Filter',
        filterBy: 'Filtere nach'
      },
      activities: 'Aktivitäten',
      and: 'und ',
      notFound404: '404 – Seite nicht gefunden',
      error500: '500 – Es ist ein Serverfehler aufgetreten',
      mostPopularDestinations: 'Beliebteste Reiseziele',
      mostPopularTopics: 'Beliebteste Themen',
      traveller: 'Reisender',
      lastEdited: 'Zuletzt bearbeitet',
      norwegianCoastalVoyage: 'Die klassische Postschiffroute',
      download: 'Herunterladen',
      attempt: 'Versuch',
      overview: 'Überblick',
      cruiseDestination: 'Ihre Expeditions-Seereise nach {destination}',
      order: 'Bestellung',
      offer: 'Angebot',
      nDaysLeft: '{n} Tage verbleibend',
      toTakeAdvantageOfOur: 'zur Nutzung unserer',
      bestPriceGuarantee: 'Bestpreis-Garantie',
      cookiePolicy: 'Cookie-Richtlinie',
      managePreferences: 'Cookie-Einstellungen',
      seeDetails: 'Details anzeigen',
      anytime: 'Zu jeder Zeit',
      xMinuteRead: '{number} Minuten Lesedauer',
      years: 'Jahre',
      discountOff: '~ {discountPercentage} % Ermäßigung',
      total: 'Gesamt',
      discounts: 'Ermäßigungen',
      time: 'Zeit',
      to: 'bis',
      selectForAllPassengers: 'Für alle Passagiere auswählen',
      remove: 'Entfernen',
      unknownError: 'Unbekannter Fehler',
      availablePromotions: 'Verfügbare Angebote',
      reserved: 'Reserved',
      success: 'Success',
      updated: 'Updated',
      removed: 'Removed',
      next: 'Nächste',
      previous: 'Vorherige',
      lastUpdated: 'Last updated',
      more: 'Mehr',
      all: 'Alle',
      notIncluded: 'Nicht inklusive',
      addFrom: 'Hinzufügen ab',
      pleaseReserve: 'Bitte reservieren',
      added: 'Hinzugefügt',
      ppPerPerson: 'p. P.',
      myaccount: 'My Account',
      optional: 'optional',
      addForFree: 'Add for free',
      apply: 'Anwenden',
      selectPreviousYear: 'Vorheriges Jahr auswählen',
      selectNextYear: 'Nächstes Jahr auswählen',
      returning: 'Rückreise',
      backToHome: 'Zurück zu Home',
      scrollToTop: 'Scroll to top',
      home: 'Startseite',
      openMenu: 'Menü öffnen',
      formUnavailable: 'Das Formular ist nicht verfügbar, bitte kontaktieren Sie uns per E-Mail oder Telefon.',
      Cancelled: 'Abgesagt',
      pause: 'Pause',
      mute: 'Stumm',
      watchVideo: 'Video ansehen',
      save: 'Speichern',
      nonbinary: 'Divers'
    }
  },
  'fr-FR': {
    common: {
      ship: 'Navire',
      loadMore: 'Voir plus',
      showLess: 'Voir moins',
      showMore: 'Afficher plus',
      day: 'Jour',
      travellers: 'Passager(s)',
      cabins: 'Cabines',
      departure: 'Départ',
      duration: 'Durée',
      itinerary: 'Itinéraire',
      days: 'jours',
      readMore: 'En savoir plus',
      unknownImage: 'Image inconnue',
      sortBy: 'Trier',
      whatsIncluded: 'Prestations incluses',
      knowBeforeYouGo: 'À savoir avant de partir',
      flights: 'Vols',
      hotel: 'Hôtel',
      expeditionCruise: 'Croisière d’expédition',
      destinations: 'Destinations',
      price: 'Prix',
      topics: 'Sujets',
      return: 'Retour',
      select: 'Selectionner',
      clear: 'Effacer',
      done: 'Valider',
      showAllDestinations: 'Voir toutes les destinations',
      showFeaturedDestinations: 'Voir les destinations présentées',
      talkToUs: 'Contactez-nous',
      getBrochures: 'Obtenir les brochures',
      filter: 'Filtre',
      sort: 'Trier',
      ambassador: 'Ambassadeur',
      email: 'E-mail',
      readingTime: 'Lecture de {readingTime} min',
      video: 'Vidéo',
      loyalty: {
        tier: 'Niveau de fidélité',
        number: 'Numéro fidélité'
      },
      button: {
        viewDetails: 'Consulter les détails',
        loadMoreCruises: 'Charger plus de croisières',
        expand: 'Ouvrir',
        seeAllStories: 'Voir tous les articles',
        collapse: 'Réduire',
        seeAllShips: 'Voir tous les navires',
        startExploring: 'Commencer l’exploration',
        seeMoreReasons: 'Voir plus de raisons',
        seeMoreCruises: 'Voir plus de croisières',
        seeMoreStories: 'Voir plus d\'articles',
        seeMore: 'Afficher plus',
        seeAllTopics: 'Voir tous les sujets',
        seeLessTopics: 'Voir moins de sujets',
        seeMoreActivities: 'Voir plus',
        exploreAllDestinations: 'Explorer toutes les destinations',
        seeAllOffers: 'Voir la liste complète des offres',
        seeCollection: 'Voir la collection'
      },
      close: 'Fermer',
      included: 'Inclus',
      priceFrom: 'À partir de',
      activityLevel: 'Niveau d’activité',
      edit: 'Modifier',
      ships: 'Navires',
      season: 'Saison',
      bookingCode: 'Code réservation',
      minNumber: 'Nombre min.',
      fromPrice: 'À partir de {price}',
      when: 'Quand',
      departures: 'Départs',
      port: 'Port',
      lastMinute: 'Dernière minute',
      male: 'Masculin',
      female: 'Féminin',
      cancel: 'Annuler',
      continue: 'Continuer',
      unavailable: 'Indisponible',
      cruises: 'Croisières',
      inspiration: 'Inspiration',
      offers: 'Offres',
      login: 'Me connecter',
      logout: 'Déconnexion',
      profile: 'Profil',
      perPerson: 'par personne',
      search: 'Rechercher',
      goTo: 'Aller vers',
      stories: 'Articles',
      bookings: 'Réservations',
      moreInformation: 'En savoir plus',
      shipsTo: 'Navires allant vers',
      title: 'Civilité',
      mr: 'Mr',
      mrs: 'Mme',
      miss: 'Mlle',
      soldOut: 'Épuisé',
      showOtherCruises: 'Explorer d’autres croisières',
      viewInMap: 'Voir sur la carte',
      webcam: 'Webcam',
      unknownLocation: 'Emplacement inconnu',
      somethingWentWrong: 'Une erreur est survenue',
      selectAll: 'Tout sélectionner',
      multipleDestinations: 'Plusieurs destinations',
      transfer: 'Transfert',
      nextDeparture: 'Départ suivant',
      imagePlaceholder: 'Espace réservé pour l\'image',
      from: 'Depuis',
      free: 'Inclus',
      topTopics: 'Thèmes principaux',
      topicsSubtitle: 'Consulter tous les articles qui correspondent à vos centres d’intérêt',
      viewAllCruisesToDestination: 'Consulter toutes les croisières vers {destination}',
      readLess: 'Moins',
      unknown: 'Inconnu',
      excursionsAndActivities: 'Complétez votre croisière',
      flightIncluded: 'Vol inclus',
      sortOptions: {
        featured: 'Présenté',
        asc: 'A-Z',
        desc: 'Z-A'
      },
      filters: {
        of: 'sur',
        xSelectedFilters: '{filters} filtres sélectionnés',
        noResults: 'Aucun résultat. Essayez de modifier votre recherche en supprimant certains filtres',
        advancedFilters: 'Filtres avancés',
        filterBy: 'Filtrer par'
      },
      activities: 'activités',
      and: 'et',
      notFound404: '404 - Page introuvable',
      error500: '500 - Une erreur côté serveur s’est produite',
      mostPopularDestinations: 'Le Top des destinations',
      mostPopularTopics: 'Sujets les plus populaires',
      traveller: 'Passager',
      lastEdited: 'Dernière modification',
      norwegianCoastalVoyage: 'Voyage le long de la côte norvégienne',
      download: 'Télécharger',
      attempt: 'Tentative',
      overview: 'Aperçu',
      cruiseDestination: 'Votre croisière d’expédition vers {destination}',
      order: 'Commander',
      offer: 'offre',
      nDaysLeft: '{n} jours restants',
      toTakeAdvantageOfOur: 'pour profiter de nos',
      bestPriceGuarantee: 'meilleurs prix garantis',
      cookiePolicy: 'Politique relative aux cookies',
      managePreferences: 'Gérer les préférences',
      seeDetails: 'Voir les détails',
      anytime: 'A tout moment',
      xMinuteRead: 'Lecture de {number} min',
      years: 'années',
      discountOff: '~ {discountPercentage} % de réduction',
      total: 'Total',
      discounts: 'Réductions',
      time: 'heure',
      to: 'vers',
      selectForAllPassengers: 'Sélectionner pour tous les passagers',
      remove: 'Supprimer',
      unknownError: 'Erreur inconnue',
      availablePromotions: 'Offres disponibles',
      reserved: 'Réservé',
      success: 'Opération réussie',
      updated: 'Mis à jour',
      removed: 'Supprimé',
      next: 'Suivant',
      previous: 'Précédent',
      lastUpdated: 'Dernière mise à jour',
      more: 'Plus',
      all: 'Tous',
      notIncluded: 'Non inclus',
      addFrom: 'Ajouter à partir de',
      pleaseReserve: 'Veuillez réserver',
      added: 'Ajouté',
      ppPerPerson: 'par personne',
      myaccount: 'My Account',
      optional: 'optional',
      addForFree: 'Add for free',
      apply: 'Appliquer',
      selectPreviousYear: 'Sélectionner l\'année précédente',
      selectNextYear: 'Select next year',
      returning: 'Retour',
      backToHome: 'Retour sur la page d\'accueil',
      scrollToTop: 'Scroll to top',
      home: 'Accueil',
      openMenu: 'Ouvrir le menu',
      formUnavailable: 'Malheureusement, le formulaire est indisponible. Veuillez nous contacter par e-mail ou par téléphone.',
      Cancelled: 'Annulé',
      pause: 'Pause',
      mute: 'Mute',
      watchVideo: 'Voir la vidéo',
      save: 'Enregistrer',
      nonbinary: 'Autre'
    }
  },
  'da-DK': {
    common: {
      ship: 'Skib',
      loadMore: 'Indlæs mere',
      showLess: 'Vis mindre',
      showMore: 'Vis mere',
      day: 'Dag',
      travellers: 'Rejsende',
      cabins: 'Kahytter',
      departure: 'Afrejse',
      duration: 'Varighed',
      itinerary: 'Rejsen - dag til dag',
      days: 'dage',
      readMore: 'Læs mere',
      unknownImage: 'Ukendt billede',
      sortBy: 'Sortér',
      whatsIncluded: 'Inkluderet',
      knowBeforeYouGo: 'Det skal du vide, før du rejser',
      flights: 'Flyrejser',
      hotel: 'Hotel',
      expeditionCruise: 'Ekspeditionskrydstogt',
      destinations: 'Destinationer',
      price: 'Pris',
      topics: 'Emner',
      return: 'Retur',
      select: 'Vælg',
      clear: 'Ryd',
      done: 'Færdig',
      showAllDestinations: 'Vis alle destinationer',
      showFeaturedDestinations: 'Vis udvalgte destinationer',
      talkToUs: 'Kontakt os',
      getBrochures: 'Få brochurer',
      filter: 'Filtrer',
      sort: 'Sortér',
      ambassador: 'Ambassadør',
      email: 'E-mailadresse',
      readingTime: '{readingTime} minutter at læse',
      video: 'Video',
      loyalty: {
        tier: 'Loyalitetsniveau',
        number: 'Loyalitetsnummer'
      },
      button: {
        viewDetails: 'Vis detaljer',
        loadMoreCruises: 'Indlæs flere krydstogter',
        expand: 'Udvid',
        seeAllStories: 'Find mere inspiration',
        collapse: 'Skjul',
        seeAllShips: 'Se alle skibe',
        startExploring: 'Begynd at udforske',
        seeMoreReasons: 'Se flere årsager',
        seeMoreCruises: 'Vis flere krydstogter',
        seeMoreStories: 'Se flere historier',
        seeMore: 'Se flere',
        seeAllTopics: 'Se alle emner',
        seeLessTopics: 'Se færre emner',
        seeMoreActivities: 'Se flere',
        exploreAllDestinations: 'Udforsk alle destinationer',
        seeAllOffers: 'Se en liste over alle tilbud',
        seeCollection: 'Se samling'
      },
      close: 'Luk',
      included: 'Inkluderet',
      priceFrom: 'Pris fra',
      activityLevel: 'Aktivitetsniveau',
      edit: 'Rediger',
      ships: 'Skibe',
      season: 'Sæson',
      bookingCode: 'Bestillingskode',
      minNumber: 'Min. antal',
      fromPrice: 'Fra {price}',
      when: 'Hvornår',
      departures: 'Afrejse',
      port: 'Havn',
      lastMinute: 'Sidste øjeblik',
      male: 'Mand',
      female: 'Kvinde',
      cancel: 'Annuller',
      continue: 'Fortsæt',
      unavailable: 'Ikke tilgængelig',
      cruises: 'Krydstogter',
      inspiration: 'Inspiration',
      offers: 'Tilbud',
      login: 'Log ind',
      logout: 'Log ud',
      profile: 'Profil',
      perPerson: 'pr. person',
      search: 'Søg',
      goTo: 'Gå til',
      stories: 'Historier',
      bookings: 'Bestillinger',
      moreInformation: 'Flere oplysninger',
      shipsTo: 'Skibe til',
      title: 'Titel',
      mr: 'Hr.',
      mrs: 'Fru',
      miss: 'Frk.',
      soldOut: 'Udsolgt',
      showOtherCruises: 'Udforsk andre krydstogter',
      viewInMap: 'Se på kort',
      webcam: 'Webcam',
      unknownLocation: 'Ukendt placering',
      somethingWentWrong: 'Der gik noget galt.',
      selectAll: 'Vælg alle',
      multipleDestinations: 'Flere destinationer',
      transfer: 'Transport',
      nextDeparture: 'Næste afrejse',
      imagePlaceholder: 'Pladsholder for billede',
      from: 'Fra',
      free: 'Gratis',
      topTopics: 'Populære emner',
      topicsSubtitle: 'Find inspiration, der passer til dine interesser ',
      viewAllCruisesToDestination: 'Se alle krydstogter til {destination}',
      readLess: 'Læs mindre',
      unknown: 'Ukendt',
      excursionsAndActivities: 'Udflugter og aktiviteter',
      flightIncluded: 'Flyrejse inkluderet',
      sortOptions: {
        featured: 'Udvalgte',
        asc: 'A-Å',
        desc: 'Å-A'
      },
      filters: {
        of: 'af',
        xSelectedFilters: '{filters} filtre valgt',
        noResults: 'Ingen resultater. Prøv at justere din søgning ved at fjerne filtre.',
        advancedFilters: 'Avancererede filtre',
        filterBy: 'Filtrér efter'
      },
      activities: 'aktiviteter',
      and: 'og',
      notFound404: '404 - Siden findes ikke',
      error500: '500 - Der opstod en fejl med serveren',
      mostPopularDestinations: 'Mest populære destinationer',
      mostPopularTopics: 'Mest populære emner',
      traveller: 'Rejsende',
      lastEdited: 'Senest redigeret',
      norwegianCoastalVoyage: 'Norsk kystrejse',
      download: 'Download',
      attempt: 'Forsøg',
      overview: 'Oversigt',
      cruiseDestination: 'Dit ekspeditionskrydstogt til {destination}',
      order: 'Ordre',
      offer: 'tilbud',
      nDaysLeft: '{n} dage tilbage',
      toTakeAdvantageOfOur: 'benyt dig af vores',
      bestPriceGuarantee: 'garanti for den bedste pris',
      cookiePolicy: 'Cookiepolitik',
      managePreferences: 'Administrer indstillinger',
      seeDetails: 'Se detaljer',
      anytime: 'Når som helst',
      xMinuteRead: '{number} minutter at læse',
      years: 'år',
      discountOff: '~ {discountPercentage} % rabat',
      total: 'I alt',
      discounts: 'Rabatter',
      time: 'klokken',
      to: 'til',
      selectForAllPassengers: 'Vælg for alle passagerer',
      remove: 'Fjern',
      unknownError: 'Ukendt fejl',
      availablePromotions: 'Tilgængelige kampagner',
      reserved: 'Reserveret',
      success: 'Succes',
      updated: 'Opdateret',
      removed: 'Fjernet',
      next: 'Næste',
      previous: 'Previous',
      lastUpdated: 'Senest opdateret',
      more: 'More',
      all: 'All',
      notIncluded: 'Ikke inkluderet',
      addFrom: 'Tilføj fra',
      pleaseReserve: 'Venligst reserver',
      added: 'Tilføjet',
      ppPerPerson: 'pr. pers.',
      myaccount: 'My Account',
      optional: 'optional',
      addForFree: 'Add for free',
      apply: 'Færdig',
      selectPreviousYear: 'Vælg tidligere år',
      selectNextYear: 'Vælg næste år',
      returning: 'Retur',
      backToHome: 'Tilbage til forsiden',
      scrollToTop: 'Scroll to top',
      home: 'Start',
      openMenu: 'Åbn menu',
      formUnavailable: 'Formularen er desværre ikke tilgængelig. Kontakt os via e-mail eller telefon.',
      Cancelled: 'Annulleret',
      pause: 'Pause',
      mute: 'Mute',
      watchVideo: 'Se video',
      save: 'Gem',
      nonbinary: 'Non-binær'
    }
  },
  'sv-SE': {
    common: {
      ship: 'Fartyg',
      loadMore: 'Läs in mer',
      showLess: 'Visa mindre',
      showMore: 'Visa mer',
      day: 'Dag',
      travellers: 'Resenärer',
      cabins: 'Hytter',
      departure: 'Avresa',
      duration: 'Längd',
      itinerary: 'Resan - dag till dag',
      days: 'dagar',
      readMore: 'Läs mer',
      unknownImage: 'Okänd bild',
      sortBy: 'Sortera',
      whatsIncluded: 'Vad som ingår',
      knowBeforeYouGo: 'Vad du behöver veta inför resan',
      flights: 'Flyg',
      hotel: 'Hotell',
      expeditionCruise: 'Expeditionskryssning',
      destinations: 'Destinationer',
      price: 'Pris',
      topics: 'Ämnen',
      return: 'Återresa',
      select: 'Välj',
      clear: 'Rensa',
      done: 'Klar',
      showAllDestinations: 'Visa alla destinationer',
      showFeaturedDestinations: 'Visa utvalda destinationer',
      talkToUs: 'Prata med oss',
      getBrochures: 'Hämta broschyrer',
      filter: 'Filtrera',
      sort: 'Sortera',
      ambassador: 'Ambassador',
      email: 'E-post',
      readingTime: '{readingTime} minuters läsning',
      video: 'Video',
      loyalty: {
        tier: 'Lojalitetsnivå',
        number: 'Lojalitetsnummer'
      },
      button: {
        viewDetails: 'Visa uppgifter',
        loadMoreCruises: 'Läs in fler kryssningar',
        expand: 'Expandera',
        seeAllStories: 'Hitta mer inspiration',
        collapse: 'Dölj',
        seeAllShips: 'Se alla fartyg',
        startExploring: 'Börja utforska',
        seeMoreReasons: 'Se fler anledningar',
        seeMoreCruises: 'Se fler kryssningar',
        seeMoreStories: 'Se fler berättelser',
        seeMore: 'Visa mer',
        seeAllTopics: 'Visa alla ämnen',
        seeLessTopics: 'Visa färre ämnen',
        seeMoreActivities: 'Visa mer',
        exploreAllDestinations: 'Upptäck alla våra resmål',
        seeAllOffers: 'Se fullständig lista med erbjudanden',
        seeCollection: 'Visa samling'
      },
      close: 'Stäng',
      included: 'Detta ingår',
      priceFrom: 'Pris från',
      activityLevel: 'Aktivitetsnivå',
      edit: 'Redigera',
      ships: 'Fartyg',
      season: 'Årstid',
      bookingCode: 'Bokningskod',
      minNumber: 'Lägsta antal',
      fromPrice: 'Från {price}',
      when: 'När',
      departures: 'Avresor',
      port: 'Hamn',
      lastMinute: 'Sista minuten',
      male: 'Man',
      female: 'Kvinna',
      cancel: 'Avbryt',
      continue: 'Fortsätt',
      unavailable: 'Inte tillgängligt',
      cruises: 'Kryssningar ',
      inspiration: 'Inspiration',
      offers: 'Erbjudanden',
      login: 'Logga in',
      logout: 'Logga ut',
      profile: 'Profil',
      perPerson: 'per person',
      search: 'Sök',
      goTo: 'Gå till',
      stories: 'Berättelser',
      bookings: 'Bokningar',
      moreInformation: 'Mer information',
      shipsTo: 'Fartyg på',
      title: 'Titel',
      mr: 'Herr',
      mrs: 'Fru',
      miss: 'Fröken',
      soldOut: 'Slutsåld',
      showOtherCruises: 'Titta närmare på andra kryssningar',
      viewInMap: 'Visa på karta',
      webcam: 'Webbkamera',
      unknownLocation: 'Okänd plats',
      somethingWentWrong: 'Något gick fel',
      selectAll: 'Välj alla',
      multipleDestinations: 'Flera resmål',
      transfer: 'Transfer',
      nextDeparture: 'Nästa avgång',
      imagePlaceholder: 'Bildplatshållare',
      from: 'Från',
      free: 'Kostnadsfri',
      topTopics: 'Populära ämnen',
      topicsSubtitle: 'Hitta inspiration som passar dina intressen',
      viewAllCruisesToDestination: 'Visa alla kryssningar till {destination}',
      readLess: 'Läs mindre',
      unknown: 'Okänt',
      excursionsAndActivities: 'Utflykter och aktiviteter',
      flightIncluded: 'Flyg ingår',
      sortOptions: {
        featured: 'Utvalda',
        asc: 'A–Ö',
        desc: 'Ö–A'
      },
      filters: {
        of: 'av',
        xSelectedFilters: '{filters} valda filter',
        noResults: 'Inga resultat. Försök anpassa sökningen genom att ta bort filtren.',
        advancedFilters: 'Avancerade filter',
        filterBy: 'Filtrera efter'
      },
      activities: 'aktiviteter',
      and: 'och',
      notFound404: '404 – Sidan hittades inte',
      error500: '500 – Ett serverfel inträffade',
      mostPopularDestinations: 'Mest populära resmål',
      mostPopularTopics: 'Mest populära ämnen',
      traveller: 'Resenär',
      lastEdited: 'Senast ändrad',
      norwegianCoastalVoyage: 'Resa längs norska kusten',
      download: 'Ladda ner',
      attempt: 'Försök',
      overview: 'Översikt',
      cruiseDestination: 'Din expeditionskryssning till {destination}',
      order: 'Beställ',
      offer: 'erbjudande',
      nDaysLeft: '{n} dagar kvar',
      toTakeAdvantageOfOur: 'för att utnyttja vår',
      bestPriceGuarantee: 'bästa pris-garanti',
      cookiePolicy: 'Cookie-policy',
      managePreferences: 'Hantera inställningar',
      seeDetails: 'Visa detaljer',
      anytime: 'När som helst',
      xMinuteRead: '{number} minuters läsning',
      years: 'år',
      discountOff: 'ca {discountPercentage} % rabatt',
      total: 'Totalt',
      discounts: 'Rabatter',
      time: 'tid',
      to: 'till',
      selectForAllPassengers: 'Välj för alla passagerare',
      remove: 'Ta bort',
      unknownError: 'Okänt fel',
      availablePromotions: 'Pågående kampanjer',
      reserved: 'Reserverad',
      success: 'Klart',
      updated: 'Har uppdaterats',
      removed: 'Har tagits bort',
      next: 'Nästa',
      previous: 'Previous',
      lastUpdated: 'Senast uppdaterad',
      more: 'More',
      all: 'All',
      notIncluded: 'Ingår inte',
      addFrom: 'Lägg till från',
      pleaseReserve: 'Vänligen reservera',
      added: 'Tillagt',
      ppPerPerson: 'per pers',
      myaccount: 'My Account',
      optional: 'optional',
      addForFree: 'Add for free',
      apply: 'Klar',
      selectPreviousYear: 'Välj föregående år',
      selectNextYear: 'Välj nästa år',
      returning: 'Återresa',
      backToHome: 'Tillbaka till startsidan',
      scrollToTop: 'Scroll to top',
      home: 'Hem',
      openMenu: 'Öppna meny',
      formUnavailable: 'Formuläret är tyvärr inte tillgängligt. Kontakta oss via e-post eller telefon.',
      Cancelled: 'Avbruten',
      pause: 'Paus',
      mute: 'Mute',
      watchVideo: 'Se video',
      save: 'Spara',
      nonbinary: 'Ickebinär '
    }
  },
  'nb-NO': {
    common: {
      ship: 'Skip',
      loadMore: 'Last inn mer',
      showLess: 'Vis mindre',
      showMore: 'Vis mer',
      day: 'Dag',
      travellers: 'Passasjerer',
      cabins: 'Lugarer',
      departure: 'Avreise',
      duration: 'Varighet',
      itinerary: 'Reisen - dag til dag',
      days: 'dager',
      readMore: 'Les mer',
      unknownImage: 'Ukjent bilde',
      sortBy: 'Sorter',
      whatsIncluded: 'Inkludert',
      knowBeforeYouGo: 'Dette bør du vite før du reiser',
      flights: 'Flyvninger',
      hotel: 'Hotell',
      expeditionCruise: 'Ekspedisjonscruise',
      destinations: 'Destinasjoner',
      price: 'Pris',
      topics: 'Emner',
      return: 'Retur',
      select: 'Select',
      clear: 'Tøm',
      done: 'Ferdig',
      showAllDestinations: 'Vis alle destinasjoner',
      showFeaturedDestinations: 'Vis fremhevede destinasjoner',
      talkToUs: 'Snakk med oss',
      getBrochures: 'Få brosjyrer',
      filter: 'Filtrer',
      sort: 'Sorter',
      ambassador: 'Ambassadør',
      email: 'E‑post',
      readingTime: 'Tar {readingTime} min å lese',
      video: 'Video',
      loyalty: {
        tier: 'Lojalitetsnivå',
        number: 'Lojalitetsnummer'
      },
      button: {
        viewDetails: 'Se detaljer',
        loadMoreCruises: 'Last inn flere cruise',
        expand: 'Utvid',
        seeAllStories: 'Finn mer inspirasjon',
        collapse: 'Skjul',
        seeAllShips: 'Se alle skip',
        startExploring: 'Start utforskingen',
        seeMoreReasons: 'Se flere grunner',
        seeMoreCruises: 'Vis flere reiser',
        seeMoreStories: 'Les flere historier',
        seeMore: 'Se mer',
        seeAllTopics: 'Se flere emner',
        seeLessTopics: 'Se færre emner',
        seeMoreActivities: 'Se mer',
        exploreAllDestinations: 'Utforsk alle destinasjoner',
        seeAllOffers: 'Se listen over alle tilbud',
        seeCollection: 'Se samling'
      },
      close: 'Lukk',
      included: 'Inkludert',
      priceFrom: 'Pris fra',
      activityLevel: 'Aktivitetsnivå',
      edit: 'Rediger',
      ships: 'Skip',
      season: 'Sesong',
      bookingCode: 'Bookingkode',
      minNumber: 'Min. antall',
      fromPrice: 'Fra {price}',
      when: 'Når',
      departures: 'Avreiser',
      port: 'Havn',
      lastMinute: 'Siste liten',
      male: 'Mann',
      female: 'Kvinne',
      cancel: 'Avbryt',
      continue: 'Fortsett',
      unavailable: 'Ikke tilgjengelig',
      cruises: 'Cruise',
      inspiration: 'Inspirasjon',
      offers: 'Tilbud',
      login: 'Logg inn',
      logout: 'Logg ut',
      profile: 'Profil',
      perPerson: 'per person',
      search: 'Søk',
      goTo: 'Gå til',
      stories: 'Historier',
      bookings: 'Bestillinger',
      moreInformation: 'Mer informasjon',
      shipsTo: 'Skip til',
      title: 'Tittel',
      mr: 'herr',
      mrs: 'fru',
      miss: 'frøken',
      soldOut: 'Utsolgt',
      showOtherCruises: 'Utforsk andre cruise',
      viewInMap: 'Se på kart',
      webcam: 'Webkamera',
      unknownLocation: 'Ukjent plassering',
      somethingWentWrong: 'Noe gikk galt',
      selectAll: 'Velg alle',
      multipleDestinations: 'Flere destinasjoner',
      transfer: 'Transport',
      nextDeparture: 'Neste avreise',
      imagePlaceholder: 'Plass til bilde',
      from: 'Fra',
      free: 'Gratis',
      topTopics: 'Populære emner',
      topicsSubtitle: 'Finn inspirasjon som passer interessene dine',
      viewAllCruisesToDestination: 'Se alle cruise til {destination}',
      readLess: 'Les mindre',
      unknown: 'Ukjent',
      excursionsAndActivities: 'Utflukter og aktiviteter',
      flightIncluded: 'Fly inkludert',
      sortOptions: {
        featured: 'Utvalgte',
        asc: 'A-Å',
        desc: 'Å-A'
      },
      filters: {
        of: 'av',
        xSelectedFilters: '{filters} filtre valgt',
        noResults: 'Ingen resultater. Prøv å endre søket ditt ved å fjerne filtre',
        advancedFilters: 'Avanserte filter',
        filterBy: 'Filtrer etter'
      },
      activities: 'aktiviteter',
      and: 'og',
      notFound404: '404 - Fant ikke siden',
      error500: '500 - Serverfeil',
      mostPopularDestinations: 'Mest populære destinasjoner',
      mostPopularTopics: 'Mest populære emner',
      traveller: 'Passasjerer',
      lastEdited: 'Sist endret',
      norwegianCoastalVoyage: 'Reis langs norskekysten',
      download: 'Last ned',
      attempt: 'Forsøk',
      overview: 'Oversikt',
      cruiseDestination: 'Ditt ekspedisjonscruise til {destination}',
      order: 'Bestill',
      offer: 'tilbud',
      nDaysLeft: '{n} dager igjen',
      toTakeAdvantageOfOur: 'til å benytte deg av vårt',
      bestPriceGuarantee: 'garanti for beste pris',
      cookiePolicy: 'Bruk av informasjonskapsler',
      managePreferences: 'Endre preferanser',
      seeDetails: 'Se detaljer',
      anytime: 'Når som helst',
      xMinuteRead: 'Tar {number} min å lese',
      years: 'år',
      discountOff: '~ {discountPercentage} % rabatt',
      total: 'Sum',
      discounts: 'Rabatter',
      time: 'klokkeslett',
      to: 'til',
      selectForAllPassengers: 'Velg for alle passasjerer',
      remove: 'Fjern',
      unknownError: 'Ukjent feil',
      availablePromotions: 'Aktuelle tilbud',
      reserved: 'Reservert',
      success: 'Vellykket',
      updated: 'Oppdatert',
      removed: 'Fjernet',
      next: 'Neste',
      previous: 'Previous',
      lastUpdated: 'Sist oppdatert',
      more: 'More',
      all: 'All',
      notIncluded: 'Ikke inkludert',
      addFrom: 'Legg til fra',
      pleaseReserve: 'Vennligst reserver',
      added: 'Lagt til',
      ppPerPerson: 'pr. pers.',
      myaccount: 'My Account',
      optional: 'optional',
      addForFree: 'Add for free',
      apply: 'Ferdig',
      selectPreviousYear: 'Velg forrige år',
      selectNextYear: 'Velg neste år',
      returning: 'Retur',
      backToHome: 'Tilbake til hovedsiden',
      scrollToTop: 'Scroll to top',
      home: 'Hjem',
      openMenu: 'Åpne meny',
      formUnavailable: 'Skjemaet er dessverre ikke tilgjengelig. Ta kontakt med oss på e-post eller telefon.',
      Cancelled: 'Avlyst',
      pause: 'Pause',
      mute: 'Mute',
      watchVideo: 'Se video',
      save: 'Lagre',
      nonbinary: 'Ikke-binær'
    }
  },
  'en': {
    common: {
      ship: 'Ship',
      loadMore: 'Load more',
      showLess: 'Show less',
      showMore: 'Show more',
      day: 'Day',
      travellers: 'Traveller(s)',
      cabins: 'Cabins',
      departure: 'Departure',
      duration: 'Duration',
      itinerary: 'Itinerary',
      days: 'days',
      readMore: 'Read more',
      unknownImage: 'Unknown image',
      sortBy: 'Sort',
      whatsIncluded: 'What\'s Included',
      knowBeforeYouGo: 'Know before you go',
      flights: 'Flights',
      hotel: 'Hotel',
      expeditionCruise: 'Expedition Cruise',
      destinations: 'Destinations',
      price: 'Price',
      topics: 'Topics',
      return: 'Return',
      select: 'Select',
      clear: 'Clear',
      done: 'Done',
      showAllDestinations: 'Show all destinations',
      showFeaturedDestinations: 'Show featured destinations',
      talkToUs: 'Talk to us',
      getBrochures: 'Get brochures',
      filter: 'Filter',
      sort: 'Sort',
      ambassador: 'Ambassador',
      email: 'Email',
      readingTime: '{readingTime} mins read',
      video: 'Video',
      loyalty: {
        tier: 'Loyalty tier',
        number: 'Loyalty number'
      },
      button: {
        viewDetails: 'View details',
        loadMoreCruises: 'Load more cruises',
        expand: 'Expand',
        seeAllStories: 'See all stories',
        collapse: 'Collapse',
        seeAllShips: 'See all ships',
        startExploring: 'Start exploring',
        seeMoreReasons: 'See more reasons',
        seeMoreCruises: 'See more cruises',
        seeMoreStories: 'See more stories',
        seeMore: 'See more',
        seeAllTopics: 'See all topics',
        seeLessTopics: 'See less topics',
        seeMoreActivities: 'See more',
        exploreAllDestinations: 'Explore all destinations',
        seeAllOffers: 'See complete list with offers',
        seeCollection: 'See collection'
      },
      close: 'Close',
      included: 'Included',
      priceFrom: 'Price from',
      activityLevel: 'Activity level',
      edit: 'Edit',
      ships: 'Ships',
      season: 'Season',
      bookingCode: 'Booking code',
      minNumber: 'Min. number',
      fromPrice: 'From {price}',
      when: 'When',
      departures: 'Departures',
      port: 'Port',
      lastMinute: 'Last minute',
      male: 'Male',
      female: 'Female',
      cancel: 'Cancel',
      continue: 'Continue',
      unavailable: 'Unavailable',
      cruises: 'Cruises',
      inspiration: 'Inspiration',
      offers: 'Offers',
      login: 'Login',
      logout: 'Logout',
      profile: 'Profile',
      perPerson: 'per person',
      search: 'Search',
      goTo: 'Go to',
      stories: 'Stories',
      bookings: 'Bookings',
      moreInformation: 'More information',
      shipsTo: 'Ships to',
      title: 'Title',
      mr: 'Mr',
      mrs: 'Mrs',
      miss: 'Miss',
      soldOut: 'Sold out',
      showOtherCruises: 'Explore other cruises',
      viewInMap: 'View in map',
      webcam: 'Webcam',
      unknownLocation: 'Unknown location',
      somethingWentWrong: 'Something went wrong',
      selectAll: 'Select all',
      multipleDestinations: 'Multiple Destinations',
      transfer: 'Transfer',
      nextDeparture: 'Next departure',
      imagePlaceholder: 'Image placeholder',
      from: 'From',
      free: 'Free',
      topTopics: 'Top topics',
      topicsSubtitle: 'View all stories that fit your interests',
      viewAllCruisesToDestination: 'View all cruises to {destination}',
      readLess: 'Read less',
      unknown: 'Unknown',
      excursionsAndActivities: 'Enhance your cruise',
      flightIncluded: 'Flight included',
      sortOptions: {
        featured: 'Featured',
        asc: 'A-Z',
        desc: 'Z-A'
      },
      filters: {
        of: 'of',
        xSelectedFilters: '{filters} selected filters',
        noResults: 'No results. Try adjusting your search by removing filters',
        advancedFilters: 'Advanced filters',
        filterBy: 'Filter by'
      },
      activities: 'activities',
      and: 'and',
      notFound404: '404 - Page Not Found',
      error500: '500 - Server-side error occurred',
      mostPopularDestinations: 'Most popular destinations',
      mostPopularTopics: 'Most popular topics',
      traveller: 'Traveller',
      lastEdited: 'Last edited',
      norwegianCoastalVoyage: 'Norwegian Coastal Voyage',
      download: 'Download',
      attempt: 'Attempt',
      overview: 'Overview',
      cruiseDestination: 'Your Expedition Cruise to {destination}',
      order: 'Order',
      offer: 'offer',
      nDaysLeft: '{n} days left',
      toTakeAdvantageOfOur: 'to take advantage of our',
      bestPriceGuarantee: 'best price guarantee',
      cookiePolicy: 'Cookie policy',
      managePreferences: 'Manage preferences',
      seeDetails: 'See details',
      anytime: 'Anytime',
      xMinuteRead: '{number} min read',
      years: 'years',
      discountOff: '~ {discountPercentage}% Off',
      total: 'Total',
      discounts: 'Discounts',
      time: 'time',
      to: 'to',
      selectForAllPassengers: 'Select for all passengers',
      remove: 'Remove',
      unknownError: 'Unknown error',
      availablePromotions: 'Available promotions',
      reserved: 'Reserved',
      success: 'Success',
      updated: 'Updated',
      removed: 'Removed',
      next: 'Next',
      previous: 'Previous',
      lastUpdated: 'Last updated',
      more: 'More',
      all: 'All',
      notIncluded: 'Not Included',
      addFrom: 'Add from',
      pleaseReserve: 'Please reserve',
      added: 'Added',
      ppPerPerson: 'pp',
      myaccount: 'My Account',
      optional: 'optional',
      addForFree: 'Add for free',
      apply: 'Apply',
      selectPreviousYear: 'Select previous year',
      selectNextYear: 'Select next year',
      returning: 'Returning',
      backToHome: 'Back to Home',
      scrollToTop: 'Scroll to top',
      home: 'Home',
      openMenu: 'Open menu',
      formUnavailable: 'The form is unfortunately not available, please get in touch with us via email or phone.',
      Cancelled: 'Cancelled',
      pause: 'Pause',
      mute: 'Mute',
      watchVideo: 'Watch video',
      save: 'Save',
      nonbinary: 'Non-binary'
    }
  }
}

export type TCommon = {
  common: {
    ship: string;
    loadMore: string;
    showLess: string;
    showMore: string;
    day: string;
    travellers: string;
    cabins: string;
    departure: string;
    duration: string;
    itinerary: string;
    days: string;
    readMore: string;
    unknownImage: string;
    sortBy: string;
    whatsIncluded: string;
    knowBeforeYouGo: string;
    flights: string;
    hotel: string;
    expeditionCruise: string;
    destinations: string;
    price: string;
    topics: string;
    return: string;
    select: string;
    clear: string;
    done: string;
    showAllDestinations: string;
    showFeaturedDestinations: string;
    talkToUs: string;
    getBrochures: string;
    filter: string;
    sort: string;
    ambassador: string;
    email: string;
    readingTime: string;
    video: string;
    loyalty: {
      tier: string;
      number: string;
    };
    button: {
      viewDetails: string;
      loadMoreCruises: string;
      expand: string;
      seeAllStories: string;
      collapse: string;
      seeAllShips: string;
      startExploring: string;
      seeMoreReasons: string;
      seeMoreCruises: string;
      seeMoreStories: string;
      seeMore: string;
      seeAllTopics: string;
      seeLessTopics: string;
      seeMoreActivities: string;
      exploreAllDestinations: string;
      seeAllOffers: string;
      seeCollection: string;
    };
    close: string;
    included: string;
    priceFrom: string;
    activityLevel: string;
    edit: string;
    ships: string;
    season: string;
    bookingCode: string;
    minNumber: string;
    fromPrice: string;
    when: string;
    departures: string;
    port: string;
    lastMinute: string;
    male: string;
    female: string;
    cancel: string;
    continue: string;
    unavailable: string;
    cruises: string;
    inspiration: string;
    offers: string;
    login: string;
    logout: string;
    profile: string;
    perPerson: string;
    search: string;
    goTo: string;
    stories: string;
    bookings: string;
    moreInformation: string;
    shipsTo: string;
    title: string;
    mr: string;
    mrs: string;
    miss: string;
    soldOut: string;
    showOtherCruises: string;
    viewInMap: string;
    webcam: string;
    unknownLocation: string;
    somethingWentWrong: string;
    selectAll: string;
    multipleDestinations: string;
    transfer: string;
    nextDeparture: string;
    imagePlaceholder: string;
    from: string;
    free: string;
    topTopics: string;
    topicsSubtitle: string;
    viewAllCruisesToDestination: string;
    readLess: string;
    unknown: string;
    excursionsAndActivities: string;
    flightIncluded: string;
    sortOptions: {
      featured: string;
      asc: string;
      desc: string;
    };
    filters: {
      of: string;
      xSelectedFilters: string;
      noResults: string;
      advancedFilters: string;
      filterBy: string;
    };
    activities: string;
    and: string;
    notFound404: string;
    error500: string;
    mostPopularDestinations: string;
    mostPopularTopics: string;
    traveller: string;
    lastEdited: string;
    norwegianCoastalVoyage: string;
    download: string;
    attempt: string;
    overview: string;
    cruiseDestination: string;
    order: string;
    offer: string;
    nDaysLeft: string;
    toTakeAdvantageOfOur: string;
    bestPriceGuarantee: string;
    cookiePolicy: string;
    managePreferences: string;
    seeDetails: string;
    anytime: string;
    xMinuteRead: string;
    years: string;
    discountOff: string;
    total: string;
    discounts: string;
    time: string;
    to: string;
    selectForAllPassengers: string;
    remove: string;
    unknownError: string;
    availablePromotions: string;
    reserved: string;
    success: string;
    updated: string;
    removed: string;
    next: string;
    previous: string;
    lastUpdated: string;
    more: string;
    all: string;
    notIncluded: string;
    addFrom: string;
    pleaseReserve: string;
    added: string;
    ppPerPerson: string;
    myaccount: string;
    optional: string;
    addForFree: string;
    apply: string;
    selectPreviousYear: string;
    selectNextYear: string;
    returning: string;
    backToHome: string;
    scrollToTop: string;
    home: string;
    openMenu: string;
    formUnavailable: string;
    Cancelled: string;
    pause: string;
    mute: string;
    watchVideo: string;
    save: string;
    nonbinary: string;
  };
}
