import React, { useEffect, useState } from 'react';
import NextLink from 'next/link';
import clsx from 'clsx';

import { GlobalInfoBanner } from '@content/models';
import { useTranslate, useBannerInformation, useLocale } from '@hooks';
import { common } from '@microcopies';
import { isFeatureEnabled } from '@src/utils';

/* Commenting out the styles that haven't been designed yet */
const getBannerStyle = (style: string | undefined) => {
  let styles = 'bg-hxv-indigo-70 text-white';
  switch (style) {
    case 'critical':
    case 'warning':
      styles = 'bg-hxv-secondary-red-600 text-white';
      break;
    // case 'offer':
    //   styles = 'bg-light-green-secondary';
    //   break;
    // case 'campaign':
    //   styles = 'bg-snow-blue';
    //   break;
    // case 'upgrade':
    //   styles = 'bg-black text-white';
    //   break;
    default:
  }
  return { styles };
};

const DefaultBanner = ({ data }: { data: GlobalInfoBanner }) => {
  const locale = useLocale();
  const translate = useTranslate(common, (x) => x.common);

  const { storedData } = useBannerInformation();
  const [isHidden, setIsHidden] = useState(false);

  useEffect(() => {
    setIsHidden(Boolean(storedData));
  }, [storedData]);

  if (isHidden || data === null) return null;

  if (!data?.show) return null;

  const bannerStyle = getBannerStyle(data?.style ?? undefined);

  const content = (
    <div className="flex items-center justify-start flex-1 tablet:justify-center">
      <div className="flex flex-row gap-1 tablet:gap-2">
        <p className="mx-auto text-sm font-normal">
          {data?.title}
          {data?.slug && (
            <span className="ml-1 underline whitespace-nowrap">
              {translate((x) => x.readMore)}
            </span>
          )}
        </p>
      </div>
    </div>
  );

  let bannerContent = content;

  if (data?.slug) {
    bannerContent = (
      <NextLink href={data.slug} locale={locale} passHref>
        <a className="w-full">{content}</a>
      </NextLink>
    );
  }

  return (
    <aside
      aria-label="Information banner"
      className={clsx('relative px-6 py-3 ui-text flex', {
        [bannerStyle.styles || '']: bannerStyle.styles
      })}
    >
      <div className="flex items-center justify-between w-full gap-3">
        {bannerContent}
      </div>
    </aside>
  );
};

const InfoBanner = ({
  data
}: {
  isSiteSwitcher?: boolean;
  data: GlobalInfoBanner;
}) => {
  if (isFeatureEnabled('showInfoBanner')) {
    return <DefaultBanner data={data} />;
  }
  return null;
};

export default InfoBanner;
