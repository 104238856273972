import React from 'react';

const LogoCompact = () => (
  <svg
    width="34"
    height="48"
    viewBox="0 0 34 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1_20188)">
      <path
        d="M30.9381 38.3338L25.6255 20.8807L29.2128 10.8364C29.6576 9.61666 30.414 9.49656 31.6406 9.41282V8.92912H25.762V9.41282C26.9434 9.37095 27.897 9.78965 27.897 11.0876C27.897 11.2551 27.886 11.4755 27.625 12.3437L25.2577 19.6478L22.4764 9.41392H24.9703V8.93022H16.6451V0.82953H19.1423V0.345825H9.966V0.82953H12.4643V22.9323H7.17478V9.41282H9.67862V8.92912H0.496766V9.41282H2.994V38.3338H0.496766V38.8175H9.67091V38.3338H7.17367V23.6849H12.4632V47.1716H9.966V47.6553H19.1423V47.1716H16.6451V38.8175H23.5521V38.3338C23.0897 38.3194 22.5325 38.2115 21.9853 38.0892C21.045 37.8787 20.5528 37.4821 20.3271 37.0138C20.2038 36.757 20.1597 36.4783 20.1663 36.1973C20.1873 35.3004 20.8325 33.7446 20.8325 33.7446L23.3088 26.8945L26.7111 38.3338H24.2139V38.8175H33.4376V38.3338H30.9381ZM17.9146 37.6418C17.581 37.9459 17.1725 38.1729 16.6451 38.2754V9.41282H18.0831L22.9421 25.5272L19.3372 34.9401C19.3372 34.9401 19.0642 35.7147 18.7625 36.3835C18.6127 36.7152 18.2428 37.3432 17.9146 37.6418Z"
        fill="#202835"
      />
    </g>
    <defs>
      <clipPath id="clip0_1_20188">
        <rect width="34" height="48" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default LogoCompact;
