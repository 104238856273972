import { MutableRefObject, Ref } from 'react';

export const combineRefs =
  <T>(...refs: Ref<T | null>[]) =>
  (node: T | null) => {
    refs.forEach((r) => {
      if (typeof r === 'function') {
        r(node);
      } else if (r) {
        // eslint-disable-next-line no-param-reassign
        (r as MutableRefObject<T | null>).current = node;
      }
    });
  };
