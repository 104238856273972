export const searchCruisesButtonReducerTypes = {
  HIDE: 'HIDE_BOOKING_BUTTON',
  SHOW: 'SHOW_BOOKING_BUTTON'
};

export function searchCruisesButtonReducer(action: { type: string }) {
  switch (action.type) {
    case searchCruisesButtonReducerTypes.SHOW: {
      return false;
    }
    case searchCruisesButtonReducerTypes.HIDE: {
      return true;
    }
    default: {
      return false;
    }
  }
}
