import { addMinutes, parseJSON } from 'date-fns';

const lastUpdatedToHoldExpires = (lastUpdatedInSeaware: string) =>
  addMinutes(
    parseJSON(lastUpdatedInSeaware),
    // Using 29 instead of 30 to prevent situations where we push
    // users to re-engage with a booking only to show them a
    // "Booking expired" page.
    29
  ).getTime();

export default lastUpdatedToHoldExpires;
