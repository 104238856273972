export const guestActionTypes = {
  SET_GUEST: 'SET_GUEST'
};

export function guestReducer(state: IGuest, action: TGuestAction): IGuest {
  switch (action.type) {
    case guestActionTypes.SET_GUEST: {
      return action.payload;
    }
    default:
      return state;
  }
}
